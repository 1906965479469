import { Injectable } from "@angular/core";
import { Locale } from "../models/Locale";
import { Language } from "../models/Language";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  constructor() {}

  public get locale(): Locale {
    return this.get("locale");
  }
  public set locale(value: Locale) {
    this.set("locale", value);
  }

  public get language(): Language {
    return this.get("language");
  }
  public set language(value: Language) {
    this.set("language", value);
  }

  public get credentials (): any {
    return this.get('credentials');
  }
  public set credentials (value: any) {
    this.set('credentials', value);
  }

  public get access_token (): any {
    return this.get('access_token');
  }
  public set access_token (value: any) {
    this.set('access_token', value);
  }

  public get acr_values(): any {
    return this.get('acr_values');
  }
  public set acr_values(value: any) {
    this.set('acr_values', value);
  }

  public get id_token (): any {
    return this.get('id_token');
  }
  public set id_token (value: any) {
    this.set('id_token', value);
  }

  public get refresh_token (): any {
    return this.get('refresh_token');
  }
  public set refresh_token (value: any) {
    this.set('refresh_token', value);
  }

  public get lastAction (): any {
    return this.get('userLastAction');
  }
  public set lastAction (value: any) {
    this.set('userLastAction', value);
  }

  public get isMuseum (): any {
    return this.get('isMuseum');
  }
  public set isMuseum (value: any) {
    this.set('isMuseum', value);
  }

  public get currentPage (): any {
    return this.get('currentPage');
  }
  public set currentPage (value: any) {
    this.set('currentPage', value);
  }

  public get previousSelectedCarMuseum (): any {
    return this.get('previousSelectedCarMuseum');
  }
  public set previousSelectedCarMuseum (value: any) {
    this.set('previousSelectedCarMuseum', value);
  }

  private get(key: string): any {
    if (
      typeof window === "undefined" ||
      typeof window.localStorage === "undefined"
    ) {
      return;
    }
    try {
      return JSON.parse(window.localStorage.getItem(key) || "");
    } catch (e) {
      // weird Safari issue in private mode, where LocalStorage is defined but throws error on access
      return;
    }
  }

  public remove(key: string){
    window.localStorage.removeItem(key);
  }

  private set(key: string, value: any): any {
    if (
      typeof window === "undefined" ||
      typeof window.localStorage === "undefined"
    ) {
      return;
    }
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
      return;
    } catch (e) {
      // weird Safari issue in private mode, where LocalStorage is defined but throws error on access
      return;
    }
  }
}
