import { Location, isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, Optional, PLATFORM_ID } from "@angular/core";
import { REQUEST, RESPONSE } from "@nguniversal/express-engine/tokens";
import { ConfigurationProvider } from "./configuration.provider";
import { LocalStorageService } from "./local-storage.service";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Car } from "../models/Car";
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  combineLatest,
  retryWhen,
  take,
  retry,
  delay,
  throwError,
  filter,
  mergeMap,
  defer,
  first,
  interval,
  timeout,
  timer,
  takeUntil,
  Subject,
} from "rxjs";
import { Preconfiguration } from "../models/Preconfiguration";
import { switchMap } from "rxjs";
import { environment } from "src/environments/environment";
import { RetryStrategies } from "../_strategies/retry.strategy";
import { Api } from "./_api";
import { OptionSelection } from "../models/vehicle-config";
import { FerrariState } from "src/app/shared/application-interfaces";
import { Store } from "@ngrx/store";
import { finishAnimation, stopLoadingSpinner } from "src/app/state/actions";
import { ConfigurationMeta, OptionWithVariant, VehicleConfigurationMeta } from "../models/configuration-meta";
import { TranslateService } from "@ngx-translate/core";
import { BrowserDetectorService } from "./browser-detector.service";
import { RT_CONFIGURATOR_URL } from "src/app/shared/application-constants";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { takeWhile } from "lodash";
import saveAs from "file-saver";
import { Locale } from "../models/Locale";

@Injectable({
  providedIn: "root",
})
export class UtilityService {
  private _cars = new BehaviorSubject<Car[]>([]);
  private _vehicleMarketMapping = new BehaviorSubject<any[]>([]);
  maxRetryAttempts = environment.production ? 20 : 20;
  counterPDFRetry = 0;

  constructor(
    private cfg: ConfigurationProvider,
    private location: Location,
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private store: Store<FerrariState>,
    private router: Router,
    private translate: TranslateService,
    private deviceInfo: BrowserDetectorService,
    private snackBar: MatSnackBar,
    @Inject(PLATFORM_ID) private platformId: any,
    @Optional() @Inject(REQUEST) protected request: any,
    @Optional() @Inject(RESPONSE) protected response: any,
    private api: Api
  ) {
    this.loadCars();
    // this.getVehicleMarketMapping("purosangue");
  }

  public get cars(): BehaviorSubject<Car[]> {
    return this._cars;
  }

  public get vehicleMarketMapping(): BehaviorSubject<any[]> {
    return this._vehicleMarketMapping;
  }

  public vehicleMarketMap(carId: string) {
    this.http
      .get<any[]>(
        `/rt-assets/data/cars/${carId}/config/vehicle-to-market-mapping.json`
      )
      .subscribe((vm: any[]) => {
        this._vehicleMarketMapping.next(vm);
      });
  }

  getCarModel(carId: string | null): Observable<Car | undefined> {
    if (!!carId) {
      return this._cars.pipe(map((cars) => cars.find((x) => x.id === carId)));
    } else {
      return of(undefined);
    }
  }

  getCarByModelId(modelId: string | null): Observable<Car | undefined> {
    if (!!modelId) {
      return this._cars.pipe(
        map((cars) =>
          cars.find((car) =>
            car.modelId?.toLowerCase() === modelId.toLowerCase() || car.ids?.some(id => id.toLowerCase() === modelId.toLowerCase())
          )
        )
      );
    } else {
      return of(undefined);
    }
  }

  getPreconfigurations(modelId: string): Observable<any[]> {
    return this.http
      .get<any>(
        `${this.cfg.apiUrl}/vehicle/${modelId}/configurations/ferrari_CMT_application`
      )
      .pipe(
        map((p) =>
          p.configurations.items.map((item: any) => new Preconfiguration(item))
        ),
        catchError((err) => {
          return of([]);
        })
      );
  }

  getCurrentLocale() {
    return `${this.getCurrentLanguage().code.toLowerCase()}_${this.getCurrentCountry().code.toUpperCase()}`;
  }

  getCurrentMarket(): string {
    const country = this.cfg.countries.find((f) => f.code === this.getCurrentCountry().code);
    return country?.marketIds[0] || "00";
  }

  getCurrentCountry() {
    // \|+$   pipe, one or more times, end of the string
    // ^\|+   beginning of the string, pipe, one or more times
    const path = isPlatformBrowser(this.platformId) ? this.location.path().replace(/^\/+/g, "") : this.request.path ? this.request.path : "";
    if (!!path && path !== "") {
      // locale is the combination of language and country
      const localePath: string = path.split('/')[0];
      const localeSplitted: string[] = localePath.split('_');
      if (localeSplitted.length > 1) {
        const langPath: string = localeSplitted[0];
        const countryPath: string = localeSplitted[1];
        const localeObj: Locale[] = this.cfg.locales.filter(p => p.code.toUpperCase() === countryPath.toUpperCase());

        if (localeObj.length > 0) {
          for (let i = 0; i < localeObj.length; i++) {
            if (localeObj[i].lang === langPath) {
              return localeObj[i];
            }
          }
          return localeObj[0];
        }
      }
    }

    if (isPlatformBrowser(this.platformId)) {
      const locale2 = this.localStorage.locale;
      if (locale2 && locale2.code) {
        return locale2;
      }
    }

    return this.cfg.locales[0];
  }

  getCountryCodeForRuleEngine(): string {
    const code = this.getCurrentCountry().code;
    const country = this.cfg.countries.find((f) => f.code === code);

    //if not find any return Italy by default
    if (!country) return "I";

    return country.id;
  }

  findCurrentLocale(path: string): string {
    if (path !== "") {
      const splittedPath = path.split("/");
      if (splittedPath.length > 0) {
        const currentLangAndCountry = splittedPath.find(
          (f) => f.charAt(2) === "_"
        );
        if (!!currentLangAndCountry) {
          return currentLangAndCountry.split("_")[1];
        }
      }
    }
    return "";
  }

  getCurrentLanguage() {
    // \|+$   pipe, one or more times, end of the string
    // ^\|+   beginning of the string, pipe, one or more times
    const path: string = isPlatformBrowser(this.platformId) ? this.location.path().replace(/^\/+/g, "") : this.request.path ? this.request.path : "";
    if (!!path && path !== "") {
      // locale is the combination of language and country
      const localePath: string = path.split('/')[0];
      const localeSplitted: string[] = localePath.split('_');
      if (localeSplitted.length > 1) {
        const langPath: string = localeSplitted[0];
        const countryPath: string = localeSplitted[1];
        const localeObj: Locale[] = this.cfg.locales.filter(p => p.code.toUpperCase() === countryPath.toUpperCase());

        if (localeObj.length > 0) {
          for (let i = 0; i < localeObj.length; i++) {
            if (localeObj[i].lang === langPath) {
              const language = this.cfg.languages.find(p => p.code == localeObj[i].lang);
              if (language && language.code) {
                return language;
              }
            }
          }
        }

        if (!!langPath) {
          const language = this.cfg.languages.find((p) => p.code.toLowerCase() === langPath.toLowerCase());
          if (language && language.code) {
            return language;
          }
        }
      }
    }

    if (isPlatformBrowser(this.platformId)) {
      const language = this.localStorage.language;
      if (language && language.code) {
        return language;
      }
    }

    return this.cfg.languages[0];
  }

  isMobileOrTabletLandscapeModeOn(): boolean {
    if (!this.deviceInfo.isDesktop() && (window.innerHeight < window.innerWidth)) {
      return true;
    } else {
      return false;
    }
  }

  animationDone(event: any) {
    if (event.phaseName === "done") {
      console.log("animation state: ", {
        name: event.triggerName,
        isFinished: true,
      });
      this.store.dispatch(finishAnimation({ name: event.triggerName }));
    }
  }

  preloadImage(
    imagePath: string,
    maxRetryAttempts: number = this.maxRetryAttempts,
    delayDuration: number = 500
  ): Observable<any> {
    if (!!imagePath) {
      return this.doesImageExist(imagePath).pipe(
        take(1),
        switchMap((isExists) => {

          return defer(() => {
            return new Observable((observer: any) => {
              const srcpath = isExists ? imagePath : imagePath + `?v=${Date.now()}`;
              const img = new Image();
              img.src = srcpath;
              img.onload = function () {
                observer.next(srcpath);
                observer.complete();
              };
              img.onerror = function (err) {
                observer.error(err);
              };
            });
          }).pipe(
            take(1),
            catchError((err) => {
              // console.error(err);
              return err;
            }),
            // Apply retry logic only if the image doesn't exist
            retryWhen((errors) =>
              errors.pipe(
                // Conditionally retry based on the boolean condition
                mergeMap((error, index) => {
                  if (!isExists && index < maxRetryAttempts - 1) {
                    return of(error).pipe(delay(delayDuration));
                  }
                  return throwError(error);
                })
              )
            )
          );
        })
      );
    } else {
      return of("");
    }
  }


  doesImageExist(imageUrl: string) {
    return new Observable<boolean>((observer) => {
      const img = new Image();
      img.src = imageUrl;

      img.onload = () => {
        // Image loaded successfully
        observer.next(true);
        observer.complete();
      };

      img.onerror = () => {
        // Image failed to load (not found)
        observer.next(false);
        observer.complete();
      };
    });
  }

  fetchImage(url: string, maxRetryAttempts: number = this.maxRetryAttempts, delayDuration: number = 4000): Observable<Blob> {
    return this.api.loadImage(url).pipe(
      retry(maxRetryAttempts), // Retry up to 6 times
      delay(delayDuration), // Delay between retries (4 seconds)
      catchError(error => {
        // Handle the error if needed
        return throwError(error);
      })
    );
  }


  applyPackage(
    carId: string,
    packageId: string,
    configLevel: any
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      this.fetchGlobalRules(carId).subscribe({
        next: (rules: any) => {
          if (!!rules && !!rules["exclusion"]) {
            const exclusionList = rules["exclusion"][packageId];
            exclusionList.forEach((targetVal: string) => {
              this.updateNestedObjectPropertyInMetaConfig(
                configLevel,
                "id",
                targetVal,
                "selectable",
                false
              );

            });
          }
          if (!!rules && !!rules["mandatory"]) {
            const mandatoryList = rules["mandatory"][packageId];

            mandatoryList.forEach((targetVal: string) => {
              this.updateNestedObjectPropertyInMetaConfig(
                configLevel,
                "id",
                targetVal,
                "selectable",
                true
              );
            });

          }
        },
        complete: () => {
          resolve();
        },
      });
    });
  }

  fetchGlobalRules(carId: string | undefined): Observable<any> {
    if (!!carId) {
      const FILE_NAME = `global-rules.json`;
      const jsonPath = `/rt-assets/data/cars/${carId}/config/${FILE_NAME}`;
      return this.http.get(jsonPath);
    } else {
      return of({});
    }
  }

  getGlobalExclusionsOfPackage(carId: string | undefined, packageId: string): Observable<string[]> {
    return this.fetchGlobalRules(carId).pipe(map(globalRules => {
      return globalRules['exclusion'][packageId];
    }));
  }

  updateNestedObjectPropertyInMetaConfig(
    obj: any[],
    prop: string,
    targetVal: any,
    updatedProp: string,
    updatedVal: any
  ) {
    for (let i = 0; i < obj.length; i++) {
      if (obj[i].hasOwnProperty("children") && obj[i].children.length > 0) {
        this.updateNestedObjectPropertyInMetaConfig(
          obj[i].children,
          prop,
          targetVal,
          updatedProp,
          updatedVal
        );
      } else {
        if (obj[i].hasOwnProperty("optspec") && obj[i].optspec.length > 0) {
          const index = obj[i].optspec.findIndex(
            (f: any) => f.id === targetVal
          );
          if (index !== -1) {
            obj[i].optspec[index][updatedProp] = updatedVal;
          }
          this.updateNestedObjectPropertyInMetaConfig(
            obj[i].optspec,
            prop,
            targetVal,
            updatedProp,
            updatedVal
          );
        }
        if (obj[i].hasOwnProperty("options") && obj[i].options.length > 0) {
          const splittedTarget = targetVal.split('_');
          const optionId = splittedTarget[0];
          const variantId = splittedTarget[1];
          if (variantId && variantId === '*') {
            // should effect all variants
            for (let t = 0; t <= obj[i].options.length; t++) {
              const targerWithWildvariant = `${optionId}` + (!!obj[i].options[t] && !!obj[i].options[t].values && !!obj[i].options[t].values[0].variantId ? `_${obj[i].options[t].values[0].variantId}` : '');
              const index = obj[i].options.findIndex(
                (f: any) => f.id === targerWithWildvariant
              );
              if (index !== -1) {
                obj[i].options[index][updatedProp] = updatedVal;
              }
            }
          } else {
            const index = obj[i].options.findIndex(
              (f: any) => f.id === targetVal
            );
            if (index !== -1) {
              obj[i].options[index][updatedProp] = updatedVal;
            }
          }
        }
      }
    }
  }

  public getFerrariCode(
    carId: string,
    packageId: string,
    selectedOpts: OptionSelection[]
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api
        .saveConfiguration(
          carId,
          packageId,
          null,
          this.getCurrentLocale(),
          null,
          selectedOpts
        )
        .subscribe({
          next: (response) => {
            this.store.dispatch(stopLoadingSpinner());
            if (!!response) {
              resolve(response.ferrari_code);
            } else {
              reject();
            }
          },
          error: (err) => {
            this.store.dispatch(stopLoadingSpinner());
            // TODO relative message snack bar
            reject(err);
          },
        });
    });
  }

  public preSave(
    carId: string,
    packageId: string,
    selectedOpts: OptionSelection[]
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const dateNow = new Date()
            .toISOString()
            .slice(0, 10)
            .replace(/-/g, "");
        const timeNow = new Date().toLocaleTimeString().replace(/:/g, "");
        var today: string = dateNow + timeNow;
        var configName = carId.toLowerCase() + today || null;
      this.api
        .saveConfiguration(
          carId,
          packageId,
          null,
          this.getCurrentLocale(),
          configName,
          selectedOpts
        )
        .subscribe({
          next: (response) => {
            this.store.dispatch(stopLoadingSpinner());
            if (!!response) {
              resolve(response);
            } else {
              reject();
            }
          },
          error: (err) => {
            this.store.dispatch(stopLoadingSpinner());
            // TODO relative message snack bar
            reject(err);
          },
        });
    });
  }

  public contactDealer(car: Car, packageId: string, selected: OptionSelection[]): Observable<string> {
    return of(([car, selected] as [Car, OptionSelection[]])).pipe(
      take(1),
      switchMap(([car, selected]) => this.api.saveConfiguration(car.id, packageId, null, this.getCurrentLocale(), null, selected)),
      switchMap((response) => this.translate.get('RT.MENU.CONTACT_DEALER_LINK.' + car.id, { valueConfig: response.ferrari_code }))
    )
  }

  // getVehicleMarketMapping(carId: string) {
  //   this.http.get<any[]>(
  //     `/rt-assets/data/cars/${carId}/config/vehicle-to-market-mapping.json`
  //   ).subscribe((vm: any[])=>{
  //     this._vehicleMarketMapping.next(vm);
  //   });
  // }

  public checkIfLoadConfigurationValidCode(ConfigCode: string): Observable<any> {
    const ferrariCode = ConfigCode.trim();
    if (ferrariCode) {
      return this.api.loadConfiguration(ferrariCode).pipe(
        switchMap((response: any) => {
          if (!!response) {
            return of({ response: response, isValid: true });
          }
          return of({ response: undefined, isValid: false });
        })
      )
    } else return of({ response: undefined, isValid: false });
  }

  public loadConfiguration(ConfigCode: string) {
    const ferrariCode = ConfigCode.trim();
    if (ferrariCode) {
      this.api.loadConfiguration(ferrariCode).pipe(
        switchMap((response: any) => {
          const selectedCar$ = this.getCarModel(response.carId);
          const packageId = response.packageId ? response.packageId : response.values.some(
            (x: any) => x.optionId == "FIO1" || x.optionId == "FIO2"
          )
            ? "fiorano"
            : response.values.some((x: any) => x.optionId == "EXCL")
              ? "design"
              : "default";
          return combineLatest([of(response), selectedCar$, of(packageId)]);
        }),
        map((data) => {
          const response = data[0];
          const car = data[1];
          const packageId = data[2];
          if (!!car && !!packageId) {
            if (car.isEnabledInRealTime) {
              this.router.navigate(
                [
                  this.getCurrentLocale(),
                  RT_CONFIGURATOR_URL,
                  car?.id,
                  packageId,
                  "exterior",
                ],
                {
                  queryParams: {
                    configuration: response.configurationId,
                    isStreaming: (car.hasStreaming ? true : false)
                  },
                }
              );
            } else {
              if (this.isPremium(response.location)) {
                window.location.href = `${document.location.origin}/${this.getCurrentLocale()}/car/code/${ferrariCode}`;
              } else {
                window.location.href = `${document.location.origin}/${this.getCurrentLocale()}/code/${ferrariCode}`;
              }
            }
          }
        }),
        first()
      )
        .subscribe({ next: () => void 0, error: (error) => console.warn(error) });
    }
  }

  public filterOptionsNotAvailableForWeb(vehicleMeta: VehicleConfigurationMeta, optionList: OptionWithVariant[]): OptionWithVariant[] {
    let correctList: OptionWithVariant[] = [];
    optionList.forEach((opt) => {
      let option;
      if (!!opt.variantId) {
        opt.variantId !== '364000000' ? option = vehicleMeta.getOption(opt.optionId, opt.variantId) : null;
      } else {
        option = vehicleMeta.getOption(opt.optionId);
      }
      !!option ? correctList.push(opt) : null;
    });
    return correctList;
  }

  public extractModisOpts(
    modisOpts: string,
    result: OptionWithVariant[] = []
  ) {
    // AQS2;CRPT/364703936;ECAL;EXTC/364000000;FLRW;INTC/364709441;PNCC/065517700;RSGD;SMRT;SNDX;TIWN;UMBR;WIRE;XLED
    const optArray = modisOpts.split(";");
    for (let opt of optArray) {
      const optSep = opt.split("/");
      const optionWithVariant: OptionWithVariant = {
        optionId: optSep[0],
        variantId: optSep[1] || null,
      };
      result.push(optionWithVariant);
    }
    return result;
  }

  pollingDownloadPDF(
    firstCall: boolean = false,
    carModelId: string,
    packageId: string,
    countryCode: string,
    selectedOptions: OptionSelection[] = [],
    isMuseum: boolean = false,
    configurationId: string = ""
    ): Observable<any> {
    return this.api.requestStatusDownloadPdfImages(carModelId, packageId, countryCode, selectedOptions, isMuseum, configurationId).pipe(
      delay(3000),
      switchMap((response) => {
        console.log("first switchmap ", response);
        if (firstCall) {
          return this.pollingDownloadPDF(false, carModelId, packageId, countryCode, selectedOptions, isMuseum, response.configuration_id);
        }
        if ((!!response && !!response.status && (response.status === 'ready' || response.status === 'error') && !!response.pdf_full_url) || this.counterPDFRetry === 30) {
          console.log("[PDF DOWNLOAD] Stop polling");
          this.counterPDFRetry = 0;
          return of(response);
        } else {
          this.counterPDFRetry++;
          return this.pollingDownloadPDF(false, carModelId, packageId, countryCode, selectedOptions, isMuseum, response.configuration_id);
        }
      }),
      switchMap((response) => {
        console.log("second switchmap ", response);
        if (response.status === 'ready') {
          console.log("[PDF DOWNLOAD] PDF ready to be downloaded");
          return this.api.downloadPdfImages(response.pdf_full_url);
        } else return of(null);
      }),
      switchMap((response) => {
        console.log("map ", response);
        if (!!response) {
          let fileName = "myFerrari - " + carModelId + ".pdf";
          let newBlob = new Blob([response], { type: "application/pdf" });
          saveAs(newBlob, fileName);
          return of(response);
        } else return of(null);
      })
    );

  }

  loadAvailableCarsList(): Observable<Car[]> {
    const urlModels = this.cfg.isMuseum ? '/rt-assets/data/museum-models.json' : '/rt-assets/data/models.json';
    return this.http.get<Car[]>(urlModels)
    .pipe(
      map(cars => cars.map(car => new Car(car))),
      map(cars => cars.filter(car => car.active))
    );
  }

  private isPremium(location: string): boolean {
    const premiumArray = ['ferrari_pos_application_New', 'ferrari_premium_application'];
    return premiumArray.some(s => s == location);
  }

  private loadCars() {
    let modelsUrl = '';
    if (this.cfg.isChina) {
      modelsUrl = `/rt-assets/data/china-models.json`;
    } else if (this.cfg.isMuseum) {
      modelsUrl = `/rt-assets/data/museum-models.json`;
    } else {
      modelsUrl = `/rt-assets/data/models.json`;
    }
    this.http
    .get<any[]>(modelsUrl)
    .subscribe((carList: any[]) => {
      const cars = carList.filter((f) => f.active).map((car) => new Car(car));
      this._cars.next(cars);
    });
  }
}
