import { Component, OnDestroy, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { InactivityService } from "src/app/core/services/inactivity.service";
import { ConfigurationManagerService } from "src/app/core/services/configuration-manager.service";
import { Dialog } from "@angular/cdk/dialog";
import { FerrariState } from "src/app/shared/application-interfaces";
import { Store } from "@ngrx/store";
import { hideMuseumPrintOverlay, setMuseumStandBy } from "src/app/state/actions";
import { MatDialogRef } from "@angular/material/dialog";
import { NgIdleService } from "src/app/core/services/ng-idle.service";
import { AnalyticsService } from "src/app/core/services/analytics.service";
import { UtilityService } from "src/app/core/services/utility.service";

@Component({
  selector: "rt-museum-popup-error",
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: "./museum-popup-error.component.html",
  styleUrls: ["./museum-popup-error.component.scss"]
})
export class MuseumPopupErrorComponent implements OnInit, OnDestroy {

  countdown$ = this.inactivitySerive.getCountdown();
  timer = '00:30';
  isActive: boolean;

  constructor(private inactivitySerive: InactivityService,
     private cm: ConfigurationManagerService,
     public dialogRef: MatDialogRef<MuseumPopupErrorComponent>,
     private store: Store<FerrariState>,
     private ngIdle: NgIdleService,
     private analytics: AnalyticsService,
     private translateService: TranslateService,
     private util: UtilityService
     ) {
      this.isActive = true;
     }
  ngOnDestroy(): void {
    this.isActive = false;
  }

  ngOnInit(): void {
    this.translateService.use(this.util.getCurrentLanguage().code);
    this.startCountdown();
  }

  startCountdown() {
    const initialSeconds = 30; // Set your initial number of seconds
    this.inactivitySerive.startCountdown(initialSeconds).subscribe({
      complete: () => {
        if (this.isActive){
          // Action to be performed when countdown reaches zero
          this.resetAll('automatic');
        }
      }
    });
  }

  close() {
    this.inactivitySerive.resetCountdown();
    this.ngIdle.initilizeSessionTimeout();
    this.analytics.errorPopUp('close');
    this.dialogRef.close();
  }

  restart(){
    window.location.reload();
    this.analytics.restart('fromError')
  }

  private resetAll(step: string) {
    this.cm.resetMuseumConfiguration();
    this.store.dispatch(setMuseumStandBy());
    this.store.dispatch(hideMuseumPrintOverlay());
    this.ngIdle.idleTimeout = this.ngIdle.USER_IDLE_TIMER_VALUE_IN_MIN;
    this.analytics.restart(step);
    this.dialogRef.close();
  }

}
