<div class="container" (click)="selectItem()">
  <div class="selection-box-container" *ngIf="selectable">
    <div class="check-container circle">
      <div class="circle-inner" [ngClass]="{'opt-selected-single': selectedSingleItem?.id === item?.id}"></div>
    </div>
  </div>
  <div class="text-area">
    <div class="opt-category">
      <span *ngIf="subConfigLabel">{{ subConfigLabel | translate }}</span>
    </div>
    <div class="opt-name">
      <!-- <span *ngIf="item">{{ item.id.includes('_') ? (item.name | titlecase) : ('RT.OPTION_NAME.' + item.id | translate) }}</span> -->
      <!-- <span *ngIf="item">{{ ((("RT.OPTION_NAME." + item.id) | translate) !== '') && ((("RT.OPTION_NAME." + item.id) | translate) !== "RT.OPTION_NAME." + item.id) ? (("RT.OPTION_NAME." + item.id) | translate) : item.name.toLowerCase() }}</span> -->
      <span *ngIf="item">{{
      ((("RT.OPTION_NAME." + ((car$ | async)?.id | uppercase) + "." + item.id) | translate) !== '') && ((("RT.OPTION_NAME." + ((car$ | async)?.id | uppercase) + "." + item.id) |
        translate) !== "RT.OPTION_NAME." + ((car$ | async)?.id | uppercase) + "." + item.id) ? (("RT.OPTION_NAME." + ((car$ | async)?.id | uppercase) + "." + item.id) | translate) :
        ((("RT.OPTION_NAME." + item.id) | translate) !== '') && ((("RT.OPTION_NAME." + item.id) |
            translate) !== "RT.OPTION_NAME." + item.id) ? (("RT.OPTION_NAME." + item.id) | translate) :
            item.name.toLowerCase() }}</span>
      <span class="category-step" *ngIf="item && showStep && !!item.category"> | </span><span class="category-step" *ngIf="item && showStep && item.category">{{ item.category | translate}}</span>
    </div>
  </div>
  <div class="img-area">
    <div class="img-container" *ngIf="item">
      <img
        src="../../../../../rt-assets/data/cars/{{ (car$ | async)?.id }}/ui/assets/{{item.id}}.png"
        onerror="this.style.display='none'"
      />
    </div>
  </div>
</div>
