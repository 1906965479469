import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, first, tap } from 'rxjs/operators';
import { COMMAND_LISTEN_CONFIGURATION_APPLIED, COMMAND_REQUEST_END_DEMO_MODE } from './application-commands';
import { ApplicationStateService } from './application-state.service';
import {
  STATE_APPLICATION_START,
  STATE_CAR_LEVEL_LOADING,
  STATE_CAR_SELECTION,
  STATE_CINEMATICS,
  STATE_EXPLORE,
  STATE_EXPORT_PDF,
  STATE_EXPORT_VIDEO,
  STATE_GENERATE_STATE_IMAGES
} from './state-machine-states';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { FerrariState } from 'src/app/shared/application-interfaces';
import { stopLoading } from 'src/app/state/actions';


@Injectable({
  providedIn: "root",
})
export class ApplicationUiContextService {

  private readonly menuActiveSubject = new BehaviorSubject<boolean>(false);
  private readonly menuActive$ = this.menuActiveSubject.asObservable();
  private readonly applicationLoadingSubject = new BehaviorSubject<boolean>(false);
  private readonly applicationLoading$ = this.applicationLoadingSubject.asObservable();
  private readonly infoTextSubject = new BehaviorSubject<string | undefined>(undefined);
  private readonly infoText$ = this.infoTextSubject.asObservable();
  private readonly connectionLostSubject = new BehaviorSubject<boolean>(false);
  private readonly connectionLost$ = this.connectionLostSubject.asObservable();


  private translationSubscription?: Subscription;

  constructor (
    private applicationStateService: ApplicationStateService,
    private store: Store<FerrariState>,
  ) {
    // applicationStateService.subscribeToCommandsInit();
    // this.initMachineState();
  }

  initMachineState(){
    this.setApplicationLoading(false);
    this.applicationStateService.currentStateMachineState$
      .pipe(
        tap(stateMachineState => {
          console.log('current state: ', stateMachineState);
        })
      )
      .subscribe({
        next: stateMachineState => {
          if (stateMachineState === STATE_EXPLORE){
            this.setApplicationLoading(false);
            // this.store.dispatch(stopLoading());
          }
          this.onStateMachineStateChange(stateMachineState);
        }
      });
  }



  isMenuActive () {
    return this.menuActive$;
  }

  setMenuActiveState (active: boolean) {
    this.menuActiveSubject.next(active);
  }

  isApplicationLoading () {
    return this.applicationLoading$;
  }

  getApplicationLoadingInfoText () {
    return this.infoText$;
  }

  setApplicationLoading (loading: boolean, infoText?: string) {
    this.applicationLoadingSubject.next(loading);
    this.infoTextSubject.next(infoText);
  }

  isConnectionLost () {
    return this.connectionLost$;
  }

  setConnectionLost (connectionLost: boolean) {
    this.connectionLostSubject.next(connectionLost);
    // this.setApplicationLoading(false);
  }

  private onStateMachineStateChange (stateMachineState: string) {
    if (this.translationSubscription) {
      this.translationSubscription.unsubscribe();
      this.translationSubscription = void 0;
    }
    switch (stateMachineState) {
      // case STATE_EXPLORE:
      //   this.setApplicationLoading(false);
      //   break;
      case COMMAND_LISTEN_CONFIGURATION_APPLIED:
        this.setApplicationLoading(false);
        break;
      default:
        this.setApplicationLoading(true);
        // this.store.dispatch(stopLoading());
    }
  }

  // private setLoadingWithTranslationKey (translationKey: string) {
  //   if (this.translationSubscription) {
  //     this.translationSubscription.unsubscribe();
  //   }
  //   this.translationSubscription = this.localizationService.getTranslation(translationKey)
  //     .subscribe((translation) => {

  //       this.setApplicationLoading(true, translation);
  //     });
  // }
}
