export class Locale {
  region?: string;
  code: string;
  name: string;
  lang: string;

  constructor(data: any) {
    this.region = data.region ? data.region : null;
    this.code = data.code ? data.code : "EN";
    this.name = data.name ? data.name : "World";
    this.lang = data.lang ? data.lang : "en";
  }
}
