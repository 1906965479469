import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { timer } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';
import { FerrariState } from 'src/app/shared/application-interfaces';


@Injectable({
  providedIn: 'root',
})
export class AutoRefreshService {
  constructor(private store: Store<FerrariState>) {}

  startAutoRefreshIfNeeded(): void {
    this.store.pipe(select(p => p.ui.isLoadingStreaming)).subscribe((value) => {
      if (value) {
        this.startAutoRefresh();
      }
    });
  }

  private startAutoRefresh(): void {
    timer(30000) // 30 seconds
      .pipe(
        switchMap(() => this.store.pipe(select(p => p.ui.isLoadingStreaming), take(1)))
      )
      .subscribe((value) => {
        if (value) {
          window.location.reload();
        }
      });
  }
}
