import { Injectable, ErrorHandler } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SessionStorageService } from './session-storage.service';
import { Location } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler  {

  constructor(
    public sessionStorage: SessionStorageService,
    public location: Location
  ) { }

  handleError(error: any) {
    // Log the error to the console
    console.error(error);
    // let loginObj = environment.loginConfig;
    // window.location.href = loginObj.url + "?p=" + loginObj.p + "&client_id=" + loginObj.clientId + "&nonce=" + loginObj.nonce + "&redirect_uri=" + loginObj.redirectUri + "&scope=" + loginObj.scopes[0] + "&response_type=" + loginObj.responseType;

    if (error.status === 401) {
      console.log('catchError');
      console.log('error', error);
      this.sessionStorage.removeKeyFromSession("authorization_code");
      this.sessionStorage.removeKeyFromSession("refresh_token");
      // Redirect to external page
      let currentUrl = this.location.path();
      this.sessionStorage.post_login_url = currentUrl;
      let loginObj = environment.loginConfig;
      console.log(loginObj.url + "?p=" + loginObj.p + "&client_id=" + loginObj.clientId + "&nonce=" + loginObj.nonce + "&redirect_uri=" + loginObj.redirectUri + "&scope=" + loginObj.scopes[0] + "&response_type=" + loginObj.responseType);
      window.location.href = loginObj.url + "?p=" + loginObj.p + "&client_id=" + loginObj.clientId + "&nonce=" + loginObj.nonce + "&redirect_uri=" + loginObj.redirectUri + "&scope=" + loginObj.scopes[0] + "&response_type=" + loginObj.responseType;

    }


    // You can also send the error to a remote server for analysis
    // ...
  }
}
