import { Action, createReducer, on } from "@ngrx/store";
import { DataState, ErrorState } from "../../shared/application-interfaces";
import * as actions from "../actions";
import { initialState } from "../initialState";
import { ConfigurationMeta } from '../../core/models/configuration-meta';
import { cloneDeep } from 'lodash';

export const dataReducer = createReducer(
  initialState.data,
  on(actions.setUserCredentials, (state, payload) => {
    return {
      ...state,
      user: payload.user,
    };
  }),
  on(actions.removeUserCredentials, (state) => {
    return {
      ...state,
      user: undefined,
    };
  }),
  on(actions.setLanguage, (state, payload) => {
    return {
      ...state,
      language: payload.language,
    };
  }),
  on(actions.setLocale, (state, payload) => {
    return {
      ...state,
      locale: payload.locale,
    };
  }),
  on(actions.setSelectedCar, (state, payload) => {
    return {
      ...state,
      car: payload.car,
    };
  }),
  on(actions.setCarPackage, (state, payload) => {
    return {
      ...state,
      packageId: payload.packageId,
    };
  }),
  on(actions.setCountry, (state, payload) => {
    return {
      ...state,
      country: payload.country,
    };
  }),
  on(actions.setSelectedPreconfigurationCode, (state, payload) => {
    return {
      ...state,
      preconfigurationCode: payload.preconfigurationCode,
    };
  }),
  on(actions.setVehicleMeta, (state, payload) => {
    return {
      ...state,
      vehicleConfigMeta: payload.vehicleConfigMeta,
    };
  }),
   on(actions.updateConfigLevel, (state, payload) => {
    let meta: any = !!state.vehicleConfigMeta ? cloneDeep(state.vehicleConfigMeta)  : undefined;
    const newConfig = payload.configLevel;
    if (!!meta){
      meta = {...meta , configuration : {...meta.configuration ,configHierarchy: newConfig}};
    }
    return {
      ...state,
      vehicleConfigMeta: meta,
    };
    // return {
    //   ...state,
    //   vehicleConfigMeta : {configuration: payload.configLevel },
    // };
  }),
  on(actions.setSelectedPreconfiguration, (state, payload) => {
    return {
      ...state,
      preconfiguration: payload.preconfiguration,
    };
  }),
  on(actions.setSelectedSection, (state, payload) => {
    return {
      ...state,
      section: payload.section,
    };
  }),
  on(actions.setSelectedlevel, (state, payload) => {
    return {
      ...state,
      level: payload.level,
    };
  }),
  on(actions.setDealers, (state, payload) => {
    return {
      ...state,
      dealers: payload.dealers,
    };
  }),
  on(actions.setSelectedDealer, (state, payload) => {
    return {
      ...state,
      selectedDealer: payload.selectedDealer,
    };
  }),
  on(actions.setSelectedDealer, (state, payload) => {
    return {
      ...state,
      selectedDealer: payload.selectedDealer,
    };
  }),
  on(actions.setSelectedDealer, (state, payload) => {
    return {
      ...state,
      selectedDealer: payload.selectedDealer,
    };
  }),
  on(actions.setSaveResponse, (state, payload) => {
    return {
      ...state,
      saveResponse: payload.saveResponse,
    };
  }),
  on(actions.setFerrariId, (state, payload) => {
    return {
      ...state,
      ferrariId: payload.ferrariId,
    };
  }),
  on(actions.setFerrariCode, (state, payload) => {
    return {
      ...state,
      ferrariCode: payload.ferrariCode,
    };
  }),
  on(actions.setConfigurationIdSaved, (state, payload) => {
    return {
      ...state,
      configurationIdSaved: payload.configurationIdSaved,
    };
  }),
  on(actions.clearConfigurationGroup, (state, payload) => {
    const propertyNames = payload.propertyNames;
    const newState: any = { ...state };
    propertyNames.forEach(prop => delete newState[prop]);
    return newState as DataState;
  }),
  on(actions.setSelectedSubLevel, (state, payload) => {
    return {
      ...state,
      subLevel: payload.subLevel,
    };
  }),
  on(actions.setAvailableCarsList, (state, payload) => {
    return {
      ...state,
      availableCarsList: payload.availableCarsList,
    };
  }),
  on(actions.setPreconfigurationMuseum, (state, payload) => {
    return {
      ...state,
      preconfigurationMuseum: payload.preconfigurationMuseum,
    };
  }),
);

export function reducer(state: DataState | undefined, action: Action) {
  return dataReducer(state, action);
}
