import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Observable, of, switchMap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DialogService {
  dialogRef: MatDialogRef<any> | null = null;

  constructor(private dialog: MatDialog) {}

  openDialogs(DialogComponent: any, configs: any[]): Observable<any> {
    if (configs.length === 0) {
      // All dialogs have been closed, return an empty observable
      return new Observable((observer) => {
        observer.complete();
      });
    } else {
      const config = configs[0];
      const dialogRef = this.dialog.open(DialogComponent, config);

      this.dialogRef = dialogRef;

      return dialogRef.afterClosed().pipe(
        switchMap((result) => {
          if (result && result.isApproved) {
            // If the dialog was confirmed, remove it from the queue and recursively open the next dialog
            configs.shift();
            return this.openDialogs(DialogComponent, configs);
          } else {
            // If the dialog was denied, close all remaining dialogs
            for (let i = 1; i < this.dialog.openDialogs.length; i++) {
              this.dialogRef = this.dialog.openDialogs[i];
              this.dialogRef.close();
            }

            // Return an empty observable to stop the recursion
            return new Observable((observer) => {
              observer.complete();
            });
          }
        })
      );
    }
  }

  async openDialog(component:any ,configs: any[], index = 0, lastResult?: any): Promise<any> {
    if (index >= configs.length) {
      // Base case: no more dialogs to open, return true
      return Promise.resolve(lastResult);
    } else {
      // Recursive case: open the next dialog and wait for the result
      const dialogRef = this.dialog.open(component, configs[index]);
      return await dialogRef.afterClosed().pipe(
        switchMap(result => {
          if (!result.isApproved) {
            // User clicked "Cancel" on this dialog, stop opening more dialogs
            return of(false);
          } else {
            // User clicked "OK" on this dialog, open the next one
            return this.openDialog(component, configs, index + 1, result);
          }
        })
      ).toPromise();
    }
  }


}
