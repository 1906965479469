import { Injectable } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";

@Injectable({
  providedIn: "root",
})
export class BrowserDetectorService {
  constructor(private deviceService: DeviceDetectorService) {}

  getBrowserName(): string {
    return this.deviceService.browser;
  }

  getBrowserVersion(): string {
    return this.deviceService.browser_version;
  }

  isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  isTablet(): boolean {
    return this.deviceService.isTablet();
  }

  isDesktop(): boolean {
    return this.deviceService.isDesktop();
  }
}
