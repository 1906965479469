import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rt-modal-generic-error',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './modal-generic-error.component.html',
  styleUrls: ['./modal-generic-error.component.scss']
})
export class ModalGenericErrorComponent implements OnInit {

  public title: string;
  public description: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: any,public dialogRef: MatDialogRef<ModalGenericErrorComponent>) {
    this.title = data.title || 'PREMIUM.RULE_ENGINE.POP_UP.COMPATIBILITY_ISSUE';
    this.description = data.description || 'PREMIUM.RULE_ENGINE.POP_UP.NOT_AVAILABLE_IN_MARKET';
   }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close(true);
  }

}
