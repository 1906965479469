import jQuery from "jquery";
import {
  flatten,
  difference,
  isEqual,
  without,
  isEmpty,
  first,
  values,
} from "lodash";
import { Car } from "./Car";
import { cloneDeep } from "lodash";

export enum ColorType {
  METALLIC = "METALLIC",
  NON_METALLIC = "NON_METALLIC",
  SPECIAL = "SPECIAL",
  NO_COLOR_TYPE = "NO_COLOR_TYPE",
}

export enum OptionType {
  UNSPECIFIED = "UNSPECIFIED",
  COLOR = "COLOR",
  LIST = "LIST",
}

export class OptionTypeMapper {
  constructor(private mappings: Map<string, OptionType>) {}

  getOptionType(optionId: string): OptionType {
    return this.mappings.get(optionId) || OptionType.UNSPECIFIED;
  }
}

export interface OptionSpecification {
  id: string;
  name: string;
  description?: string;
  options: Option[];
  category?: string;
}

export interface OptionWithVariant {
  optionId: string;
  variantId?: string | null;
}

export interface ConfigurationLevel {
  id: string;
  name: string;
  isMenu: boolean;
  optspec: OptionSpecification[];
  options: Option[];
  componentType?: string;
  parent?: ConfigurationLevel;
  children?: ConfigurationLevel[];
  perspectives?: string[];
  cameraShot?: string[];
  suggestions?: boolean;
  alwaysSelected?: boolean;

  /**
   * Gets the path to the root for this ConfigurationLevel consisting of the IDs of the parent ConfigurationLevels and
   * this ConfiguraitonLevel.
   * So if this ConfigurationLevel has ABCD as id and has a parent ConfigurationLevel with EFGH as id, the resulting path would be
   * ['EFGH', 'ABCD']
   */
  getPathToRoot(): string[];

  /**
   * Same as #getPathToRoot with the difference, that the ConfigurationLevel instances are returned instead of their IDs.
   */
  getConfigurationsToRoot(): ConfigurationLevel[];
  getAllChildOptions(): Option[];

  /**
   * Returns true if this level or one of its child-levels has options, false otherwise.
   * @param {boolean} onlySelf If true, only check this level.
   * @return {boolean}
   */
  hasOptions(onlySelf?: boolean): boolean;
  hasSpecificOption(optionId: string): boolean;
}

export interface RawOption {
  id: string; // the option ID (referenced in rules)
  description: string; // a description for the config option
  name: string; // name of option
  config: boolean; // unclear, what this stands for ???
  kconfig: string; // either 'standard' or 'default' ???
  so: string; // either 'standard' or 'optional' ???
  info: string; // ??
  variants?: OptionVariant[];
  special: boolean;
  selectable: boolean;
  type: OptionType;
  values?: OptionWithVariant[];
  category?: string;
  cameraShot?: string[];
}

export interface OptionVariant {
  id: string; // the variant ID
  description: string; // variant description
  seq: number; // ???
  selected: boolean; // if the variant is preselected ???
  colorType: ColorType; // the colorType (if set) of this color/variant
  special: boolean; // if it's a special-color
}

export interface Option extends RawOption {
  parentConfiguration: ConfigurationLevel;

  getVariant: (id: string) => OptionVariant | undefined;

  /**
   * Get the (first) default variant if exists or undefined if there is either no default defined or no variants exist.
   * @return {OptionVariant | undefined}
   */
  getDefaultVariant: () => OptionVariant | undefined;
  /**
   * Gets the first variant from the list of variants or undefined if no variant exists.
   * @param {boolean} ignoreInvisibleDefault
   *  If true, continues searching for non-invisible-default variants but still returns an invisible-default-variant if
   *  no other variants exist.
   * @return {OptionVariant | undefined}
   */
  getFirstVariant: (
    ignoreInvisibleDefault?: boolean
  ) => OptionVariant | undefined;
  getPathToRoot: () => string[];
}

export interface ConfigurationMeta {
  configHierarchy: ConfigurationLevel[];
  getConfigurationLevel(configPath: string[]): ConfigurationLevel | null;
  getAllOptionsBelow(configPath: string[]): Option[];
  getAllOptions(): Option[];
  getAllCameraShots(): string[];
}

export class ConfigurationMetaImpl implements ConfigurationMeta {
  constructor(public configHierarchy: ConfigurationLevel[]) {}
  getAllCameraShots(): string[] {
    // go throgh each config and then child and collect camerashot and insert to list if unique
    return this.collectUniqueCameraShots(this.configHierarchy);
  }

  getConfigurationLevel(configPath: string[]): ConfigurationLevel | null {
    return this.getConfigurationLevelInternal(this.configHierarchy, configPath);
  }
  getAllOptionsBelow(configPath: string[]): Option[] {
    if (isEmpty(configPath)) {
      // special case, collect all options
      return flatten(
        this.configHierarchy.map((configLevel0) => {
          return this.getAllOptionsBelowInternal(configLevel0);
        })
      );
    }
    const configLevel = this.getConfigurationLevel(configPath);
    if (!configLevel) {
      return [];
    }
    return this.getAllOptionsBelowInternal(configLevel);
  }

  getAllOptions(): Option[] {
    return this.getAllOptionsBelow([]);
  }

  private getConfigurationLevelInternal(
    configLevels: ConfigurationLevel[],
    configPath: string[]
  ): ConfigurationLevel | null {
    if (!configPath.length) {
      return null;
    }
    for (const entry of configLevels) {
      if (entry.id === configPath[0]) {
        if (configPath.length === 1) {
          return entry;
        }
        const children = entry.children;
        if (children) {
          return this.getConfigurationLevelInternal(
            children,
            configPath.slice(1)
          );
        }
      }
    }
    return null;
  }

  private getAllOptionsBelowInternal(
    configLevel: ConfigurationLevel | undefined
  ) {
    if (!configLevel) {
      return [];
    }
    let collectedOptions: Option[] = [];
    if (configLevel.optspec.length > 0) {
      configLevel.optspec.forEach((spec) => {
        collectedOptions.push(...spec.options);
      });
    }
    // const options = configLevel.options;
    // if (options) {
    //   collectedOptions = collectedOptions.concat(options);
    // }
    const children = configLevel.children;
    if (children) {
      children.forEach((child) => {
        collectedOptions = collectedOptions.concat(
          this.getAllOptionsBelowInternal(child)
        );
      });
    }
    return collectedOptions;
  }

  private collectUniqueCameraShots(data: any[], result: string[] = []) {
    data.forEach((item) => {
      if (item.cameraShot) {
        item.cameraShot.forEach((shot: string) => {
          if (!result.includes(shot)) {
            result.push(shot);
          }
        });
      }
      if (item.children && item.children.length > 0) {
        this.collectUniqueCameraShots(item.children, result);
      }
    });
  
    return result;
  }
}

export class ConfigurationLevelImpl implements ConfigurationLevel {
  constructor(
    public id: string,
    public name: string,
    public isMenu: boolean,
    public optspec: OptionSpecification[],
    public options: Option[],
    public componentType?: string,
    public perspectives?: string[],
    public cameraShot?: string[],
    public parent?: ConfigurationLevel,
    public children?: ConfigurationLevel[],
    public suggestions?: boolean,
    public alwaysSelected?: boolean
  ) {
    if (this.optspec) {
      this.optspec = this.optspec.filter(spec => spec.options.filter(f => !f.id.includes('364000000')).some(ev => ev.selectable));
      this.optspec.forEach((spec) => {
        options = [...spec.options];
      });
    }
  }

  getPathToRoot(): string[] {
    return this.getPathToRootInternal(this, []).map(
      (configLevel) => configLevel.id
    );
  }

  getConfigurationsToRoot(): ConfigurationLevel[] {
    return this.getPathToRootInternal(this, []);
  }

  getAllChildOptions(): Option[] {
    let allOptions: Option[] = [];
    if (this.optspec && this.optspec.length > 0) {
      this.optspec.forEach((spec) => {
        allOptions = [...spec.options];
      });
    }
    if (this.children) {
      this.children.forEach((configChild) => {
        allOptions = [...allOptions, ...configChild.getAllChildOptions()];
      });
    }
    return allOptions;
  }

  hasOptions(onlySelf: boolean = false): boolean {
    if (!isEmpty(this.options)) {
      return true;
    }
    return !onlySelf && !!this.children
      ? !!this.children.find((configChild) => {
          return configChild.hasOptions();
        })
      : false;
  }

  hasSpecificOption(optionId: string): boolean {
    return this.options.some((opt) => opt.id === optionId);
  }

  private getPathToRootInternal(
    configLevel: ConfigurationLevel,
    path: ConfigurationLevel[]
  ): ConfigurationLevel[] {
    if (!configLevel) {
      return path;
    }
    if (configLevel.parent) {
      this.getPathToRootInternal(configLevel.parent, path);
    }
    path.push(configLevel);
    return path;
  }
}

export interface VehicleConfigurationMeta {
  id: string;
  description: string;
  configuration: ConfigurationMeta;

  getOption(id: string, varientId?: string | null): Option | undefined;
  areOptionsInSameLevel(optionIds: string[]): boolean;
  isInvisibleDefaultVariant(variantId: string | undefined): boolean;
  getInvisibleDefaultVariant(): string;
  getOptionFromLevel(
    optionList: OptionWithVariant[],
    targetLevel: string[]
  ): Option | undefined;
  getDefaultOption(
    targetLevel: string,
    optionList?: OptionWithVariant[]
  ): Option | undefined;
  changeOptionVisibility(isSelectable: boolean, id: string, varientId?: string | null): void;
}

export class VehicleConfigurationMetaImpl implements VehicleConfigurationMeta {
  VARIANT_EXTRACAMPIONARIO_NOT_STANDARD = "364000000";
  constructor(
    public id: string,
    public description: string,
    public configuration: ConfigurationMeta
  ) {}
  changeOptionVisibility(isSelectable: boolean, id: string, varientId?: string | null | undefined): void {
    const option = this.getOption(id, varientId);
    if (!!option){
      option.selectable = isSelectable;
    }
  }
  getDefaultOption(
    targetLevel: string,
    optionList?: OptionWithVariant[] | undefined
  ): Option | undefined {
    let foundoption: Option | undefined;
    // we need to get target level that should be root like exterior/interior/equipment
    // note to filter VARIANT_EXTRACAMPIONARIO_NOT_STANDARD
    const parentLevel = this.configuration.getConfigurationLevel([targetLevel]);
    if (!!parentLevel) {
      foundoption = this.getDefaultoptionInternal(parentLevel, optionList);

      // if (optionList && optionList.length > 0){
      //   // if param optionList has items search in children of target and get back the best match option
      //   for(let optList of optionList){
      //     const opt = this.getOptionFromHierarchy(optList.optionId, parentLevel, optList.variantId);
      //     if (!!opt){
      //       // const variantId = (opt.values && opt.values.length > 0) ? opt.values[0].variantId : undefined;
      //       // if (!variantId){
      //         // if (!this.isInvisibleDefaultVariant(variantId)){
      //           return opt;
      //         // }
      //       // }
      //     }
      //   }
      // } else{
      //   // if empty list just send the first option in first child
      //   foundoption = this.getDefaultoptionInternal(parentLevel);
      // }
    }

    return foundoption;
  }

  isInvisibleDefaultVariant(variantId: string | undefined): boolean {
    return this.VARIANT_EXTRACAMPIONARIO_NOT_STANDARD === variantId;
  }

  getInvisibleDefaultVariant(): string {
    return this.VARIANT_EXTRACAMPIONARIO_NOT_STANDARD;
  }

  getOption(id: string, varientId?: string | null): Option | undefined {
    for (const configHierarchy of this.configuration.configHierarchy) {
      const foundOption = this.getOptionFromHierarchy(
        id,
        configHierarchy,
        varientId
      );
      if (foundOption) {
        const option: Option = {
          ...foundOption,
        };
        return option;
      }
    }
    return undefined;
  }

  // getColorOption(id: string, variantId: string): Option | undefined {
  //   for (const configHierarchy of this.configuration.configHierarchy) {
  //     const foundOption = this.getOptionFromHierarchy(id, configHierarchy);
  //     if (foundOption) {
  //       if (foundOption.values) {
  //         const indx = foundOption.values.findIndex(
  //           (v) => v.variantId === variantId
  //         );
  //       }
  //     }
  //   }
  //   return undefined;
  // }

  areOptionsInSameLevel(optionIds: string[]): boolean {
    if (!optionIds || isEmpty(optionIds)) {
      return false;
    }
    // find level of first option
    const option = this.getOption(optionIds[0]);
    if (!option) {
      console.warn(`Unknown option [${optionIds[0]}]`);
      return false;
    }
    return (
      difference(
        optionIds,
        option.parentConfiguration
          .getAllChildOptions()
          .map((currentOption) => currentOption.id)
      ).length === 0
    );
  }

  isConflictMessageShouldApply(
    optionIds: string[],
    uiConflicts: string[]
  ): boolean {
    if (!optionIds || isEmpty(optionIds)) {
      return false;
    }
    // find level of first option
    const option = this.getOption(optionIds[0]);
    if (!option) {
      console.warn(`Unknown option [${optionIds[0]}]`);
      return false;
    }

    let isExcluded = false;
    for (let i = 0; i < uiConflicts.length; i++) {
      // tslint:disable-next-line: no-shadowed-variable
      const ConfigurationLevel = this.configuration.getConfigurationLevel(
        uiConflicts[i].split("|")
      );
      isExcluded = isEqual(ConfigurationLevel, option.parentConfiguration);
      if (isExcluded) {
        return isExcluded;
      }
    }

    return isExcluded;
  }

  getDefaultOrFirstVariant(
    optionId: string | undefined,
    ignoreInvisibleDefault: boolean = true,
    includeVariantIds?: string[]
  ): OptionVariant | undefined {
    if (!optionId) {
      return;
    }
    const optionMeta = this.getOption(optionId);
    if (!optionMeta || !optionMeta.variants) {
      return;
    }
    const checkVariantInclude = (variant: OptionVariant) => {
      return includeVariantIds && !isEmpty(includeVariantIds)
        ? includeVariantIds.indexOf(variant.id) > -1
        : true;
    };
    const defaultVariant = optionMeta.getDefaultVariant();
    if (defaultVariant && checkVariantInclude(defaultVariant)) {
      return defaultVariant;
    }
    const firstVariant = optionMeta.getFirstVariant(ignoreInvisibleDefault);
    if (firstVariant && checkVariantInclude(firstVariant)) {
      return firstVariant;
    }
    const validVariants = optionMeta.variants.filter((variant) => {
      if (!includeVariantIds) {
        return true;
      }
      return includeVariantIds.indexOf(variant.id) > -1;
    });
    const foundVariant = validVariants.find((variant) => {
      if (!ignoreInvisibleDefault) {
        return true;
      }
      return !this.isInvisibleDefaultVariant(variant.id);
    });
    if (foundVariant) {
      return foundVariant;
    }
    // last resort, return first of validVariants
    return isEmpty(validVariants) ? undefined : validVariants[0];
  }

  getOptionFromLevel(
    optionList: OptionWithVariant[],
    targetLevel: string[]
  ): Option | undefined {
    //pass the list of options and get the target level
    // example of exterior color: ["exterior","paintwork","paint"]
    const allOptionsBelowTarget =
      this.configuration.getAllOptionsBelow(targetLevel);
    const bestMatch = optionList.find((val) =>
      allOptionsBelowTarget.some((a) =>
        a.values
          ? a.values.some(
              (v) =>
                v.optionId === val.optionId && v.variantId === val.variantId
            )
          : false
      )
    );
    const configLevel = this.configuration.getConfigurationLevel(targetLevel);
    if (bestMatch && configLevel) {
      return this.getOptionFromHierarchy(
        bestMatch.optionId,
        configLevel,
        bestMatch.variantId
      );
    }
    return undefined;
  }

  private getOptionFromHierarchy(
    optionId: string,
    configHierarchy: ConfigurationLevel,
    variantId?: string | null
  ): Option | undefined {
    let foundOption =
      configHierarchy.options &&
      configHierarchy.options.find((option) =>
        option.values
          ? option.values.some(
              (s) =>
                s.optionId === optionId &&
                (s.variantId ? s.variantId === variantId : true)
            )
          : false
      );
      if (!foundOption && configHierarchy.options.length === 1){
        // we have some problems for optionals that has rule but not option row in sales.xml for ex. CRPT so if it has only 364000000 do not consider variantId for search
        if (configHierarchy.options.some(s => (s.values ? s.values.some(v => v.optionId === optionId && (v.variantId === this.VARIANT_EXTRACAMPIONARIO_NOT_STANDARD && !variantId)) : false))){
          foundOption = configHierarchy.options[0];
        }
      }
    if (!foundOption && configHierarchy.children) {
      for (const configHierarchyChild of configHierarchy.children) {
        if (!foundOption && configHierarchyChild.children) {
          foundOption = this.getOptionFromHierarchy(
            optionId,
            configHierarchyChild,
            variantId
          );

          if (
            !foundOption &&
            configHierarchyChild.children &&
            configHierarchyChild.children.length > 0
          ) {
            for (const configHierarchyGrandChild of configHierarchyChild.children) {
              foundOption = this.getOptionFromHierarchy(
                optionId,
                configHierarchyGrandChild,
                variantId
              );
              if (foundOption) {
                const option: Option = {
                  ...foundOption,
                  getPathToRoot: () => {
                    return configHierarchyGrandChild
                      .getPathToRoot()
                      .concat(optionId);
                  },
                };
                foundOption = cloneDeep(option);
                break;
              }
            }
          } else {
            foundOption = this.getOptionFromHierarchy(
              optionId,
              configHierarchyChild,
              variantId
            );
            if (foundOption) {
              const option: Option = {
                ...foundOption,
                getPathToRoot: () => {
                  return configHierarchyChild.getPathToRoot().concat(optionId);
                },
              };
              foundOption = cloneDeep(option);
              break;
            }
          }
        }
      }
    }

    return foundOption;
  }

  private getDefaultoptionInternal(
    hirarchyLevel?: ConfigurationLevel,
    optionList?: OptionWithVariant[]
  ): Option | undefined {
    let foundoption: Option | undefined;
    if (!!hirarchyLevel) {
      if (hirarchyLevel.children) {
        if (hirarchyLevel.children.length > 0) {
          return this.getDefaultoptionInternal(
            hirarchyLevel.children[0],
            optionList
          );
        } else {
          // target
          if (!!optionList && optionList.length > 0) {
            // Ex. For preconfiguration highlight
            const filteredOptionList = cloneDeep(
              optionList.filter(
                (f) =>
                  f.variantId !== this.VARIANT_EXTRACAMPIONARIO_NOT_STANDARD
              )
            );
            foundoption = hirarchyLevel.options.find((x) =>
              filteredOptionList.some((s) =>
                x.values
                  ? s.optionId === x.values[0].optionId &&
                    s.variantId === x.values[0].variantId
                  : false
              )
            );
          } else {
            foundoption = hirarchyLevel.options.find((x) =>
              x.values
                ? x.values[0].variantId !==
                  this.VARIANT_EXTRACAMPIONARIO_NOT_STANDARD
                : true
            );
          }
          if (!!foundoption) {
            const option: Option = {
              ...foundoption,
              getPathToRoot: () => {
                return hirarchyLevel.getPathToRoot();
              },
            };
            foundoption = cloneDeep(option);
          }
        }
      }
    }
    return foundoption;
  }
}

export class VehicleConfigurationBuilder {
  // create configuration from json file
  constructor() {}

  buildVehicleConfigurationMeta(
    guiStructureNode: any,
    vehicleInfo: Car
  ): VehicleConfigurationMeta {
    const configuration = this.buildVehicleConfiguration(guiStructureNode);

    if (!configuration) {
      throw new Error("Got vehicle with missing [configuration].");
    }

    const vehicle = new VehicleConfigurationMetaImpl(
      vehicleInfo.vehicleId,
      vehicleInfo.name,
      configuration
    );
    return vehicle;
  }

  private buildVehicleConfiguration(guiStructur: any): ConfigurationMeta {
    // go throgh each subConfig and fill into ConfigurationLevel (we assume 3 level for all)

    const configlevel = this.createConfigObjects(guiStructur);
    this.parseConfig(configlevel);

    const configuration = new ConfigurationMetaImpl(configlevel);

    return configuration;
  }

  createConfigObjects(configArray: any[]): ConfigurationLevel[] {
    const result: ConfigurationLevel[] = [];
    for (const config of configArray) {
      const options: Option[] = this.fillAllOptions(config.optspec);
      const configObject = new ConfigurationLevelImpl(
        config.id,
        config.name,
        config.isMenu,
        config.optspec,
        options,
        config.componentType,
        config.perspectives,
        config.cameraShot,
        undefined,
        undefined,
        undefined,
        config.alwaysSelected
      );
      if (config.subConfig) {
        configObject.children = this.createConfigObjects(config.subConfig);
        configObject.parent = config;
      }
      result.push(configObject);
    }
    return result;
  }

  fillAllOptions(optspec: any[]): Option[] {
    const result: Option[] = [];
    if (optspec.length > 0) {
      optspec.forEach((spec) => {
        result.push(...spec.options);
      });
    }
    return result;
  }

  parseConfig(config: ConfigurationLevel[], parent?: ConfigurationLevel) {
    config.forEach((item) => {
      item.parent = parent;
      if (item.children && item.children.length > 0) {
        this.parseConfig(item.children, item);
      }
    });
  }
}
