import { Injectable } from '@angular/core';
import { Observable ,  ConnectableObservable ,  merge ,  of ,  ReplaySubject ,  Subject ,  Subscriber } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map,
  publishReplay,
  share,
  shareReplay,
  startWith,
  switchMap,
  timeout
} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
  COMMAND_CAMERA_SHOT_TOUCH,
  COMMAND_CURRENT_ENVIRONMENT,
  COMMAND_IMAGE_STREAM_ENABLED,
  COMMAND_LISTEN_CONFIGURATION_APPLIED,
  COMMAND_LISTEN_DAY_NIGHT,
  COMMAND_LISTEN_LOCATION,
  COMMAND_REQUEST_CAMERA_SHOT,
  COMMAND_SUMMARY_VIEW_VISIBILITY,
  COMMAND_ZOOM,
  COMMAND_IS_LEVEL_ENVIRONMENT_LOADING
} from './application-commands';
import { ENDPOINT_GET_BEAUTYSHOTS } from './application-endpoints';
import { ApplicationStateService } from './application-state.service';
import { TOPIC_IMAGE_STREAM } from './application-topics';
import { StateLineClientService } from './state-line-client.service';
import { forOwn, isNumber } from 'lodash';

export enum AnimationGroup {
  ANIMATION_GROUP_DOOR_FRONT_LEFT = 'AnimationGroup_DoorFL',
  ANIMATION_GROUP_DOOR_FRONT_RIGHT = 'AnimationGroup_DoorFR',
  ANIMATION_GROUP_DOOR_REAR_RIGHT = 'AnimationGroup_DoorRR',
  ANIMATION_GROUP_DOOR_REAR_LEFT = 'AnimationGroup_DoorRL',
  ANIMATION_GROUP_GLOVE_BOX = 'AnimationGroup_GloveBox',
  ANIMATION_GROUP_BACKSEATS = 'AnimationGroup_Backseats',
  ANIMATION_GROUP_DOOR_TRUNK = 'AnimationGroup_DoorTrunk',
  ANIMATION_GROUP_ROOF_TOP = 'AnimationGroup_Rooftop',
  ANIMATION_GROUP_HOOD = 'AnimationGroup_Hood',
  ANIMATION_GROUP_REAR_WALL = 'AnimationGroup_Rearwall'
}

export const ANIMATION_GROUPS = [
  AnimationGroup.ANIMATION_GROUP_DOOR_FRONT_LEFT,
  AnimationGroup.ANIMATION_GROUP_DOOR_FRONT_RIGHT,
  AnimationGroup.ANIMATION_GROUP_DOOR_REAR_LEFT,
  AnimationGroup.ANIMATION_GROUP_DOOR_REAR_RIGHT,
  AnimationGroup.ANIMATION_GROUP_GLOVE_BOX,
  AnimationGroup.ANIMATION_GROUP_BACKSEATS,
  AnimationGroup.ANIMATION_GROUP_DOOR_TRUNK,
  AnimationGroup.ANIMATION_GROUP_ROOF_TOP,
  AnimationGroup.ANIMATION_GROUP_HOOD,
  AnimationGroup.ANIMATION_GROUP_REAR_WALL
];

export interface BeautyShotPayload {
  [key: string]: string;
}

export interface BeautyShot {
  id: string;
  uiPath: string;
}

@Injectable({
  providedIn: "root",
})
export class SceneStateService {

  private nightModeActive$: Observable<boolean> = of(false);
  private headlightsState$: Observable<boolean> = of(false);
  private interiorLocationActive$: Observable<boolean> = of(false);
  private zoomState$: Observable<number> = of(0);
  private beautyshotActive$: Observable<boolean> = of(false);
  private beautyshots$: Observable<BeautyShot[]> = of([]);
  private currentBeautyShot$: Observable<BeautyShot | undefined> =  of(undefined);
  private currentEnvironment$: Observable<string | undefined> = of("");
  private summaryState$: Observable<boolean>  = of(false);
  private configurationChangePending$: Observable<boolean> = of(false);
  private sceneImageStream$: Observable<string> = of("");
  private sceneImageStreamUrl$: Observable<URL> = of();

  private environmentLoading$: Observable<boolean> | undefined;

  private readonly animationGroupStateObservables = new Map<AnimationGroup, Observable<boolean>>();
  private readonly configurationChangeTriggeredSubject = new Subject<void>();

  constructor (
    private applicationStateService: ApplicationStateService,
    private stateLineClientService: StateLineClientService
  ) {
    // this.initializeObservables();
  }

  getNightModeActive () {
    return this.nightModeActive$;
  }

  getInteriorLocationActive () {
    return this.interiorLocationActive$;
  }

  getBeautyshotActive () {
    return this.beautyshotActive$;
  }

  getCurrentBeautyShot () {
    return this.currentBeautyShot$;
  }

  getBeautyshots () {
    return this.beautyshots$;
  }

  getZoomState () {
    return this.zoomState$;
  }

  getHeadlightState () {
    return this.headlightsState$;
  }

  getSummaryState () {
    return this.summaryState$;
  }

  getConfigurationChangePending () {
    return this.configurationChangePending$;
  }

  getIsEnvironmentLoading () {
    return this.environmentLoading$;
  }

  /**
   * Returns an Observable that, when subscribed to, enables image-streaming in the application
   * and provides base64 encoded image-data to the subscriber.
   * when no subscribers are left, image-streaming is disabled in the application again.
   */
  getSceneImageStream () {
    return this.sceneImageStream$;
  }

  /**
   * Returns an Observable that, when subscribed to, enables image-streaming in the application
   * and provides the URL that may be used as a src for an image to the subscriber.
   * When no subscribers are left, image-streaming is disabled in the application again.
   */
  getSceneImageStreamUrl () {
    return this.sceneImageStreamUrl$;
  }

  /**
   * Call when a configuration-change (select) has been sent to the backend.
   */
  notifyConfigurationChangeSent () {
    this.configurationChangeTriggeredSubject.next();
  }

  requestCameraShot (cameraShotId: string) {
    this.applicationStateService.publishCommand({
      type: COMMAND_REQUEST_CAMERA_SHOT,
      identifier: cameraShotId
    });
  }

  leaveBeautyShotIfActive () {
    this.getBeautyshotActive()
      .pipe(
        first(),
        filter((isActive) => isActive),
        switchMap(() => {
          return this.getInteriorLocationActive()
            .pipe(
              first(),
              map((isInteriorActive) => {
                if (isInteriorActive) {
                  this.applicationStateService.publishCommand({
                    type: COMMAND_REQUEST_CAMERA_SHOT,
                    identifier: 'interiorStart'
                  });
                } else {
                  this.applicationStateService.publishCommand({
                    type: COMMAND_REQUEST_CAMERA_SHOT,
                    identifier: 'exteriorStart'
                  });
                }
              })
            );
        })
      )
      .subscribe(() => void 0, () => void 0);
  }

  /**
   * Gets the state-observable for a given animation group or undefined if no such animation-group is registered.
   * @param {AnimationGroup} animationGroup
   * @return {Observable<boolean> | undefined}
   */
  getAnimationGroupState (animationGroup: AnimationGroup): Observable<boolean> | undefined {
    return this.animationGroupStateObservables.get(animationGroup);
  }

  getCurrentEnvironment () {
    return this.currentEnvironment$;
  }

  public initializeObservables () {
    this.nightModeActive$ = this.applicationStateService.subscribeToApplicationCommand(COMMAND_LISTEN_DAY_NIGHT)
      .pipe(
        map((payload) => {
          if (payload && payload.identifier) {
            return payload.identifier === 'Night';
          }
          return false;
        }),
        publishReplay(1)
      );
    this.connectConnectableObservable(this.nightModeActive$);

    this.headlightsState$ = this.applicationStateService.subscribeToApplicationCommand('Headlights')
      .pipe(
        startWith({
          type: 'Headlights',
          identifier: 'Off'
        }),
        map((command) => {
          if (!command) {
            return false;
          }
          return command.identifier === 'On';
        }),
        publishReplay(1)
      );
    this.connectConnectableObservable(this.headlightsState$);

    this.interiorLocationActive$ = this.applicationStateService.subscribeToApplicationCommand(COMMAND_LISTEN_LOCATION)
      .pipe(
        map((payload) => {
          if (payload && payload.identifier) {
            return payload.identifier === 'int';
          }
          return false;
        }),
        publishReplay(1)
      );
    this.connectConnectableObservable(this.interiorLocationActive$);

    // this.beautyshots$ = Observable.create((observer: any) => {

    //   const subscription = this.stateLineClientService.callJson<BeautyShotPayload>(ENDPOINT_GET_BEAUTYSHOTS, null, true)
    //     .pipe(
    //       map((beautyShotsPayload) => {
    //         const beautyShots: BeautyShot[] = [];
    //         if (beautyShotsPayload && (<any> beautyShotsPayload).success !== false) {
    //           forOwn(beautyShotsPayload, (id, uiPath) => {
    //             beautyShots.push({
    //               id: id,
    //               uiPath: uiPath
    //             });
    //           });
    //         }
    //         return beautyShots;
    //       }),
    //       map((beautyShots) => {
    //         // sort by ext/int
    //         return beautyShots.sort((a, b) => {
    //           return a.id.localeCompare(b.id);
    //         });
    //       })
    //     )
    //     .subscribe((beautyShots) => {
    //       observer.next(beautyShots);
    //     }, (reason) => {
    //       observer.error(reason);
    //     }, () => {
    //       observer.complete();
    //     });

    //   return () => {
    //     try {
    //       subscription.unsubscribe();
    //     } catch (error) {}
    //   };
    // });

    this.currentBeautyShot$ = this.applicationStateService.subscribeToApplicationCommand(COMMAND_CAMERA_SHOT_TOUCH)
      .pipe(
        map((payload: any) => {
          if (payload && payload.identifier) {
            return payload.identifier;
          }
        }),
        switchMap((cameraShotIdentifier) => {
          if (!cameraShotIdentifier) {
            return of(undefined);
          }
          return this.getBeautyshots()
            .pipe(
              map((beautyShots) => {
                if (beautyShots) {
                  return beautyShots.find(beautyShot => beautyShot.id === cameraShotIdentifier);
                }
                return undefined;
              })
            );
        }),
        publishReplay(1)
      );
    this.connectConnectableObservable(this.currentBeautyShot$);

    this.currentEnvironment$ = this.applicationStateService.subscribeToApplicationCommand(COMMAND_CURRENT_ENVIRONMENT)
      .pipe(
        map((payload): string | undefined => {
          return payload && payload.stringValue;
        }),
        publishReplay(1)
      );
    this.connectConnectableObservable(this.currentEnvironment$);

    this.beautyshotActive$ = this.currentBeautyShot$
      .pipe(
        map((beautyShot): boolean => {
          return !!beautyShot;
        }),
        shareReplay(1)
      );

    this.zoomState$ = merge(
      this.applicationStateService.subscribeToApplicationCommand(COMMAND_ZOOM),
    ).pipe(
        map((payload) => {
          if (payload && isNumber(payload.floatValue)) {
            return payload.floatValue;
          }
          return .8;
        }),
        distinctUntilChanged(),
        publishReplay(1)
      );
    this.connectConnectableObservable(this.zoomState$);

    this.summaryState$ = this.applicationStateService.subscribeToApplicationCommand(COMMAND_SUMMARY_VIEW_VISIBILITY)
      .pipe(
        map((payload) => {
          if (payload) {
            return payload.identifier === 'True';
          }
          return false;
        }),
        publishReplay(1)
      );
    this.connectConnectableObservable(this.summaryState$);

    this.configurationChangePending$ = this.configurationChangeTriggeredSubject
      .pipe(
        switchMap(() => {
          // when a configuration-change has been triggered, wait X seconds for the OnProductUpdateApplied command to come in
          return merge(of(true), this.stateLineClientService
            .subscribeToCommand(COMMAND_LISTEN_CONFIGURATION_APPLIED)
            .pipe(
              first(),
              // timeout(environment.appConfig.applyConfigurationTimeout),
              timeout(10000),
              catchError((reason) => {
                return of(false);
              }),
              map(() => {
                return false;
              })
            ));
        }),
        startWith(false),
        publishReplay(1)
      );
    this.connectConnectableObservable(this.configurationChangePending$);

    this.environmentLoading$ = this.applicationStateService.subscribeToApplicationCommand(COMMAND_IS_LEVEL_ENVIRONMENT_LOADING)
      .pipe(
        map((payload) => {
          if (payload) {
            return !!payload.boolValue;
          }
          return false;
        }),
        publishReplay(1)
      );
    this.connectConnectableObservable(this.environmentLoading$);

    this.sceneImageStream$ = this.createSceneImageStreamObservable();
    // this.sceneImageStreamUrl$ = this.createSceneImageStreamUrlObservable();

    this.initializeActionStatesObservables();
  }

  private initializeActionStatesObservables () {
    ANIMATION_GROUPS.forEach((animationGroupId: string) => {
      const stateObserver$ = this.applicationStateService
        .subscribeToApplicationCommand(animationGroupId)
        .pipe(
          map((applicationCommand) => {
            if (!applicationCommand) {
              return false;
            }
            return applicationCommand.identifier === 'Active';
          }),
          publishReplay(1)
        );
      this.connectConnectableObservable(stateObserver$);
      this.animationGroupStateObservables.set(
        <AnimationGroup> animationGroupId,
        stateObserver$
      );
    });
  }

  private createSceneImageStreamObservable () {
    return this.createImageStreamTogglingObservable(
      this.stateLineClientService.subscribe<string[]>(TOPIC_IMAGE_STREAM)
        .pipe(
          map((imageStringArray: string[]) => {
            if (imageStringArray && imageStringArray.length) {
              return imageStringArray[0];
            }
            return void 0;
          }),
          filter((imageString: string | undefined) => !!imageString)
        )
    ).pipe(
      publishReplay()
    );
  }

  private createSceneImageStreamUrlObservable (): Observable<URL> {
    // create a non-completing replay-subject that emits the image-stream URL once.
    const imageStreamSubject = new ReplaySubject<URL>(1);
    imageStreamSubject.next(new URL(environment.streamingConfig.httpImageStreamUrl));

    return this.createImageStreamTogglingObservable<URL>(
      imageStreamSubject
    ).pipe(
      publishReplay()
    );
  }

  private createImageStreamTogglingObservable<T> (innerObservable: Observable<T>) {
    return Observable.create((subscriber: Subscriber<T>) => {

      const imageStreamUrlSubscription = this.applicationStateService
        .publishCommand({
          type: COMMAND_IMAGE_STREAM_ENABLED,
          boolValue: true
        })
        .pipe(
          switchMap(() => {
            return innerObservable;
          })
        )
        .subscribe(subscriber);

      return () => {
        this.applicationStateService.publishCommand({
          type: COMMAND_IMAGE_STREAM_ENABLED,
          boolValue: false
        });
        imageStreamUrlSubscription.unsubscribe();
      };
    });
  }

  private connectConnectableObservable (observable: Observable<any>) {
    (<ConnectableObservable<any>> observable).connect();
  }

}