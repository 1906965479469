<div class="container" (click)="goToComponent()">
  <div class="text-area">
    <div class="title-section">
      <span [ngClass]="{'title-museum' : isMuseum}">{{ item ? (item.name | translate) : ("" | translate) }}</span>
    </div>
    <div class="opt-name">
      <ng-container *ngIf="selectedItems.length === 0">
        <span [ngClass]="{'opt-name-museum' : isMuseum}">{{ "RT.CHOOSE_OPTION" | translate }}</span>
      </ng-container>
      <ng-container *ngIf="selectedItems.length > 0">
        <ng-container *ngFor="let item of selectedItems; let isLast = last">
          <!-- <span>{{ item.id.includes('_')? item.name.toLowerCase() : ("RT.OPTION_NAME." + item.id) | translate }}</span> -->
          <!-- <span [ngClass]="{'opt-name-museum' : isMuseum}">{{ ((("RT.OPTION_NAME." + item.id) | translate) !== '') && ((("RT.OPTION_NAME." + item.id) | translate) !== "RT.OPTION_NAME." + item.id) ? (("RT.OPTION_NAME." + item.id) | translate) : item.name.toLowerCase() }}</span> -->

          <!-- TEMPORARY, TO BE CHANGED -->
          <span [ngClass]="{'opt-name-museum' : isMuseum}">{{ ((("RT.OPTION_NAME." + ((car$ | async)?.id | uppercase) + "." + item.id) | translate) !== '') && ((("RT.OPTION_NAME." + ((car$ | async)?.id | uppercase) + "." + item.id) |
            translate) !== "RT.OPTION_NAME." + ((car$ | async)?.id | uppercase) + "." + item.id) ? (("RT.OPTION_NAME." + ((car$ | async)?.id | uppercase) + "." + item.id) | translate) :
            ((("RT.OPTION_NAME." + item.id) | translate) !== '') && ((("RT.OPTION_NAME." + item.id) |
                translate) !== "RT.OPTION_NAME." + item.id) ? (("RT.OPTION_NAME." + item.id) | translate) :
                item.name.toLowerCase() }}</span>
          <span *ngIf="!isLast" [ngClass]="{'opt-name-museum' : isMuseum}">{{ ", " }}</span>
        </ng-container>
      </ng-container>
      <!-- <span *ngIf="selectedItems.length > 0">{{ selectedItem.name }}</span>
      <span *ngIf="!selectedItem">{{ "RT.CHOOSE_OPTION" | translate }}</span> -->
    </div>
  </div>
  <div class="img-area">
    <div class="img-container" *ngIf="selectedItems.length > 0">
      <img
        src="../../../../rt-assets/data/cars/{{ (car$ | async)?.id }}/ui/assets/{{ item ? (item.id.toLowerCase().includes('carbon') ? 'carbon' :
          selectedItems[0].id) : selectedItems[0].id
        }}.png"
        onerror="this.style.display='none'"
      />
    </div>
  </div>
</div>
