import { EnvironmentInterface } from "./environment-interface";

export const environment: EnvironmentInterface = {
  production: false,
  enableLog: true,
  isChina: true,
  GTM_ID: "GTM-KLDD6J",
  carConfigListFileName: 'car-config-list.china.stg.json',
  appConfig: {
    cloudFrontUrl: "",
    apiUrl:
      "https://a6xsirr65d.execute-api.cn-northwest-1.amazonaws.com.cn/api",
    oAuthUrl: "https://login.test.ferrari.com/connect",
    oAuthClientId: "", //"mck_vision_test",
    oAuthClientSecret: "", //"7BijWEZszgLU3IFo86ge",
    useForceRender: false,
  },
  streamingConfig: {
    wampSharpPort: "",
    realm: "realm1",
    isSecureCommunication: true,
    internalCustomerId: "",
    assetsBaseUrl: "",
    statelineUrl: "",
    keepAliveInterval: 1000,
    manualUrl: "",
    applyConfigurationTimeout: 0,
    httpImageStreamUrl: "",
    debugAnalyticsEvents: false,
    forceUseLocalTranslations: false,
    user: "mackevision",
    password: "m1acke",
    inactivityTimeout: 5, //minutes
  },
  monkeyWayConfig: {
    checkAvailabilityPollingInterval: 60000,
    carConfigList: [
      {
        modelId: "f175",
        appEnvId: "1a55a035-d4a7-4a17-ba95-ef91bb5fadcb",
        apiKey: "de95775e-57b7-4ae2-a4ee-2b3af2cf9fe5",
        baseUrl: "https://ferrari-dev.mw.gorillastreaming.com"
      },
      {
        modelId: "f167",
        appEnvId: "03141318-1b19-4f81-ba5c-e799fc0f55eb",
        apiKey: "d11d6278-68b3-4a69-8a35-49d642a6d359",
        baseUrl: "https://ferrari-dev.mw.gorillastreaming.com"
      },
      {
        modelId: "f167sp",
        appEnvId: "a38d892c-af4f-4a18-a769-82005e97b6d7",
        apiKey: "38903bcf-5cce-49d8-8702-4b54840c43f4",
        baseUrl: "https://ferrari-dev.mw.gorillastreaming.com"
      },
      {
        modelId: "f169_sp",
        appEnvId: "",
        apiKey: "",
        baseUrl: ""
      },
      {
        modelId: "f171",
        appEnvId: "",
        apiKey: "",
        baseUrl: ""
      },
      {
        modelId: "f171sp",
        appEnvId: "",
        apiKey: "",
        baseUrl: ""
      }
    ],
  },
  loginConfig: {
    url:"https://iam.dev.ferrari.com/devferrarib2ctenant.onmicrosoft.com/oauth2/v2.0/authorize/",
    p:"B2C_1A_SIGNUP_SIGNIN_EXT",
    clientId:"d3389f2f-f4d1-4349-85c3-8a053efd371c",
    nonce: "defaultNonce",
    redirectUri: "https://carconfigurator.test.ferrari.cn/iam_authorize",
    scopes: ["openid"], // user.read di Azure ??
    responseType: "code"
  },
  includeExternalJavascript: false,
};
