import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
} from "@angular/router";
import { Store } from "@ngrx/store";
import {
  Observable,
  combineLatest,
  first,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs";
import { Api } from "src/app/core/services/_api";
import { AnalyticsService } from "src/app/core/services/analytics.service";
import { ModelConfigurationService } from "src/app/core/services/model-configuration.service";
import { SessionStorageService } from "src/app/core/services/session-storage.service";
import { UtilityService } from "src/app/core/services/utility.service";
import { RT_CONFIGURATOR_URL } from "src/app/shared/application-constants";
import { FerrariState } from "src/app/shared/application-interfaces";

@Injectable({
  providedIn: "root",
})
export class RetriveCodeResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private modelConfig: ModelConfigurationService,
    private api: Api,
    private sessionStorage: SessionStorageService,
    private store: Store<FerrariState>,
    private util: UtilityService,
    private analytics: AnalyticsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    console.log("[RESOLVER] RetriveCodeResolver");
    const ferrariCode = route.paramMap.get("ferrariCode");
    !!route.queryParams["dealerCode"] ? this.sessionStorage.dealer_code = route.queryParams["dealerCode"] : this.sessionStorage.dealer_code = '';
    if (ferrariCode) {
      return this.api.loadConfiguration(ferrariCode).pipe(
        switchMap((response: any) => {
          const selectedCar$ = this.util.getCarModel(response.carId);
          const packageId = response.packageId? response.packageId : response.values.some(
            (x: any) => x.optionId == "FIO1" || x.optionId == "FIO2"
          )
            ? "fiorano"
            : response.values.some((x: any) => x.optionId == "EXCL")
            ? "design"
            : "default";
          return combineLatest([of(response), selectedCar$, of(packageId)]);
        }),
        map((data) => {
          const response = data[0];
          const car = data[1];
          const packageId = data[2];
          if (!!car && !!packageId) {
            this.analytics.loadConfiguration(ferrariCode, car, packageId);
            if(car.isEnabledInRealTime) {
              this.router.navigate(
                [
                  this.util.getCurrentLocale(),
                  RT_CONFIGURATOR_URL,
                  car?.id,
                  packageId,
                  "exterior",
                ],
                {
                  queryParams: {
                    configuration: response.configurationId,
                    isStreaming: (car.hasStreaming? true : false)
                  },
                }
              );
            } else {
              if (this.isPremium(response.location)) {
                window.location.href = `${document.location.origin}/${this.util.getCurrentLocale()}/car/ferrari_premium_configurator/${car.id}/${packageId}/(ConfigBody:summary)?configuration=${response.configurationId}`;
              } else {
                window.location.href = `${document.location.origin}/${this.util.getCurrentLocale()}/ferrari_car_configurator/${car.id}/${packageId}/step/summary?configuration=${response.configurationId}`;
              }
            }
          }
        }),
        first()
      );
    } else {
      return of("");
    }
  }

  private isPremium(location: string): boolean {
    const premiumArray = ['ferrari_pos_application_New','ferrari_premium_application'];
    return premiumArray.some(s => s == location);
  }
}
