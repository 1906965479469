import { environment } from "src/environments/environment";
import { VehicleInfo } from "./vehicle-config";

export class Car implements VehicleInfo {
  id: string;
  name: string;
  code: string | undefined;
  active: boolean;
  packages: string[];
  order: number | undefined;
  isEnabledInRealTime: boolean;
  marketId: string;
  vehicleId: string;
  modelId?: string;
  des?: string;
  commercialModel?: string;
  startingConfigurationId?: number | undefined;
  hasStreaming: boolean;
  disable3D?: boolean;
  cwsId?: string;
  cobaPrefix?: string;
  carDetailsName?: string;
  hideInMenu?: boolean;
  ids? : string[]


  constructor(data: any) {
    this.id = data.id || "";
    this.code = data.code ? data.code : null;
    this.marketId = data.marketId ? data.marketId : "00";
    this.name = data.name || "";
    this.active = data.active || false;
    this.packages = data.packages || [];
    this.isEnabledInRealTime = data.isEnabledInRealTime ? data.isEnabledInRealTime : false;
    this.order = data.order || 0;
    this.modelId = data.modelId ? data.modelId : "";
    // these should come from sales.xml after read
    this.vehicleId = "";
    this.commercialModel = "";
    this.des = "";
    this.hasStreaming = data.hasStreaming && this.active;
    this.disable3D = data.disable3D ? data.disable3D : false;
    this.cwsId = data.cwsId;
    this.cobaPrefix = data.cobaPrefix;
    this.carDetailsName = data.carDetailsName;
    this.hideInMenu = data.hideInMenu ? data.hideInMenu : false;
    this.ids = data.ids || [];
  }

  nameUpper(): string {
    return this.name ? this.name.toUpperCase() : "";
  }
}
