import { Locale } from "../core/models/Locale";
import { Language } from "../core/models/Language";
import { Car } from "../core/models/Car";
import { FerrariState } from "../shared/application-interfaces";
import { Country } from "../core/models/Country";
import { VehicleConfigurationMeta, VehicleConfigurationMetaImpl, ConfigurationLevel } from '../core/models/configuration-meta';
import { Preconfiguration } from '../core/models/Preconfiguration';
import { VehicleConfig } from "../core/models/vehicle-config";
import { Dealer } from "../core/models/Dealer";
import { environment } from "src/environments/environment";
import { PreconfigurationMuseum } from "../core/models/PreconfigurationMuseum";

export const initialState: FerrariState = {
  data: {
    user: undefined,
    language: new Language({}),
    locale: new Locale({}),
    country: new Country({}),
    car: new Car({}),
    packageId: undefined,
    preconfigurationCode: "",
    vehicleConfigMeta: undefined,
    preconfiguration: new Preconfiguration({}),
    section: "",
    level: "",
    subLevel: "",
    dealers: new Array<Dealer>(),
    selectedDealer: undefined,
    saveResponse: undefined,
    ferrariId: 0,
    ferrariCode: undefined,
    configurationIdSaved: '',
    availableCarsList: [],
    preconfigurationMuseum: new PreconfigurationMuseum({})
  },
  ui: {
    isFirstTimeConfiguratorLoaded: true,
    currentEnvironment: null,
    isLoading: false,
    isLoadingStreaming: false,
    isLoadingSpinner: false,
    selectedItem: undefined,
    isFullscreen: false,
    isTurntable: false,
    isStreaming: false,
    isDaytime: true,
    showConfirmSave: false,
    animations: {},
    renders: undefined,
    startupDelay: false,
    hideSuperHeader: false,
    navigationActiveIndex: -1,
    emptySpaceHeight: 200,
    canSkipRulePopup: true,
    turnTableLoadingImage: [],
    isLoadingTurntable: false,
    renderStartTime: 0,
    renderDuration: 0,
    sectionIndex: 0,
    hasStreamingInstance: false,
    isInternalJourny: false,
    sessionIdStreaming: undefined,
    renderImageStep: 'default',
    isMuseum: false,
    museumStandBy: true,
    showMuseumPrintcarOverlay: false,
    isDownloadingPdfImages: false,
    isLoadingMuseumSelector: false
  },
  error: {
    error: null,
  },
};
