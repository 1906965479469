export class Language {
    code: string;
    name: string;
    native: string;

    constructor (data: any) {
      this.code = data.code ? data.code : 'en';
      this.name = data.name ? data.name : 'English';
      this.native = data.native ? data.native : 'English';
    }
}
