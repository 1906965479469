// inactivity.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, fromEvent, timer } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {
  private inactivityTimeout = environment.streamingConfig.inactivityTimeout; // Get the timeout from environment
  private countdownSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private countdownSubscription: Subscription | undefined;

  constructor() { }

  startInactivityTimer(onTimeout: () => void) {
    // const eachSecond$ = timer(0, 1000); // starts at 0 and gives 1,2,3 as values each 1000 milliseconds.
    const activity$ = fromEvent<MouseEvent>(document, 'mousemove');
    activity$
      .pipe(
        switchMap(() => timer(this.inactivityTimeout)),
        take(1)
      )
      .subscribe(() => {
        onTimeout(); // Execute the function on timeout
      });
  }

  startCountdown(initialSeconds: number): Observable<string> {
    this.countdownSubject.next(initialSeconds);

    return new Observable<string>((observer) => {
      this.countdownSubscription = timer(0, 1000).pipe(
        take(initialSeconds),
        map(() => {
          const remainingSeconds = this.countdownSubject.value;
          return this.formatTime(remainingSeconds);
        }),
        tap(() => {
          this.countdownSubject.next(this.countdownSubject.value - 1);
          if (this.countdownSubject.value === 0) {
            observer.complete();
          }
        })
      ).subscribe({
        next: (formattedTime) => observer.next(formattedTime),
        error: (error) => observer.error(error),
        complete: () => observer.complete()
      }
      );
    });
  }

  resetCountdown() {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
    this.countdownSubject.next(0);
  }

  getCountdown(): Observable<string> {
    return this.countdownSubject.pipe(
      map((remainingSeconds) => this.formatTime(remainingSeconds))
    );
  }

  private formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = this.padNumber(minutes);
    const formattedSeconds = this.padNumber(remainingSeconds);
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  private padNumber(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }


}
