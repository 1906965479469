import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { ConfigurationLevel } from "../../../core/models/configuration-meta";
import { ListItemComponent } from "../list-item/list-item.component";
import { MaterialModule } from "../../../shared/_modules/material.module";
import { FerrariState } from "src/app/shared/application-interfaces";
import { Store } from "@ngrx/store";
import { BackButtonComponent } from "../../../shared/components/back-button/back-button.component";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfigurationManagerService } from "src/app/core/services/configuration-manager.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { Observable, Subscription, fromEvent } from "rxjs";
import { setSelectedlevel } from "src/app/state/actions";

@Component({
  selector: "app-list",
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    ListItemComponent,
    BackButtonComponent,
  ],
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class ListComponent implements OnInit, OnDestroy {
  @Input() item?: ConfigurationLevel;
  listItems: ConfigurationLevel[] = [];
  isMobile = false;
  innerHeight = '100';
  public resizeObservable$?: Observable<Event>;
  resizeSubscription$?: Subscription;

  constructor(
    private store: Store<FerrariState>,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private cm: ConfigurationManagerService,
    private deviceInfo: DeviceDetectorService
  ) {}

  ngOnInit(): void {
    this.isMobile = this.deviceInfo.isMobile();
    this.innerHeight = window.innerHeight + 'px';
    // this.store
    //   .select((p) => p.data.vehicleConfigMeta)
    this.cm.getVehicleMeta()
      .subscribe((meta) => {
        if (!!meta) {
          const segments = this.router.url.split("/");
          const level1 = segments[segments.length - 3] || "";
          // const level1 =
          //   this.activeRoute.snapshot.paramMap.get("section") || "";
          const level2 = this.activeRoute.snapshot.paramMap.get("level") || "";

          const levelList = [level1, level2];
          const finalConfig =
            meta.configuration.getConfigurationLevel(levelList);
          if (!!finalConfig) {
            this.item = finalConfig;
            if (!!this.item.children) {
              this.item.children.forEach((child) => {
                if (
                  (!!child.children && child.children?.length > 0) ||
                  (!!child.options && child.options?.length > 0) ||
                  (!!child.optspec && child.optspec?.length > 0)
                ) {
                  // const totalCount = child.options.filter((f) => !f.id.includes("364000000")).length;
                  const selectableCount = child.options.filter((f) => !f.id.includes("364000000")).filter((ev) => ev.selectable).length;
                  if (
                    selectableCount > 0
                  ) {
                    this.listItems.push(child);
                  }
                }
              });
            }
          }
        }
      });

      this.resizeObservable$ = fromEvent(window, "resize");
      this.resizeSubscription$ = this.resizeObservable$.subscribe((event) => {
        this.innerHeight = window.innerHeight + 'px';
      });

    // if (!!this.item) {
    //   // here the input is second level we need to get all child and list them
    //   if (!!this.item.children){
    //     this.item.children.forEach(child => {
    //       this.listItems.push(child);
    //     });
    //   }
    // } else {
    //   // temporary
    //   this.store.select(p => p.data.vehicleConfigMeta).subscribe(meta => {
    //     if (meta){
    //       meta.configuration.configHierarchy[0].children?.forEach(child => {
    //         this.listItems.push(child);
    //       })
    //     }
    //   })
    // }
  }

  onTouchMove(event: TouchEvent) {
    if (event.touches.length >= 2) {
      event.preventDefault();
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(setSelectedlevel({ level: '' }));
    if (this.resizeSubscription$) {
      this.resizeSubscription$.unsubscribe();
    }
  }
}
