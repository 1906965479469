
import { cloneDeep } from 'lodash';
import {throwError as observableThrowError,  Observable ,  from as fromPromise ,  ConnectableObservable } from 'rxjs';
import { catchError, publishReplay, switchMap } from 'rxjs/operators';

export interface IntentInterceptor<T> {
  canProceed (context: T): Promise<void>;
}

class VetoableObservableHelper {

  /**
   * Create a vetoable observable chain.
   *
   * @param {I} input
   * @param {IntentInterceptor<I>[]} interceptors
   * @param {() => Observable<T>} observableFactory
   * @return {Observable<T>}
   */
  static create<T, I> (input: I, interceptors: IntentInterceptor<I>[], observableFactory: () => Observable<T>): Observable<T> {
    const interceptorPromise = interceptors.reduce((prevPromise, interceptor) => {
      return prevPromise.then(() => {
        return interceptor.canProceed(cloneDeep(input));
      });
    }, Promise.resolve());
    const result$ = <ConnectableObservable<T>> fromPromise(interceptorPromise)
      .pipe(
        catchError((error) => {
          console.log(`Execution of vetoable action [${input}] was rejected by interceptor`, error);
          return observableThrowError(error);
        }),
        switchMap(() => {
          return observableFactory();
        }),
        publishReplay(1)
      );
    result$.connect();
    return result$;
  }
}

export const createVetoableObservable = VetoableObservableHelper.create;