import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { ElementRef, Injectable } from "@angular/core";
import { StreamingService } from "@monkeyway/streaming-lib/dist/streaming/streaming";
import { ServiceOptions } from "@monkeyway/streaming-lib/dist/types/service-options";
import {
  ClientStreamInfo,
  StreamInfo,
} from "@monkeyway/streaming-lib/dist/types/stream-info";
import { StreamingControlService } from "@monkeyway/streaming-control-lib/dist/control";
import {
  Subject,
  Subscription,
} from "@monkeyway/streaming-lib/node_modules/rxjs";
import { Observable, of, throwError } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { FerrariState } from "src/app/shared/application-interfaces";
import { setSessionIdStreaming, streamingModeOff } from "src/app/state/actions";
import { AnalyticsService } from "./analytics.service";
import { ConfigurationProvider } from "./configuration.provider";
import { MatDialog } from "@angular/material/dialog";
import { MuseumPopupErrorComponent } from "src/app/features/configuration-area/museum-popup-error/museum-popup-error.component";
import { Api } from "./_api";

@Injectable({
  providedIn: "root",
})
export class MonkeyWayService {
  private streamingService: StreamingService | undefined;

  private streamInfo$: Observable<ClientStreamInfo> | undefined;
  private _streamInfo: ClientStreamInfo | null = null;
  private streamingControlService: StreamingControlService =
    new StreamingControlService();
  private _streamSubscription: Subscription | null = null;

  private _streamElement: ElementRef<HTMLVideoElement> | null | undefined;
  streamControlSubs$: Subject<boolean> = new Subject();
  streamingAvailable$: Subject<boolean> = new Subject();
  machineSerialNumberMuseum: any;

  constructor(
    private http: HttpClient,
    private store: Store<FerrariState>,
    private analytics: AnalyticsService,
    private cfg: ConfigurationProvider,
    private dialog: MatDialog,
    private api: Api
  ) {
    
  }

  get srcObject(): any {
    return this._streamInfo && this._streamInfo.stream;
  }

  setStreamElement(element: ElementRef<HTMLVideoElement>): void {
    console.log("setStreamElement", element);
    this._streamElement = element;

    if (this._streamSubscription) {
      console.log("restart");
      this.streamControlSubs$.next(true);
    }

    console.log("start");
  }

  subscribeToMwMovement(info: StreamInfo) {
    setTimeout(() => {
      if (!!info && !!this._streamElement) {
        const options = this.streamingControlService.createOptions(info);
        this.streamingControlService
          .connect(this._streamElement.nativeElement, options)
          .subscribe(
            async (connected) => {
              if (connected) {
                this._streamInfo = info;
                this.onConnected();
              } else {
                // close streaming connection if mouse/keyboard connection gets lost
                console.log("ERROR IN subscribeToMwMovement: connection lost");
                this.streamingAvailable$.next(false);
                this.onDisconnected();
                await this.stopSession();
              }
              //   this.onConnected();
            },
            async (err) => {
              console.error(`error connecting to renderer: ${err}`);
              this.analytics.error(`error connecting to renderer: ${err}`);
              this._streamInfo = info; // TODO
              setTimeout(() => {
                this.streamingAvailable$.next(false);
              }, 2000);
              this.onDisconnected();
              await this.stopSession();
            }
          );
      }
    }, 1000);
  }

  swipeCar(x: number, y: number) {
    this.streamingControlService.move(x, y, true);
  }

  destroyStreamElement(): void {
    this.streamingControlService.close();
    this._streamElement = null;
  }

  startSession(modelId: string): void {
    const monkeyWayConfig = this.cfg.carConfigList.find(
      (x: any) => x.modelId === modelId
    );
    if (!!monkeyWayConfig) {
      if (this.cfg.isMuseum) {
        this.startMuseumWithSerialNumber(monkeyWayConfig);
      } else {
        const streamingOptions: ServiceOptions = {
          baseUrl: monkeyWayConfig.baseUrl,
          appEnvId: monkeyWayConfig.appEnvId,
          apiKey: monkeyWayConfig.apiKey,
        };
        this.streamingService = new StreamingService(streamingOptions);
  
        // this.streamingService.stop();
        /*if (this._streamSubscription) {
                return;
            }*/
        // if (connectionKey == ''){
        //     connectionKey =  environment.monkeyWayConfig.apiKey;
        // }
        // const streamInfo$ = this.streamingService.startWithConnectionKey(connectionKey);
        let streamInfo$;
          streamInfo$ = this.streamingService.start(
            {
              immediate: false,
              waitForProvisioning: () => {
                console.log("waiting for renderer");
              },
            },
            { useAudio: true }
          );
          this._streamSubscription = streamInfo$.subscribe({
            next: (info) => {
              this._streamInfo = info;
              console.log(info);
              if (this.cfg.isMuseum) {
                this._streamInfo = info;
                this.onConnected();
              } else {
                this.subscribeToMwMovement(info);
              }
              this.store.dispatch(
                setSessionIdStreaming({ sessionIdStreaming: info.session.id })
              );
            },
            error: (e) => {
              const descriptionError =
                "Error on stream subscription - activating fallback on 2D configurator";
              console.warn(descriptionError);
              this.streamingAvailable$.next(false);
              this.store.dispatch(streamingModeOff());
              this.store.dispatch(
                setSessionIdStreaming({ sessionIdStreaming: undefined })
              );
              this.analytics.error(descriptionError);
              //TODO - Open toast here
              //(this.errorDialogService.open({ data: { message: e, action: 'redirect'}, }))
            },
          });
      }
    }
  }

  startMuseumWithSerialNumber(monkeyWayConfig: any) {
    this.api.getMachineSerialNumberMuseum().subscribe((objSerialNumber) => {
      if (!!objSerialNumber) {
        
        this.machineSerialNumberMuseum = objSerialNumber;
        console.log("SERIAL NUMBER ONCE WE LOAD A CAR ", this.machineSerialNumberMuseum);
        
        const streamingOptions: ServiceOptions = {
          baseUrl: monkeyWayConfig.baseUrl,
          appEnvId: monkeyWayConfig.appEnvId,
          apiKey: monkeyWayConfig.apiKey,
        };
        this.streamingService = new StreamingService(streamingOptions);

        let streamInfo$;
          
        streamInfo$ = this.streamingService.start(
          {
            immediate: false,
            waitForProvisioning: () => {
              console.log("waiting for renderer");
            },
            requiredSpecs: this.machineSerialNumberMuseum
          },
          { useAudio: true }
        );
        this._streamSubscription = streamInfo$.subscribe({
          next: (info) => {
            this._streamInfo = info;
            console.log(info);
            if (this.cfg.isMuseum) {
              this._streamInfo = info;
              this.onConnected();
            } else {
              this.subscribeToMwMovement(info);
            }
            this.store.dispatch(
              setSessionIdStreaming({ sessionIdStreaming: info.session.id })
            );
          },
          error: (e) => {
            const descriptionError =
              "Error on stream subscription - activating fallback on 2D configurator";
            console.warn(descriptionError);
            this.streamingAvailable$.next(false);
            this.store.dispatch(streamingModeOff());
            this.store.dispatch(
              setSessionIdStreaming({ sessionIdStreaming: undefined })
            );
            this.analytics.error(descriptionError);
            //TODO - Open toast here
            //(this.errorDialogService.open({ data: { message: e, action: 'redirect'}, }))
          },
        });
      }
    })
  }

  async stopSession() {
    if (!this._streamSubscription) {
      return;
    }

    this.onDisconnected();
    this._streamSubscription.unsubscribe();
    this._streamSubscription = null;
    this.store.dispatch(
      setSessionIdStreaming({ sessionIdStreaming: undefined })
    );
  }

  stopMonkeyWaySession() {
    if (!this._streamSubscription) {
      return;
    }
    console.log("disconnected");
    this.streamingService?.stop();
    this._streamSubscription.unsubscribe();
    this._streamSubscription = null;
    this.destroyStreamElement();
    this.streamingAvailable$.unsubscribe();
    // this.streamingAvailable$.complete();
    this.streamingAvailable$ = new Subject<boolean>();
  }

  stopPresenterScreenSession() {
    this.onDisconnected();
  }

  checkForAvailableSession(modelId: string): Observable<any> {
    const monkeyWayConfig = this.cfg.carConfigList.find(
      (x: any) => x.modelId === modelId
    );

    if (!monkeyWayConfig) {
      // TODO: need to adjust correct response
      return of(400);
    }
    const url = `${monkeyWayConfig.baseUrl}/api/v1/session/check`;
    const headers = new HttpHeaders()
      .set("Authorization", `ApiKey ${monkeyWayConfig.apiKey}`)
      .set("Content-Type", "application/json")
      .set("X-AppEnvId", monkeyWayConfig.appEnvId);

    return this.http
      .post(url, {}, { headers, observe: "response" })
      .pipe(map((response: HttpResponse<any>) => response.status));
  }

  private onConnected(): void {
    console.log("connected");
    this.streamingAvailable$.next(true);
    // this.streamingAvailable$.complete();
    this.streamControlSubs$.next(true);
  }

  private onDisconnected(): void {
    console.log("disconnected");
    if (this.streamControlSubs$) {
      this.streamControlSubs$.next(false);
    }
    this.streamingAvailable$.next(false);
    this.streamingService?.stop();
  }

  public getStreamInfo() {
    return this._streamInfo;
  }
}
