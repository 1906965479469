import { Option, OptionWithVariant } from './configuration-meta'

export class Preconfiguration {
  id: number = 0;
  configuration_id: string = '';
  name: string = '';
  configuration_code: string = '';
  model: string = '';
  modis_optional: string = '';
  packageType: string = 'default';
  options: OptionWithVariant[] = [];
  images_urls: ImagesUrls | undefined = undefined;

  constructor(data?: any) {
    if (data) {
      this.id = data.id ? data.id : 0;
      this.configuration_id = data.configuration_id ? data.configuration_id : '';
      this.name = data.configuration_name ? data.configuration_name : '';
      this.configuration_code = data.configuration_code ? data.configuration_code : '';
      this.model = data.model ? data.model : '';
      this.modis_optional = data.modis_optional ? data.modis_optional : '';
      this.packageType = data.packageType ? data.packageType : this.setPackageType();
      this.options = data.options ? data.options : this.createOptionsList();
      this.images_urls = data.images_urls ? new ImagesUrls(data.images_urls) : undefined;
    }
  }

  setPackageType(): string {
    if (this.modis_optional.includes('FIO1') || this.modis_optional.includes('FIO2')) {
      return 'fiorano';
    } else if(this.modis_optional.includes('EXCL')) {
      return 'design';
    } else {
      return 'default';
    }
  }

  createOptionsList(): OptionWithVariant[] {
    const optionsArray: OptionWithVariant[] = [];
    const options = this.modis_optional.split(';');
    options.forEach(opt => {
      const arrayOpt = opt.split('/');
      optionsArray.push({
        optionId: arrayOpt[0],
        variantId: arrayOpt.length === 2 ? arrayOpt[1] : null
      } as OptionWithVariant);
    });
    return optionsArray;
  }
}

// export class OptionValue {
//   optionId: string;
//   variantId: string;

//   constructor(o?: any) {
//     this.optionId = (!!o && !!o.optionId) ? o.optionId : null;
//     this.variantId = (!!o && !!o.variantId) ? o.variantId : null;
//   }
// }

export class ImagesUrls {
  image_url: string = '';
  image_url_wb: string = '';
  configuration_url: string = '';
  genuine_url: string = '';

  constructor(i?: ImagesUrls) {
    this.image_url = (!!i && i.image_url) ? i.image_url : this.image_url;
    this.image_url_wb = (!!i && i.image_url_wb) ? i.image_url_wb : this.image_url_wb;
    this.configuration_url = (!!i && i.configuration_url) ? i.configuration_url : this.configuration_url;
    this.genuine_url = (!!i && i.genuine_url) ? i.genuine_url : this.genuine_url;
  }
}
