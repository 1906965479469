import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { FerrariState } from "src/app/shared/application-interfaces";
import { Locale } from "src/app/core/models/Locale";
import { Language } from "src/app/core/models/Language";
import { ConfigurationProvider } from "src/app/core/services/configuration.provider";
import { LocalStorageService } from "src/app/core/services/local-storage.service";
import { UtilityService } from "src/app/core/services/utility.service";
import * as actions from "../../state/actions";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class LocaleResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private translate: TranslateService,
    private cfg: ConfigurationProvider,
    private util: UtilityService,
    private store: Store<FerrariState>,
    private localStorage: LocalStorageService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    console.log("[RESOLVER] LocaleResolver");

    const locale = route.paramMap.get("locale") || "en_EN";

    // if (!locale) {
    //   this.router.navigateByUrl("/404");
    // } else {
      const languageCode = locale.split("_")[0];
      const countryCode = locale.split("_")[1];

      if (!languageCode || !countryCode) {
        this.router.navigate([this.util.getCurrentLocale()]);
      } else {
        const language: Language =
          this.cfg.languages.find((p) => p.code === languageCode) ||
          this.util.getCurrentLanguage();
        this.translate.use(language.code);
        this.translate.reloadLang(language.code);
        this.localStorage.language = language;
        this.store.dispatch(actions.setLanguage({ language: language }));

        const country: Locale =
          this.cfg.locales.find(
            (p) => p.code === countryCode && p.lang === languageCode
          ) ||
          this.cfg.locales.find((p) => p.code === countryCode) ||
          this.util.getCurrentCountry();
        this.localStorage.locale = country;
        this.store.dispatch(actions.setLocale({ locale: country }));
      }
    // }

    return of("");
  }
}
