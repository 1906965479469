import { createAction, props } from "@ngrx/store";
import { Locale } from "../core/models/Locale";
import { Language } from "../core/models/Language";
import { Car } from "../core/models/Car";
import { Country } from "../core/models/Country";
import { Preconfiguration } from "../core/models/Preconfiguration";
import { ConfigurationLevel, VehicleConfigurationMeta, VehicleConfigurationMetaImpl } from '../core/models/configuration-meta';
import { VehicleConfig } from '../core/models/vehicle-config';
import { Dealer } from "../core/models/Dealer";
import { ImageRender, User } from "../shared/application-interfaces";
import { SaveResponse } from "../core/models/SaveResponse";
import { PreconfigurationMuseum } from "../core/models/PreconfigurationMuseum";

export const setError = createAction(
  "[Error] Set Error",
  props<{ error: any }>()
);

export const setUserCredentials = createAction(
  "SET_USER_CREDENTIALS",
  props<{ user: User }>()
);
export const removeUserCredentials = createAction(
  "REMOVE_USER_CREDENTIALS"
);
export const setLanguage = createAction(
  "SET_CURRENT_LANGUAGE",
  props<{ language: Language }>()
);
export const setLocale = createAction(
  "SET_CURRENT_LOCALE",
  props<{ locale: Locale }>()
);
export const isFirstTimeConfiguratorLoaded = createAction(
  "IS_FIRST_TIME_CONFIGURATOR_LOADED",
  props<{ isFirstTimeConfiguratorLoaded: boolean }>()
);
export const changeEnviroment = createAction(
  "CHANGE_ENVIROMENT",
  props<{ currentEnvironment: any }>()
);
export const setSelectedCar = createAction(
  "SELECT_CAR_MODEL",
  props<{ car: Car }>()
);
export const setCarPackage = createAction(
  "SELECT_CAR_PACKAGE",
  props<{ packageId: string | undefined }>()
);
export const setCountry = createAction(
  "SET_CURRENT_COUNTRY",
  props<{ country: Country }>()
);
export const setSelectedPreconfigurationCode = createAction(
  "SELECT_PRECONFIGURATION_CODE",
  props<{ preconfigurationCode: string }>()
);
export const setSelectedPreconfiguration = createAction(
  "SELECT_PRECONFIGURATION_CODE",
  props<{ preconfiguration: Preconfiguration }>()
);
export const setVehicleMeta = createAction(
  "SET_VEHICLE_CONFIG_META",
  props<{ vehicleConfigMeta: VehicleConfigurationMeta }>()
);
export const setSelectedSection = createAction(
  "SET_SELECTED_SECTION",
  props<{ section: string }>()
);
export const setDealers = createAction(
  "SET_DEALERS",
  props<{ dealers: Dealer[] }>()
);
export const setSelectedDealer = createAction(
  "SET_SELECTED_DEALER",
  props<{ selectedDealer: Dealer }>()
);
export const setSaveResponse = createAction(
  "SET_SAVE_RESPONSE",
  props<{ saveResponse: SaveResponse }>()
);
export const setFerrariId = createAction(
  "SET_FERRARI_ID",
  props<{ ferrariId: number }>()
);
export const setFerrariCode = createAction(
  "SET_FERRARI_CODE",
  props<{ ferrariCode: string }>()
);
export const setConfigurationIdSaved = createAction(
  "SET_CONFIGURATION_ID_SAVED",
  props<{ configurationIdSaved: string }>()
);
export const startLoading = createAction(
  "START_LOADING"
);
export const stopLoading = createAction(
  "STOP_LOADING"
);
export const startLoadingStreaming = createAction(
  "START_LOADING_STREAMING"
);
export const stopLoadingStreaming = createAction(
  "STOP_LOADING_STREAMING"
);
export const setLoading = createAction(
  "SET_LOADING",
  props<{ isLoading: boolean }>()
);
export const startLoadingSpinner = createAction(
  "START_LOADING_SPINNER"
);
export const stopLoadingSpinner = createAction(
  "STOP_LOADING_SPINNER"
);
export const setSelectedItem = createAction(
  "SET_SELECTED_ITEM",
  props<{ selectedItem: ConfigurationLevel | undefined }>()
);
export const updateConfigLevel = createAction(
  "UPDATE_CONFIG_LEVEL",
  props<{ configLevel: ConfigurationLevel[] | undefined }>()
);
export const toggleFullscreen = createAction(
  "TOGGLE_FULLSCREEN"
);
export const openFullscreen = createAction(
  "OPEN_FULLSCREEN"
);
export const closeFullscreen = createAction(
  "CLOSE_FULLSCREEN"
);
export const openTurntable = createAction(
  "OPEN_TURNTABLE"
);
export const closeTurntable = createAction(
  "CLOSE_TURNTABLE"
);
export const toggleStreamingMode = createAction(
  "TOGGLE_STREAMING_MODE"
);
export const streamingModeOn = createAction(
  "STREAMING_MODE_ON"
);
export const streamingModeOff = createAction(
  "STREAMING_MODE_OFF"
);
export const toggleDayNightView = createAction(
  "TOGGLE_DAY_NIGHT_VIEW"
);
export const setDayView = createAction(
  "SET_DAYVIEW"
);
export const setNightView = createAction(
  "SET_NIGHTVIEW"
);
export const showConfirmSave = createAction(
  "SHOW_CONFIRM_SAVE",
  props<{ showConfirmSave: boolean }>()
);
export const isDownloadingPdfImages = createAction(
  "IS_DOWNLOADING_PDF_IMAGES",
  props<{ isDownloadingPdfImages: boolean }>()
);
export const setRendersImages = createAction(
  "SET_RENDER_IMAGES",
  props<{ images: ImageRender }>()
);

export const startAnimation = createAction(
  "ANIMATION_START",
  props<{name: string}>()
);

export const finishAnimation = createAction(
  "ANIMATION_FINISH",
  props<{name: string}>()
);

export const startupDelay = createAction(
  "STARTUP_DELAY"
);

export const noStartupDelay = createAction(
  "NO_STARTUP_DELAY"
);

export const clearConfigurationGroup = createAction(
  '[Clear Group] Clear Group Configuration',
  props<{ propertyNames: string[] }>()
);
export const showSuperHeader = createAction(
  "SHOW_SUPER_HEADER"
);
export const hideSuperHeader = createAction(
  "HIDE_SUPER_HEADER"
)
export const setNavActiveIndex = createAction(
  'SET_NAV_ACTIVE_INDEX',
  props<{ activeIndex: number }>()
);
export const setEmptySpaceHeight = createAction(
  'SET_EMPTY_SPACE_HEIGHT',
  props<{ height: number }>()
);
export const setSelectedlevel = createAction(
  "SET_SELECTED_LEVEL",
  props<{ level: string }>()
);
export const disableRuleSkip = createAction(
  "DISABLE_RULE_SKIP"
);
export const enableRuleSkip = createAction(
  "ENABLE_RULE_SKIP"
);
export const setTurnTableLoadImage = createAction(
  "SET_TURNTABLE_LOAD_IMAGE",
  props<{ imgUrl: string[] }>()
);
export const startLoadingTurntable = createAction(
  "START_LOADING_TURNTABLE"
);
export const stopLoadingTurntable = createAction(
  "STOP_LOADING_TURNTABLE"
);
export const startRenderTime = createAction(
  "START_RENDER_TIME"
);
export const calculateDuration = createAction(
  "UPDATE_DURATION"
);
export const resetDuration = createAction(
  "RESET_DURATION"
);
export const setSelectedSectionIndex = createAction(
  "SET_SELECTED_SECTION_INDEX",
  props<{ index: number }>()
);
export const setInstanceStatus = createAction(
  "SET_INSTANCE_STATUS",
  props<{ hasStreamingInstance: boolean }>()
);
export const setInternalJourny = createAction(
  "SET_INTERNAL_JOURNY"
);
export const setSelectedSubLevel = createAction(
  "SET_SELECTED_SUB_LEVEL",
  props<{ subLevel: string }>()
);
export const setSessionIdStreaming = createAction(
  "SET_SESSION_ID_STREAMING",
  props<{ sessionIdStreaming: string | undefined }>()
);
export const setRenderImageStep = createAction(
  "SET_RENDER_IMAGE_STEP",
  props<{ renderImageStep: string }>()
);
export const toggleMuseumStandBy = createAction(
  "TOGGLE_MUSUEM_STAND_BY"
);
export const setMuseumStandBy = createAction(
  "SET_MUSUEM_STANDBY"
);
export const unSetMuseumStandBy = createAction(
  "UNSET_MUSUEM_STANDBY"
);

export const showMuseumPrintOverlay = createAction(
  "SHOW_MUSEUM_PRINT_OVERLAY"
);
export const hideMuseumPrintOverlay = createAction(
  "HIDE_MUSEUM_PRINT_OVERLAY"
);
export const setMuseum = createAction(
  "SET_MUSUEM"
);
export const setAvailableCarsList = createAction(
  "SET_AVAILABLE_CARS_LIST",
  props<{ availableCarsList: Car[] }>()
);
export const setPreconfigurationMuseum = createAction(
  "SET_MUSEUM_PRECONFIGURATION",
  props<{ preconfigurationMuseum: PreconfigurationMuseum }>()
);
export const startLoadingMuseumSelector = createAction(
  "START_LOADING_MUSEUM_SELECTOR"
);
export const stopLoadingMuseumSelector = createAction(
  "STOP_LOADING_MUSEUM_SELECTOR"
);