import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { take } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  private history: string[][] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.pushUniqueHistory(
          event.urlAfterRedirects.split("?")[0].split("/")
        );
      }
    });
  }

  back(): void {
    const lastIndex = this.history.length -1;
    if (lastIndex != -1){
      const lastHistory = this.history[lastIndex];
      if (lastHistory.length === 7){
        // it means we need to go one step back
        this.history[lastIndex].pop();
      } else {
        this.history.pop();
      }
      if (this.history.length > 0) {
        this.router.navigate(this.history[this.history.length - 1], {
          relativeTo: this.activatedRoute,
          queryParams: {
            configuration:
              this.activatedRoute.snapshot.queryParamMap.get("configuration"),
              isStreaming: this.activatedRoute.snapshot.queryParamMap.get("isStreaming") || false
          },
          replaceUrl: false,
        });
      } else {
        const url = this.router.url.split("/");
        if (url.length >= 6){
          const segments = [];
          for (let i = 0; i < 6; i++) {
            segments.push(url[i]);
          }
    
          this.router.navigate(segments, {
            relativeTo: this.activatedRoute,
            queryParams: {
              configuration:
                this.activatedRoute.snapshot.queryParamMap.get("configuration"),
                isStreaming: this.activatedRoute.snapshot.queryParamMap.get("isStreaming") || false
            },
            replaceUrl: false,
          });
        } else {
          // out of config area
          this.router.navigate(['..'], {
            relativeTo: this.activatedRoute,
            queryParams: {
              configuration:
                this.activatedRoute.snapshot.queryParamMap.get("configuration"),
                isStreaming: this.activatedRoute.snapshot.queryParamMap.get("isStreaming") || false
            },
            replaceUrl: false,
          });
        }
      }
    } else {
      // in case user refresh and no previous history found
      const newSegment = this.router.url.split('/');
      if (newSegment.length > 5){
        if (newSegment[newSegment.length-1].toLowerCase().startsWith("seattype") && newSegment.length === 8) {
          // need to substitute last item with the word list
          newSegment[7] = "list";
        } else {
          newSegment.pop();
        }
        this.router.navigate(newSegment, {
          relativeTo: this.activatedRoute,
          queryParams: {
            configuration:
              this.activatedRoute.snapshot.queryParamMap.get("configuration"),
              isStreaming: this.activatedRoute.snapshot.queryParamMap.get("isStreaming") || false
          },
          replaceUrl: false,
        });
      } else {
        this.location.back();
      }


    }
  }

  private pushUniqueHistory(item: string[]): void {
    if (!this.history.some((l) => JSON.stringify(l) === JSON.stringify(item))) {
      this.history.push(item);
    }
  }
}
