export const COMMAND_REQUEST_STATE = 'RequestState';
export const COMMAND_CURRENT_STATE_MACHINE_STATE = 'CurrentStateMachineState';
export const COMMAND_LOCALISATION = 'Localisation';
export const COMMAND_LANGUAGE = 'Language';
export const COMMAND_REQUEST_DAY_NIGHT_SWITCH = 'RequestDaytime';
export const COMMAND_LISTEN_DAY_NIGHT = 'Daytime';
export const COMMAND_REQUEST_CAMERA_SHOT = 'RequestCameraShot';
export const COMMAND_CAMERA_SHOT_TOUCH = 'CameraShotTouch';
export const COMMAND_LISTEN_LOCATION = 'Location';
export const COMMAND_LISTEN_CONFIGURATION = 'FerrariCurrentConfiguration';
export const COMMAND_LISTEN_CONFIGURATION_APPLIED = 'OnProductUpdateApplied';
export const COMMAND_APPLICATION_CONFIG = 'ApplicationConfig';
export const COMMAND_FERRARI_APPLICATION_CONFIG = 'FerrariAdditionalConfig';
export const COMMAND_ZOOM = 'Zoom';
export const COMMAND_REQUEST_EXPORT_PDF = 'RequestExportPdf';
export const COMMAND_REQUEST_START_DEMO_MODE = 'RequestCinematicMode';
export const COMMAND_REQUEST_END_DEMO_MODE = 'RequestCinematicEnd';
export const COMMAND_REQUEST_EXPORT_VIDEO = 'RequestExportVideo';
export const COMMAND_SUMMARY_VIEW_VISIBILITY = 'SummeryViewVisibility';
export const COMMAND_CURRENT_ENVIRONMENT = 'EnvironmentSelected';
export const COMMAND_LISTEN_VIDEO_CAPTURE_STATE = 'VideoCaptureState';
export const COMMAND_START_VIDEO_CAPTURE = 'StartVideoCapture';
export const COMMAND_STOP_VIDEO_CAPTURE = 'StopVideoCapture';
export const COMMAND_IMAGE_STREAM_ENABLED = 'ImageStreamEnabled';
export const COMMAND_UI_STATE = 'FerrariUIState';
export const COMMAND_FERRARI_USE_HTTP_STREAM = 'FerrariUseHttpStream';
export const COMMAND_REQUEST_START_DRIVING_EXPERIENCE = 'RequestDrivingExperience';
export const COMMAND_IS_LEVEL_ENVIRONMENT_LOADING = 'IsEnvironmentSetupRunning';
export const COMMAND_RESET_SCENE = 'ResetScene';
