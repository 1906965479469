import { Action, createReducer, on } from "@ngrx/store";
import { UIState } from "../../shared/application-interfaces";
import * as actions from "../actions";
import { initialState } from "../initialState";

export const uiReducer = createReducer(
  initialState.ui,
  on(actions.changeEnviroment, (state, payload) => {
    const currentEnvironment = payload.currentEnvironment;
    return {
      ...state,
      currentEnviroment: currentEnvironment,
    };
  }),
  on(actions.startLoading, (state) => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(actions.setLoading, (state, payload) => {
    return {
      ...state,
      isLoading: payload.isLoading,
    };
  }),
  on(actions.stopLoading, (state) => {
    return {
      ...state,
      isLoading: false,
    };
  }),
  on(actions.startLoadingSpinner, (state) => {
    return {
      ...state,
      isLoadingSpinner: true,
    };
  }),
  on(actions.stopLoadingSpinner, (state) => {
    return {
      ...state,
      isLoadingSpinner: false,
    };
  }),
  on(actions.isFirstTimeConfiguratorLoaded, (state, payload) => {
    return {
      ...state,
      isFirstTimeConfiguratorLoaded: payload.isFirstTimeConfiguratorLoaded,
    };
  }),
  on(actions.setSelectedItem, (state, payload) => {
    return {
      ...state,
      selectedItem: payload.selectedItem,
    };
  }),
  on(actions.toggleFullscreen, (state) => {
    return {
      ...state,
      isFullscreen: !state.isFullscreen,
    };
  }),
  on(actions.startupDelay, (state) => {
    return {
      ...state,
      startupDelay: true,
    };
  }),
  on(actions.noStartupDelay, (state) => {
    return {
      ...state,
      startupDelay: false,
    };
  }),
  on(actions.openFullscreen, (state) => {
    return {
      ...state,
      isFullscreen: true,
    };
  }),
  on(actions.closeFullscreen, (state) => {
    return {
      ...state,
      isFullscreen: false,
    };
  }),
  on(actions.openTurntable, (state) => {
    return {
      ...state,
      isTurntable: true,
    };
  }),
  on(actions.closeTurntable, (state) => {
    return {
      ...state,
      isTurntable: false,
    };
  }),
  on(actions.toggleStreamingMode, (state) => {
    return {
      ...state,
      isStreaming: !state.isStreaming,
    };
  }),
  on(actions.streamingModeOn, (state) => {
    return {
      ...state,
      isStreaming: true,
    };
  }),
  on(actions.streamingModeOff, (state) => {
    return {
      ...state,
      isStreaming: false,
    };
  }),
  on(actions.toggleDayNightView, (state) => {
    return {
      ...state,
      isDaytime: !state.isDaytime,
    };
  }),
  on(actions.setDayView, (state) => {
    return {
      ...state,
      isDaytime: true,
    };
  }),
  on(actions.setNightView, (state) => {
    return {
      ...state,
      isDaytime: false,
    };
  }),
  on(actions.showConfirmSave, (state, payload) => {
    return {
      ...state,
      showConfirmSave: payload.showConfirmSave,
    };
  }),
  on(actions.isDownloadingPdfImages, (state, payload) => {
    return {
      ...state,
      isDownloadingPdfImages: payload.isDownloadingPdfImages,
    };
  }),
  on(actions.setRendersImages, (state, payload) => {
    return {
      ...state,
      renders: payload.images,
    };
  }),
  on(actions.startAnimation, (state, { name }) => ({
    ...state,
    animations: {
      ...state.animations,
      [name]: false,
    },
  })),
  on(actions.finishAnimation, (state, { name }) => ({
    ...state,
    animations: {
      ...state.animations,
      [name]: true,
    },
  })),
  on(actions.clearConfigurationGroup, (state, payload) => {
    const propertyNames = payload.propertyNames;
    const newState: any = { ...state };
    propertyNames.forEach((prop) => delete newState[prop]);
    return newState as UIState;
  }),
  on(actions.showSuperHeader, (state) => {
    return {
      ...state,
      hideSuperHeader: false,
    };
  }),
  on(actions.hideSuperHeader, (state) => {
    return {
      ...state,
      hideSuperHeader: true,
    };
  }),
  on(actions.setNavActiveIndex, (state, payload) => {
    return {
      ...state,
      navigationActiveIndex: payload.activeIndex,
    };
  }),
  on(actions.setEmptySpaceHeight, (state, payload) => {
    return {
      ...state,
      emptySpaceHeight: payload.height,
    };
  }),
  on(actions.disableRuleSkip, (state) => {
    return {
      ...state,
      canSkipRulePopup: false,
    };
  }),
  on(actions.enableRuleSkip, (state) => {
    return {
      ...state,
      canSkipRulePopup: true,
    };
  }),
  on(actions.setTurnTableLoadImage, (state, payload) => {
    return {
      ...state,
      turnTableLoadingImage: payload.imgUrl,
    };
  }),
  on(actions.startLoadingTurntable, (state) => {
    return {
      ...state,
      isLoadingTurntable: true,
    };
  }),
  on(actions.stopLoadingTurntable, (state) => {
    return {
      ...state,
      isLoadingTurntable: false,
    };
  }),
  on(actions.startRenderTime, (state) => {
    return {
      ...state,
      renderStartTime: Date.now(),
    };
  }),
  on(actions.calculateDuration, (state) => {
    return {
      ...state,
      renderDuration: Date.now() - state.renderStartTime,
    };
  }),
  on(actions.resetDuration, (state) => {
    return {
      ...state,
      renderDuration: 0,
    };
  }),
  on(actions.startLoadingStreaming, (state) => {
    return {
      ...state,
      isLoadingStreaming: true,
    };
  }),
  on(actions.stopLoadingStreaming, (state) => {
    return {
      ...state,
      isLoadingStreaming: false,
    };
  }),
  on(actions.setSelectedSectionIndex, (state, payload) => {
    return {
      ...state,
      sectionIndex: payload.index,
    };
  }),
  on(actions.setInstanceStatus, (state, payload) => {
    return {
      ...state,
      hasStreamingInstance: payload.hasStreamingInstance,
    };
  }),
  on(actions.setInternalJourny, (state) => {
    return {
      ...state,
      isInternalJourny: true,
    };
  }),
  on(actions.setSessionIdStreaming, (state, payload) => {
    return {
      ...state,
      sessionIdStreaming: payload.sessionIdStreaming,
    };
  }),
  on(actions.setRenderImageStep, (state, payload) => {
    return {
      ...state,
      renderImageStep: payload.renderImageStep,
    };
  }),
  on(actions.toggleMuseumStandBy, (state) => {
    return {
      ...state,
      museumStandBy: !state.museumStandBy,
    };
  }),
  on(actions.setMuseumStandBy, (state) => {
    return {
      ...state,
      museumStandBy: true,
    };
  }),
  on(actions.unSetMuseumStandBy, (state) => {
    return {
      ...state,
      museumStandBy: false,
    };
  }),
  on(actions.showMuseumPrintOverlay, (state) => {
    return {
      ...state,
      showMuseumPrintcarOverlay: true,
    };
  }),
  on(actions.hideMuseumPrintOverlay, (state) => {
    return {
      ...state,
      showMuseumPrintcarOverlay: false,
    };
  }),
  on(actions.setMuseum, (state) => {
    return {
      ...state,
      isMuseum: true,
    };
  }),
  on(actions.startLoadingMuseumSelector, (state) => {
    return {
      ...state,
      isLoading: true,
      isLoadingMuseumSelector: true,
    };
  }),
  on(actions.stopLoadingMuseumSelector, (state) => {
    return {
      ...state,
      isLoading: false,
      isLoadingMuseumSelector: false,
    };
  }),
  
);

export function reducer(state: UIState | undefined, action: Action) {
  return uiReducer(state, action);
}
