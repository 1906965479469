import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../shared/_modules/material.module';
import { FerrariState } from 'src/app/shared/application-interfaces';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AnalyticsService } from '../../core/services/analytics.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { startLoading } from 'src/app/state/actions';


@Component({
  selector: 'post-login-landing',
  standalone: true,
  templateUrl: './post-login-landing.page.html',
  styleUrls: ['./post-login-landing.page.scss']
})
export class PostLoginLandingPage implements OnInit {

  constructor(
    private store: Store<FerrariState>,
    private router: Router,
    private route: ActivatedRoute,
    private sessionStorage: SessionStorageService,
    private activatedRoute: ActivatedRoute
  ) {


  }

  ngOnInit(): void {
    this.store.dispatch(startLoading());
    const authorizationCode = this.activatedRoute.snapshot.queryParamMap.get("code");
    this.sessionStorage.authorization_code = authorizationCode;
    const postLoginUrl = this.sessionStorage.post_login_url;
    this.sessionStorage.removeKeyFromSession("postLoginUrl");
    // this.analyzeResponseUrl(); // TODO
    this.sessionStorage.open_save_enquire_after_redirect = true;
    // this.router.navigateByUrl(decodeURIComponent(postLoginUrl));
    window.location.href = postLoginUrl;

  }

}
