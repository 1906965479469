import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  ConfigurationLevel,
  Option,
} from "../../../core/models/configuration-meta";
import { TranslateModule } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfigurationManagerService } from "../../../core/services/configuration-manager.service";
import { Observable, distinctUntilChanged, BehaviorSubject, combineLatest, debounceTime } from 'rxjs';
import { Car } from "src/app/core/models/Car";
import { Store } from "@ngrx/store";
import { FerrariState } from "../../../shared/application-interfaces";
import { filter, first, take } from "rxjs/operators";
import { setRenderImageStep, setSelectedItem, setSelectedSectionIndex } from "src/app/state/actions";
import { NavigationService } from '../../../core/services/navigation.service';
import { AnalyticsService } from "src/app/core/services/analytics.service";
import { ConfigurationProvider } from "src/app/core/services/configuration.provider";

@Component({
  selector: "app-list-item",
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: "./list-item.component.html",
  styleUrls: ["./list-item.component.scss"],
})
export class ListItemComponent implements OnInit {
  @Input() item?: ConfigurationLevel;
  public selectedItems: Option[] = [];
  public car$: Observable<Car>;
  public isMuseum: boolean;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private configManager: ConfigurationManagerService,
    private store: Store<FerrariState>,
    private analytics :AnalyticsService,
    cfg: ConfigurationProvider
  ) {
    this.isMuseum = cfg.isMuseum;
    this.car$ = store.select((s) => s.data.car);
  }

  ngOnInit(): void {
    const isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
      true
    );
    // combineLatest([
    //   this.store.select((p) => p.ui.isLoadingSpinner),
    //   this.store.select((p) => p.ui.isLoading)
    // ])
    //   .subscribe((s) => {
    //     isLoading.next(!s[0] && !s[1]);
    //   });

    // isLoading
    //   .pipe(
    //     filter((b) => b),
    //     first()
    //   )
    // const vehicleConfig = this.configManager.getVehicleInfoValue();
    // if (!!this.item && vehicleConfig) {
    //   const nodeopts = this.item.options.filter(
    //     (f) =>
    //       f.values?.some((s) => s.variantId !== "364000000")
    //   );
    //   const selectedOpts = vehicleConfig.getFullOptionList();
    //   const found = selectedOpts.filter((x) =>
    //     nodeopts.some((s) => s.id === x.id)
    //   );
    //   this.selectedItems = found;
    //   // console.log('selected item in item :', found);
    //   // nodeopts.find(opt => selectedOpts.some(selected => selected.optionId === opt.values))
    //   isLoading.complete();
    // }
    this.configManager.getVehicleConfig().pipe(filter(f => !!f))
      .subscribe(vehicleConfig => {
        if (!!this.item && vehicleConfig) {
          const nodeopts = this.item.options.filter(
            (f) =>
              f.values?.some((s) => s.variantId !== "364000000")
          );
          const selectedOpts = vehicleConfig.getFullOptionList();
          const found = selectedOpts.filter((x) =>
            nodeopts.some((s) => s.id === x.id)
          );
          this.selectedItems = found;
          // console.log('selected item in item :', found);
          // nodeopts.find(opt => selectedOpts.some(selected => selected.optionId === opt.values))
          isLoading.complete();
        }
      });
  }

  goToComponent() {
    if (!!this.item) {
      if (!!this.item.componentType) {
        // this.analytics.navigateToStep(this.item.id);
        // put selected item in store to scroll back to it on back button
        this.store.dispatch(setRenderImageStep({ renderImageStep: 'list_option'}));
        this.store.dispatch(setSelectedItem({selectedItem: this.item}));
        this.analytics.openOption(this.item.parent?.id || 'no_value', this.item.id);
        this.requestRenderImage();
        if (this.item.children && (this.item.optspec.length === 0 || this.item.children.length === 0)) {
          let firstLevel = "",
            secondLevel,
            thirdLevel = undefined;

          // if (!!this.item.parent) {
          const pathToRoot = this.item.getPathToRoot();
          firstLevel = pathToRoot[0];
          // calculate index of section
          const meta = this.configManager.getVehicleMetaValue()
          if (!!meta){
            if (!!meta.configuration){
              const sectionIndex = meta.configuration.configHierarchy.findIndex(x => x.id === firstLevel);
              this.store.dispatch(setSelectedSectionIndex({index: sectionIndex}));
            }
          }
          // }
          if (this.item.children && this.item.children.length === 0) {
            // it means we are in last level so use parent as level & current item as third level
            secondLevel = pathToRoot[1];
            thirdLevel = pathToRoot[2];
          } else {
            secondLevel = this.item.id;
            thirdLevel = this.item.children[0].id;
          }
          this.navigateToStep(
            firstLevel,
            secondLevel,
            this.item.componentType,
            thirdLevel
          );
        } else if (this.item.children && this.item.optspec.length > 0 && this.item.children.length > 0) {
          let firstLevel = "",
            secondLevel,
            thirdLevel = undefined;

          // if (!!this.item.parent) {
          const pathToRoot = this.item.getPathToRoot();
          firstLevel = pathToRoot[0];
          // }
          if (this.item.children && this.item.children.length > 0) {
            // it means we are in complex level that should show multiple components
            secondLevel = pathToRoot[1];
          } else {
            secondLevel = this.item.id;
            // thirdLevel = this.item.children[0].id;
          }

          this.navigateToStep(
            firstLevel,
            secondLevel,
            this.item.componentType,
            thirdLevel
          );
        }
      }
    }
  }

  private requestRenderImage() {
    if (!!this.item){
      const start = performance.now();
      const cameraShots: string[] = this.item.cameraShot || [];
      const trunk = this.route.snapshot.queryParamMap.get("configuration") || "";
      this.store.select(p => p.ui.isStreaming).pipe(take(1)).subscribe({next: isStreaming => {
        if (!isStreaming){
          this.configManager.renderImage(cameraShots, [], trunk).subscribe();
        } else {
          this.configManager.changeBeautyShot(cameraShots[0]);
        }
      }})
    }
  }

  navigateToStep(
    firstlevel: string,
    secondLevel: string,
    componentType: string,
    thirdLevel?: string
  ) {
    let url = [firstlevel, secondLevel];
    if (componentType === "seats") {
      // go to another list of components
      url.push("list");
      // TODO: MOBILE should go directly to first item in list
    } else {
      // go directly to config level
      // url.push(thirdLevel);
      if (!!thirdLevel) {
        url.push(thirdLevel);
      }
    }

    const segments = this.router.url.split("/");
    let index = 0;
    const newSegments = [];
    while (index < 5) {
      // create base url
      newSegments.push(segments[index]);
      index++;
    }

    // add created url to it
    newSegments.push(...url);

    // this.navService.addToHistory();

    this.router.navigate(newSegments, {
      relativeTo: this.route,
      queryParams: {
        configuration: this.route.snapshot.queryParamMap.get("configuration"),
        isStreaming: this.route.snapshot.queryParamMap.get("isStreaming") === "true"
      },
      replaceUrl: false
    });
  }
}
