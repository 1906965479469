// tslint:disable: max-line-length
export const ENDPOINT_IS_READY = 'com.mackevision.engine.is_ready';
export const ENDPOINT_GET_APPLICATION_STATE = 'com.mackevision.engine.get_application_state';
export const ENDPOINT_GET_VEHICLE_XML = 'com.mackevision.stateline.get_vehicle_xml';
export const ENDPOINT_GET_OPTION_STRUCTURE_XML = 'com.mackevision.stateline.get_option_structure_xml';
export const ENDPOINT_SET_CURRENT_CONFIGURATION = 'com.mackevision.engine.set_current_configuration';
export const ENDPOINT_STATELINE_SEARCH_CUSTOMERS = 'com.mackevision.stateline.get_customers';
export const ENDPOINT_GET_BEAUTYSHOTS = 'com.mackevision.engine.get_camerashots';
export const ENDPOINT_GET_ANIMATION_GROUPS = 'com.mackevision.engine.get_animationgroups';
export const ENDPOINT_SAVE_CONFIGURATION = 'com.mackevision.engine.save_configuration';
export const ENDPOINT_GET_CONFIGURATIONS = 'com.mackevision.engine.get_saved_configurations';
export const ENDPOINT_GET_SUGGESTED_CONFIGURATIONS = 'com.mackevision.stateline.get_suggested_configurations';
export const ENDPOINT_LOAD_CONFIGURATION = 'com.mackevision.engine.load_configuration';
export const ENDPOINT_DELETE_CONFIGURATION = 'com.mackevision.engine.delete_configuration';
export const ENDPOINT_GET_OPTION_TYPE_MAPPING = 'com.mackevision.stateline.get_option_types_mapping';
export const ENDPOINT_CREATE_CONFIGURATION_THUMBNAIL = 'com.mackevision.engine.create_configuration_thumbnail';
export const ENDPOINT_GET_CONFIGURATION = 'com.mackevision.engine.get_local_configuration';
export const ENDPOINT_GET_DEFAULT_CONFIGURATION = 'com.mackevision.stateline.get_default_configuration';
export const ENDPOINT_SAVE_PERSISTENT_DATA = 'com.mackevision.engine.save_data';
export const ENDPOINT_LOAD_PERSISTENT_DATA = 'com.mackevision.engine.load_data';
export const ENDPOINT_SET_COBA_DEFAULT_CONFIGURATION = 'com.mackevision.engine.set_coba_default_configuration';
export const ENDPOINT_SAVESTATE_CREATE_OR_SAVE_SAVESTATE = 'com.mackevision.engine.create_savestate';
export const ENDPOINT_SAVESTATE_DELETE_SAVESTATE = 'com.mackevision.engine.delete_savestate';
export const ENDPOINT_SAVESTATE_GET_CONFIGURATION_SAVESTATES = 'com.mackevision.engine.get_savestates';
export const ENDPOINT_SAVESTATE_IS_RENDERED = 'com.mackevision.engine.is_savestate_rendered';
export const ENDPOINT_SAVESTATE_CREATE_IMAGES = 'com.mackevision.engine.create_savestate_images';
// FIXME: namespace will be adjusted in the backend
export const ENDPOINT_ENGINE_GET_PRICELIST = 'com.mackevision.engine.get_pricelist';
export const ENDPOINT_ENGINE_GET_ONLINE_CONFIGURATION_BY_ID = 'com.mackevision.engine.load_myferrari_configuration_by_id';
export const ENDPOINT_ENGINE_GET_ONLINE_CONFIGURATION_BY_CODE = 'com.mackevision.engine.load_myferrari_configuration_by_code';
export const ENDPOINT_ENGINE_GET_ORIGINAL_ONLINE_CONFIGURATION_BY_CODE = 'com.mackevision.engine.load_original_myferrari_configuration_by_code';
export const ENDPOINT_ENGINE_SAVE_ONLINE_CONFIGURATION = 'com.mackevision.engine.save_myferrari_configuration';
export const ENDPOINT_ENGINE_GET_ONLINE_CONFIGURATIONS_FOR_CURRENT_DEALER = 'com.mackevision.engine.load_myferrari_configurations_for_dealercode';
export const ENDPOINT_ENGINE_LOAD_ONLINE_CONFIGURATION_INTO_APPLICATION = 'com.mackevision.engine.load_online_configuration';
export const ENDPOINT_ENGINE_SET_INITIAL_CONFIGURATION = 'com.mackevision.engine.set_initial_configuration';
export const ENDPOINT_ENGINE_GET_INITIAL_CONFIGURATION = 'com.mackevision.engine.get_initial_configuration';
export const ENDPOINT_ENGINE_SEND_PREORDER_REQUEST = 'com.mackevision.engine.preorder';
export const ENDPOINT_ENGINE_SUBMIT_ANALYTICS_EVENT = 'com.mackevision.engine.submit_analytics_event';
export const ENDPOINT_ENGINE_EXECUTE_ONLINE_CUSTOMER_SEARCH = 'com.mackevision.engine.call_search_endpoint';
export const ENDPOINT_ENGINE_SSO_LOGIN = 'com.mackevision.engine.sso_login';
export const ENDPOINT_ENGINE_SSO_LOGOUT = 'com.mackevision.engine.sso_logout';
export const ENDPOINT_ENGINE_GET_RSA_PUBLIC_KEY = 'com.mackevision.engine.get_public_rsa_key';
export const ENDPOINT_ENGINE_GET_DEALER_DETAILS_BY_ID = 'com.mackevision.engine.get_dealer_details_for';

// tslint:enable: max-line-length

export const ENDPOINT_UPSELLING_GET_SUGGESTIONS = 'com.mackevision.stateline.resolve_all_suggestions_from_files';

// ------------- Multimedia -------------
export const ENDPOINT_MULTIMEDIA_POST_REQUEST_OPTS  = 'com.mackevision.engine.request_opts_media';
export const ENDPOINT_MULTIMEDIA_POST_ENABLE_GALLERY_MIRROR_MODE = 'com.mackevision.engine.enable_gallery_mirror_mode';
export const ENDPOINT_MULTIMEDIA_POST_DISABLE_GALLERY_MIRROR_MODE = 'com.mackevision.engine.disable_gallery_mirror_mode';
export const ENDPOINT_MULTIMEDIA_POST_GALLERY_MEDIA_SELECTED = 'com.mackevision.engine.gallery_media_selected';
export const ENDPOINT_MULTIMEDIA_POST_TRANSFORM_IMAGE = 'com.mackevision.engine.transform_image';
export const ENDPOINT_MULTIMEDIA_POST_VIDEO_COMMAND = 'com.mackevision.engine.video_command';
export const ENDPOINT_MULTIMEDIA_TOPIC_VIDEO_TIME_UPDATE = 'com.mackevision.engine.video_time_update';
export const ENDPOINT_MULTIMEDIA_TOPIC_ON_VIDEO_LOADED = 'com.mackevision.engine.on_video_loaded';
export const ENDPOINT_MULTIMEDIA_POST_GET_OPTS_WITH_MEDIA = 'com.mackevision.engine.get_opts_with_media';
export const ENDPOINT_MULTIMEDIA_POST_GET_GALLERY_STATUS = 'com.mackevision.engine.get_gallery_status';
export const ENDPOINT_MULTIMEDIA_TOPIC_ON_MEDIA_EVENT = 'com.mackevision.engine.on_media_event';
// ---------------------------------------

// ---------------- Museum ----------------
export const ENDPOINT_MUSEUM_CHANGE_MODEL = 'com.mackevision.engine.change_model';
// ----------------------------------------