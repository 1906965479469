import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { FerrariState } from "src/app/shared/application-interfaces";
import { clearConfigurationGroup } from "src/app/state/actions";

@Injectable({
  providedIn: "root",
})
export class ClearConfigurationStoreGuard implements CanActivate {
  constructor(private store: Store<FerrariState>, private router: Router) {}

  canActivate(): boolean {
    // Dispatch the action to clear the store
    this.store.dispatch(
      clearConfigurationGroup({
        propertyNames: [
          "renders",
          "isDaytime",
          "isFullscreen",
          "selectedItem",
          "currentEnvironment",
          "section",
          "saveResponse",
          "startupDelay"
        ],
      })
    );

    // Return true to allow navigation to the next route
    return true;
  }
}
