<div class="container">
  <div class="header-logo-container">
    <div class="spacer"></div>
    <div class="spacer logo">
      <img src="/rt-assets/images/Cavallino_White.png" alt="Ferrari Logo" />
    </div>
    <div class="spacer print">
      <!-- <div class="ferrari-print"></div> -->
    </div>
  </div>
  <div class="car-image-container">
    <div class="image-container">
      <img class="image" [src]="carImageUrl | async" alt="Your car" />
      <!-- Logo overlay -->
      <!-- <img class="overlay-logo" [src]="carLogoUrl | async" alt="Car Logo" /> -->
      <!-- Info text overlay -->
      <!-- <div class="overlay-text">
        <img class="overlay-ferrari-logo-container" src="/rt-assets/images/logo_museo.png" alt="Museum logo" /> -->
        <!-- <div class="overlay-ferrari-logo-container">
          <img
            class="overlay-ferrari-logo"
            src="/rt-assets/images/Cavallino_White.png"
            alt="Ferrari Logo"
          />
        </div>
        <div class="info-text">
          <span>MUSEO</span>
          <span>ENZO FERRARI</span>
          <span class="city">MODENA</span>
        </div> -->
      <!-- </div> -->
    </div>
    <!-- <div class="sub-title">
      <span>{{ 'RT.MUSEUM.IMAGE.DESCRIPTION' | translate }}</span>
    </div> -->
  </div>
  <div class="tag-us">
    <span>{{ "RT.MUSEUM.TAG.US" | translate }}</span>
  </div>
  <div class="button-container first-button">
    <div class="button share-button-red" (click)="webshare()">
      <div><span>{{"RT.MUSEUM.BUTTON.SHARE" | translate}}</span></div>
      <div class="ferrari-share-2"></div>
    </div>
  </div>
  <!-- <div class="button-container first-button">
    <div class="button share-button-red" (click)="share()">
      <div><span>{{"RT.MUSEUM.BUTTON.SHARE" | translate}}</span></div>
      <div class="ferrari-share-2"></div>
    </div>
  </div> -->
  <!--  <div class="share-container">
    <div class="share-text">
      <span>{{"RT.MUSEUM.SHARE" | translate}}</span>
    </div>
    <div class="share-icons">
      <div class="ferrari-share" *ngIf="webShareSupported" (click)="share()"></div>
      <div class="ferrari-facebook-2" (click)="shareOnFacebook()"></div>
      <div class="ferrari-x" (click)="shareOnTwitter()"></div>
      <div class="ferrari-instagram" (click)="shareOnInstagram()"></div>
      <div class="ferrari-linkedin-2" (click)="shareOnLinkedIn()"></div>
      <div class="ferrari-share-2" (click)="share()"></div>
      <div class="ferrari-whatsapp"></div>
      <div class="ferrari-tiktak"></div>
    </div>
  </div> -->
  <div class="line-container">
    <div class="line line-margin-right"></div>
    <div class="or-text">
      <span>{{"RT.MUSEUM.OR" | translate}}</span>
    </div>
    <div class="line line-margin-left"></div>
  </div>
  <div class="button-container">
    <div class="button save-btn" (click)="saveImage()">
      <div><span>{{"RT.MUSEUM.SAVEIMAGE" | translate}}</span></div>
      <div class="ferrari-download-white"></div>
    </div>
    <div class="button download-pdf" (click)="downloadPDF()">
      <div>
        <span *ngIf="!downloadingPDF">{{"RT.MUSEUM.DOWNLOADPDF" | translate}}</span>
        <span *ngIf="downloadingPDF">{{"RT.MUSEUM.ISDOWNLOADING" | translate}}</span>
      </div>
      <div *ngIf="!downloadingPDF" class="ferrari-download-white"></div>
      <div *ngIf="downloadingPDF" class="loading-indicator-spinner-museum loading-spinner"></div>
    </div>
  </div>
  <div class="line-separator"></div>
  <div class="configuration-info-container">
    <div class="ferrari-code">
      <span>{{ferrariCode}}</span>
    </div>
    <div class="info">
      <span>{{"RT.MUSEUM.CONFIGINFO" | translate}}</span>
    </div>
  </div>
  <div class="button-container button-open-config" (click)="goToConfigurator()">
    <div class="button open-text">
      <span>{{"RT.MUSEUM.OPENCONFIG" | translate}}</span>
      <div class="ferrari-freccia-destra"></div>
    </div>
  </div>
</div>
