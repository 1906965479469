import {
  CommonModule,
  isPlatformBrowser,
  isPlatformServer,
} from "@angular/common";
import {
  Component,
  Inject,
  OnInit,
  Optional,
  PLATFORM_ID,
} from "@angular/core";
import { NavigationEnd, NavigationStart, Router, RouterModule } from "@angular/router";
import { Store } from "@ngrx/store";
import { REQUEST, RESPONSE } from "@nguniversal/express-engine/tokens";
import { filter, first } from "rxjs/operators";
import { ErrorState, FerrariState } from "./shared/application-interfaces";
import { ConfigurationProvider } from "./core/services/configuration.provider";
import { UtilityService } from "./core/services/utility.service";
import * as actions from "./state/actions";
import { TranslateService, TranslateStore } from "@ngx-translate/core";
import { Event as NavigationEvent } from "@angular/router";
import { Observable, of } from "rxjs";
import { AnalyticsService } from "./core/services/analytics.service";
import { Title } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { LocalStorageService } from "./core/services/local-storage.service";

@Component({
  selector: "app-root",
  template: `
    <router-outlet></router-outlet>
    <div
      class="loading-indicator"
      [ngClass]="(isLoading$ | async) ? 'loading' : ''">
        <span class="spinner-label-museum-selector" [ngClass]="(isLoadingMuseumSelector$ | async) ? 'isSelectorLoading' : ''">LOADING</span>
    </div>
  `,
  imports: [RouterModule, CommonModule],
  providers: [TranslateService, TranslateStore],
  standalone: true,
})
export class AppComponent implements OnInit {
  private currentUrl = '';
  public isLoading$: Observable<boolean> = of(false);
  public isLoadingMuseumSelector$: Observable<boolean> = of(false);
  title = "ferrari-rt-webcc-frontend";
  data: any;

  constructor(
    private store: Store<FerrariState>,
    private analytics: AnalyticsService,
    private titleMeta: Title,
    private cfg: ConfigurationProvider,
    translate: TranslateService,
    private router: Router,
    util: UtilityService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(REQUEST) protected request: any,
    @Optional() @Inject(RESPONSE) protected response: any,
    private ls: LocalStorageService
  ) {
    router.events
      .pipe(
        // The "events" stream contains all the navigation events. For this demo,
        // though, we only care about the NavigationStart event as it contains
        // information about what initiated the navigation sequence.
        filter((event: NavigationEvent) => {
          return event instanceof NavigationStart;
        })
      )
      .subscribe((event: any) => {
        console.group("NavigationStart Event");
        // Every navigation sequence is given a unique ID. Even "popstate"
        // navigations are really just "roll forward" navigations that get
        // a new, unique ID.
        console.log("navigation id:", event.id);
        console.log("route:", event.url);
        // The "navigationTrigger" will be one of:
        // --
        // - imperative (ie, user clicked a link).
        // - popstate (ie, browser controlled change such as Back button).
        // - hashchange
        // --
        // NOTE: I am not sure what triggers the "hashchange" type.
        console.log("trigger:", event.navigationTrigger);

        // This "restoredState" property is defined when the navigation
        // event is triggered by a "popstate" event (ex, back / forward
        // buttons). It will contain the ID of the earlier navigation event
        // to which the browser is returning.
        // --
        // CAUTION: This ID may not be part of the current page rendering.
        // This value is pulled out of the browser; and, may exist across
        // page refreshes.
        if (event.restoredState) {
          console.warn(
            "restoring navigation id:",
            event.restoredState.navigationId
          );
        }

        console.groupEnd();
        this.currentUrl = window.location.href;
      });

    router.events.pipe(
      filter((event: NavigationEvent) => {
        return event instanceof NavigationEnd;
      })
    )
    .subscribe((event: any) => {
      setTimeout(() => {
        this.analytics.pageChanged(this.currentUrl, window.location.origin + event.urlAfterRedirects, this.titleMeta.getTitle());
      }, 1000);
    });

    this.isLoading$ = store.select((p) => p.ui.isLoading);
    this.isLoadingMuseumSelector$ = store.select((p) => p.ui.isLoadingMuseumSelector);
    translate.langs = cfg.languages.map((x) => x.code);
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang("en");
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use(util.getCurrentLanguage().code);
    document.documentElement.setAttribute('lang', util.getCurrentLanguage().code);

    // Redirect to correct locale if none is set in url
    if (isPlatformServer(this.platformId)) {
      router.events
        .pipe(
          filter((p) => p instanceof NavigationStart),
          first()
        )
        .subscribe((e: any) => {
          if (!!this.request) {
            const url = this.request.path;
            console.log(`@@@@ SERVER ROUTER EVENT URL: ${url}`);
            if (url === "/" || url.startsWith("/?") || url.startsWith("/#")) {
              const locale = util.getCurrentLocale();
              console.log(`Empty route! Redirecting to: ${locale}`);
              router.navigate([locale]);
            }
          }
        });
    }

    if (isPlatformBrowser(this.platformId)) {
      router.events.subscribe((e: any) => {
        const url = e.url;
        if (
          e instanceof NavigationStart &&
          (url === "/" || url.startsWith("/?") || url.startsWith("/#"))
        ) {
          const locale = util.getCurrentLocale();
          console.log(`Empty route! Redirecting to: ${locale}`);
          router.navigate([locale]);
        }
      });
    }
  }

  ngOnInit() {
    this.store.dispatch(
      actions.setError({ error: { message: "this is test error" } })
    );

    this.isLoading$.subscribe(isLoading => {
      if (isLoading) {
        document.body.classList.add("disable-scroll-on-loading");
      } else {
        document.body.classList.remove("disable-scroll-on-loading");
      }
    });
    if (this.cfg.isMuseum && !environment.production && (this.ls.isMuseum === undefined)){
      // just for local development test
      this.ls.isMuseum = true;
      // this.store.dispatch(actions.toggleMuseumStandBy());
      this.router.navigateByUrl("/en_EN/ferrari_car_configurator/purosangue/default/museum?configuration=40248d7d105aff867f1c42e41107317593ae7ecf353c0b5a5ee62b5b737f6e1a&isStreaming=true");
    }

    // this.http.get('https://api.example.cm/data')
    // .pipe(catchError(err => throwError(() => new Error(err))))
    // .subscribe();
  }

}
