<div class="list-container" (touchmove)="onTouchMove($event)">
  <div class="title-container">
    <div class="back">
      <rt-back-button [arrow]="'back'"></rt-back-button>
    </div>
    <div class="title">
      <span>{{ item ? (item.name | translate) : ("" | translate) }}</span>
    </div>
    <div class="empty"></div>
  </div>
  <div 
    class="list-items"
    [ngStyle]="{'height': isMobile ? ('calc(' + innerHeight +' - 368px)') : null}"
  >
    <ng-container *ngFor="let child of listItems; let index = index">
      <div class="item-container">
        <app-list-item [item]="child"></app-list-item>
      </div>
    </ng-container>
  </div>
</div>
