import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { FerrariState } from 'src/app/shared/application-interfaces';
import { environment } from 'src/environments/environment';
import { dataReducer } from './data.reducer';
import { uiReducer } from './ui.reducer';
import { errorReducer } from './error.reducer';

export const reducers: ActionReducerMap<FerrariState> = {
    data: dataReducer,
    ui: uiReducer,
    error: errorReducer
};

export const metaReducers: MetaReducer<FerrariState>[] = !environment.production ? [] : [];
