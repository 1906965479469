export class Country {
  id: string;
  marketIds: string[];
  name: string;
  code: string;
  lang: string;
  marketIdentifier: number;

  constructor(data: any) {
    this.id = data.id;
    this.code = data.code;
    this.lang = data.lang;
    this.marketIds = data.marketIds;
    this.name = data.name;
    this.marketIdentifier = data.marketIdentifier;
  }
}
