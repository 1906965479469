import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class LinkPrefetchService {
  constructor(private http: HttpClient) {}

  prefetchData(): void {
    if (environment.includeExternalJavascript) {
      this.addPrefetchLink(
        "https://static.apps.ferrarinetwork.ferrari.com/autoloader/v1/static/js/ferrari-autoloader.js"
      );
      this.addScript(
        "https://static.apps.ferrarinetwork.ferrari.com/autoloader/v1/static/js/ferrari-autoloader.js"
      );
      this.addScriptWithContent(`
        // JavaScript content here
        console.log('Hello from dynamically included script!');
        __FerrariAutoloaderManagerCallback = function () {
            var superheaderElement = document.getElementById("superheader");
            var superfooterElement = document.getElementById("superfooter");
    
            var autoloader = new FerrariAutoloaderManager(
              {
                superheader: superheaderElement,
                superfooter: superfooterElement,
              },
              {
                superheader: {
                  zIndex: 2000,
                },
                superfooter: {
                  zIndex: 2000,
                },
              }
            );
            autoloader.mount();
          };
      `);
    }
  }

  private addPrefetchLink(url: string): void {
    const link = document.createElement("link");
    link.setAttribute("rel", "prefetch");
    link.setAttribute("href", url);
    document.head.appendChild(link);
  }

  private addScriptWithContent(scriptContent: string): void {
    const script = document.createElement("script");
    script.innerHTML = scriptContent;
    document.body.appendChild(script);
  }

  private addScript(url: string): void {
    const script = document.createElement("script");
    script.setAttribute("src", url);
    document.body.appendChild(script);
  }
}
