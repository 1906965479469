import { Injectable } from "@angular/core";
import { Locale } from "../models/Locale";
import { Language } from "../models/Language";
import { Preconfiguration } from "../models/Preconfiguration";

@Injectable({
  providedIn: "root",
})
export class SessionStorageService {
  constructor() {}

  public get dealer_code(): string {
    return this.get("dealerCodeFromURL");
  }
  public set dealer_code(value: string) {
    this.set("dealerCodeFromURL", value);
  }
  public get selected_preconfiguration(): Preconfiguration {
    return this.get('selectedPreconfiguration');
  }
  public set selected_preconfiguration(value: Preconfiguration) {
    this.set('selectedPreconfiguration', value);
  }

  public get post_login_url() {
    return this.get('postLoginUrl');
  }
  public set post_login_url(value: string) {
    this.set('postLoginUrl', value);
  }

  public get authorization_code() {
    return this.get('authorizationCode');
  }
  public set authorization_code(value: string | null) {
    this.set('authorizationCode', value );
  }

  public get refresh_token() {
    return this.get('refreshToken');
  }
  public set refresh_token(value: string | null) {
    this.set('refreshToken', value );
  }

  public get open_save_enquire_after_redirect() {
    return this.get('openSaveEnquireAfterRedirect');
  }
  public set open_save_enquire_after_redirect(value: boolean | null) {
    this.set('openSaveEnquireAfterRedirect', value );
  }

  public get previousSelectedCarMuseum (): any {
    return this.get('previousSelectedCarMuseum');
  }
  public set previousSelectedCarMuseum (value: any) {
    this.set('previousSelectedCarMuseum', value);
  }

  public removeKeyFromSession(key: string) {
    if (
      typeof window === "undefined" ||
      typeof window.sessionStorage === "undefined"
    ) {
      return;
    }
    try {
      window.sessionStorage.removeItem(key);
      return;
    } catch (e) {
      return;
    }
  }

  private get(key: string): any {
    if (
      typeof window === "undefined" ||
      typeof window.sessionStorage === "undefined"
    ) {
      return;
    }
    try {
      return JSON.parse(window.sessionStorage.getItem(key) || "");
    } catch (e) {
      return;
    }
  }

  private set(key: string, value: any): any {
    if (
      typeof window === "undefined" ||
      typeof window.sessionStorage === "undefined"
    ) {
      return;
    }
    try {
      window.sessionStorage.setItem(key, JSON.stringify(value));
      return;
    } catch (e) {
      return;
    }
  }
}
