import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { tap, catchError, map, switchMap } from 'rxjs/operators';
import { Api } from 'src/app/core/services/_api';
import { UtilityService } from 'src/app/core/services/utility.service';
import { Car } from 'src/app/core/models/Car';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from 'src/app/core/services/configuration.provider';


@Injectable({
  providedIn: 'root'
})
export class ActiveCarGuard implements CanActivate {

  constructor(
    private router: Router,
    private util: UtilityService,
    private http: HttpClient,
    private cfg: ConfigurationProvider) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      console.log("[GUARD] ActiveCarGuard");
      var carId = next.paramMap.get("carModelId");
      console.log(carId);

      if (!carId) {
        this.router.navigateByUrl("/404");
      }

      let modelsUrl = '';
      if (this.cfg.isChina) {
        modelsUrl = `/rt-assets/data/china-models.json`;
      } else if (this.cfg.isMuseum) {
        modelsUrl = `/rt-assets/data/museum-models.json`;
      } else {
        modelsUrl = `/rt-assets/data/models.json`;
      }

      return this.http
      .get<any[]>(modelsUrl)
      .pipe(
        map((cars) => {
          const carsList = cars.filter((f) => f.active).map((car) => new Car(car));
          const selectedCar = carsList.find((c) => c.id == carId);
          if (!selectedCar || !selectedCar.active) {
            this.router.navigateByUrl("/404");
          }
          return selectedCar ? selectedCar.active : false;
        })
      );

      // const car = this.util.getCarModel(carId);
      // return car.pipe(map((c) => {
      //   if (!c || !c.active){
      //     this.router.navigateByUrl("/404");
      //   }
      //   return c ? c.active : false;
      // }));
  }
}
