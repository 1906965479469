import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import {  catchError, flatMap, map, Observable, of, switchMap, take } from "rxjs";
import {
  FerrariState,
  UserAction,
} from "../../../shared/application-interfaces";
import { ConfigurationProvider } from "../configuration.provider";
import { OptionWithVariant } from "../../models/configuration-meta";
import { OptionSelection } from "../../models/vehicle-config";
import { RT_SAVE_LOCATION } from "src/app/shared/application-constants";
import { saveAs } from "file-saver";

@Injectable({
  providedIn: "root",
})
export class Api {
  constructor(
    private http: HttpClient,
    private cfg: ConfigurationProvider,
    private store: Store<FerrariState>
  ) {}
  render(
    carModelId: string,
    packageId: string = "default",
    camera: string[],
    daytime: string,
    selectedOptions: OptionWithVariant[] = [],
    scenario: string,
    frames: number[] = []
  ): Observable<MackevisionServices.VehicleSlugImages_Post200> {
    if (!!this.cfg.apiKey) {
      // temporary use api key and PUT
      // Set the headers including the x-api-key header
      const headers = new HttpHeaders().set("x-api-key", this.cfg.apiKey);
      return this.http.put<MackevisionServices.VehicleSlugImages_Post200>(
        `${this.cfg.apiUrl}/vehicle/${carModelId}/${packageId}/images${
          this.cfg.isUseForceRender ? "?drop-lock" : ""
        }`,
        {
          daytime: daytime,
          options: selectedOptions,
          scenario: scenario,
          cameras: camera,
          // tint_windows: false,
          frames,
        } as MackevisionServices.ImagesRequestBody_Post,
        { headers }
      );
    } else {
      return this.http.post<MackevisionServices.VehicleSlugImages_Post200>(
        `${this.cfg.apiUrl}/vehicle/${carModelId}/${packageId}/images${
          this.cfg.isUseForceRender ? "?drop-lock" : ""
        }`,
        {
          daytime: daytime,
          options: selectedOptions,
          scenario: scenario,
          cameras: camera,
          // tint_windows: false,
          frames,
        } as MackevisionServices.ImagesRequestBody_Post
      );
    }
  }

  lookup(
    carModelId: string,
    packageId: string = "default",
    isDay: boolean,
    selectedOptions: OptionWithVariant[] = []
  ): Observable<MackevisionServices.VehicleSlugPackageIdLookup_Post200> {
    return this.http.post<MackevisionServices.VehicleSlugPackageIdLookup_Post200>(
      `${this.cfg.apiUrl}/vehicle/${carModelId}/${packageId}/lookup`,
      {
        daytime: isDay ? "Day" : "Night",
        config: selectedOptions,
      }
    );
  }

  validate(
    carModelId: string,
    packageId: string = "default",
    camera: string,
    daytime: string,
    selectedOptions: OptionWithVariant[] = [],
    scenario: string
  ): Observable<MackevisionServices.VehicleSlugPackageIdLookup_Post200> {
    return this.http.post<MackevisionServices.VehicleSlugPackageIdLookup_Post200>(
      `${this.cfg.apiUrl}/vehicle/${carModelId}/${packageId}/options`,
      {
        scenario: scenario,
        camera: camera,
        jobtype: this.lookupJobtype(camera),
        daytime: daytime,
        options: selectedOptions,
      } as MackevisionServices.LookupRequestBody_Post
    );
  }

  lookupJobtype(camera: string): "360" | "turntable" | "camera" {
    switch (camera) {
      case "360_interior":
        return "360";
      case "360_exterior":
        return "turntable";
      default:
        return "camera";
    }
  }

  resolveImageUrl(
    prefix: string,
    camera: string,
    trunk: string,
    day: "Day" | "Night",
    scene: string,
    small: boolean = false
  ) {
    prefix = prefix.replace("/", "");

    if (!small) {
      return `/${prefix}/${trunk}-${scene}-${day}-${camera}-2d.jpg`;
    } else {
      return `/${prefix}/small/${trunk}-${scene}-${day}-${camera}-2d.jpg`;
    }
  }

  resolveTurntableUrls(prefix: string, trunk: string, scene: string) {
    prefix = prefix.replace("/", "");
    return new Array<string>(18)
      .fill("")
      .map((p, i) => `/${prefix}/${trunk}-${scene}-Day-turntable-${i}.jpg`);
  }

  read(carModelId: string, packageId: string = "default", trunk: string) {
    return this.http
      .get<MackevisionServices.VehicleSlugPackageIdLookupTrunk_Post200>(
        `${this.cfg.apiUrl}/vehicle/${carModelId}/${packageId}/lookup/${trunk}`
      )
      .pipe(
        // map(response => response.options.map(p => new Value(p)))
        map((response: any) =>
          response.options.filter((f: any) => f.optionId !== "HIGH").map((p: any) => ({
            cobaPrefix: response.vehicle.coba_prefix,
            ...p,
          }))
        )
      );
  }

  checkRule(
    carModelId: string,
    userActions: UserAction[],
    selectedOptions: OptionSelection[] = [],
    marketId: string = "00",
    countryCode: string = "DE",
    dealerId = null
  ): Observable<MackevisionServices.VehicleSlugPackageIdCheckRule_Post200> {
    return this.http.post<MackevisionServices.VehicleSlugPackageIdCheckRule_Post200>(
      `${this.cfg.apiUrl}/vehicle/${carModelId}/default/checkRule`,
      {
        dealer_id: dealerId,
        market_id: marketId,
        country_code: countryCode,
        user_action: userActions,
        options: selectedOptions.map((s) => ({
          optionId: s.optionId,
          variantId: s.variantId,
        })),
      }
    );
  }

  async checkRuleEngine(payload: any): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const options = {
      headers: headers,
      // withCredentials: true // Set withCredentials to true to prevent preflight CORS OPTIONS request
    };
    const body = {
      dealer_id: payload.dealerId,
      market_id: payload.marketId,
      country_code: payload.countryCode,
      user_action: payload.userActions,
      options: payload.selectedOptions.map((s: any) => ({
        optionId: s.optionId,
        variantId: s.variantId,
      })),
    };
    return await this.http
      .post<MackevisionServices.VehicleSlugPackageIdCheckRule_Post200>(
        `${this.cfg.apiUrl}/vehicle/${payload.carModelId}/default/checkRule`,
        body, options
      )
      .toPromise();
  }

  getDealerInfo(
    dealer_code: string,
    locale: string
  ): Observable<MackevisionServices.DealerInfo_Get200> {
    return this.http.get<MackevisionServices.DealerInfo_Get200>(
      `${this.cfg.apiUrl}/dealers/${dealer_code}?locale=${locale}`
    );
  }

  getAllDealers(
    locale: string
  ): Observable<MackevisionServices.Dealers_Get200> {
    return (
      this.http.get<MackevisionServices.Dealers_Get200>(
        `${this.cfg.apiUrl}/dealers?locale=${locale}`
      ) || of({ dealers: [] })
    );
  }

  saveConfiguration(
    modelId: string,
    packageId: string = "default",
    userId: string | null,
    locale: string,
    name: string | null,
    selectedOptions: OptionSelection[] = []
  ): Observable<MackevisionServices.VehicleSlugPackageIdImages_Post200> {
    return this.http
      .post<MackevisionServices.ConfigurationRequestBody_Post>(
        `${this.cfg.apiUrl}/vehicle/${modelId}/${packageId}/configuration`,
        {
          configuration_name: name,
          user_id: userId,
          geo_position: locale,
          location: RT_SAVE_LOCATION,
          options: selectedOptions.map((s) => ({
            optionId: s.optionId,
            variantId: s.variantId,
          })),
        } as MackevisionServices.ConfigurationRequestBody_Post
      )
      .pipe();
  }

  patchConfiguration(
    ferrariId: number,
    modelId: string,
    packageId: string = "default",
    userId: string | null,
    locale: string,
    name: string,
    selectedOptions: OptionSelection[] = []
  ): Observable<MackevisionServices.VehicleSlugPackageIdImages_Post200> {
    return this.http
      .patch<MackevisionServices.ConfigurationRequestBody_Post>(
        `${this.cfg.apiUrl}/vehicle/${modelId}/${packageId}/configuration/${ferrariId}`,
        {
          configuration_name: name,
          user_id: userId,
          geo_position: locale,
          location: RT_SAVE_LOCATION,
          options: selectedOptions.map((s) => ({
            optionId: s.optionId,
            variantId: s.variantId,
          })),
        } as MackevisionServices.ConfigurationRequestBody_Post
      )
      .pipe();
  }

  silentLogin(
    username: string,
    password: string
  ): Observable<MackevisionServices.getToken_Post200> {
    return this.http.post<MackevisionServices.getToken_Post200>(
      `${this.cfg.apiUrl}/login/getToken`,
      {
        username: username,
        password: password,
      }
    );
  }

  loadConfiguration(ferrariCode: string): Observable<any> {
    ferrariCode = ferrariCode.trim();
    return this.http
      .get<MackevisionServices.ConfigurationConfigurationId_Get200>(
        `${this.cfg.apiUrl}/configuration/${ferrariCode}`
      )
      .pipe(
        map((p: MackevisionServices.ConfigurationConfigurationId_Get200) => ({
          carId: p.vehicle?.slug,
          packageId: p.vehicle?.package_id,
          label: p.vehicle?.label,
          values: p.options?.map((s: any) => ({
            optionId: s.optionId,
            variantId: s.variantId,
          })),
          location: p.location,
          configurationId: p.configuration_id,
          imageUrl: p.result.images_urls.image_url
        }))
      );
  }

  loadRawConfiguration(ferrariCode: string): Observable<any> {
    ferrariCode = ferrariCode.trim();
    return this.http
      .get<MackevisionServices.ConfigurationConfigurationId_Get200>(
        `${this.cfg.apiUrl}/configuration/${ferrariCode}`
      )
      .pipe(
        map((p: any) => {return p.result})
      );
  }

  sendToDealer(
    configuration_id: number,
    guid_user: string | null,
    dealer_code: string
  ): Observable<MackevisionServices.SendToDealer_Post200> {
    return this.http.post<MackevisionServices.SendToDealer_Post200>(
      `${this.cfg.apiUrl}/dealer/${configuration_id}`,
      {
        guid_user: guid_user,
        dealer_code: dealer_code,
        configuration_source: `Fcom(CARCONF)`,
      }
    );
  }

  loadImage(url: string): Observable<Blob> {
    const hh = this.http.get(url, { responseType: "blob" });
    return this.http.get(url, { responseType: "blob" });
  }

  downloadPdf(
    carModelName: string,
    configCode: any,
    packageId: string = "default",
    selectedOptions: OptionSelection[] = [],
    perspectives: string[],
    countryCode: string
  ): Observable<any> {
    let fileName = "myFerrari - " + carModelName + " - " + configCode + ".pdf";
    // perspectives = ["34_front_view", "34_rear_view","dashboard","floor_matts_view","seats_view","top_view","wheel_detail"];
    const cameras_custom = perspectives.reduce((obj: any, item: any) => {
      if (!(item in obj)) {
        obj[item] = selectedOptions.map((s) => {
          return { optionId: s.optionId, variantId: s.variantId || null };
        });
      }
      return obj;
    }, {});

    return this.http
      .post<MackevisionServices.VehicleSlugPackageIdPdf_Post200>(
        `${this.cfg.apiUrl}/vehicle/${carModelName}/${packageId}/pdf?country_code=${countryCode}`,
        {
          options: selectedOptions.map((m) => {
            return { optionId: m.optionId, variantId: m.variantId || null };
          }),
          // cameras_custom,
        } as MackevisionServices.PdfRequestBody_Post
      )
      .pipe(
        switchMap((p: any) =>
          this.http.get<Blob>(
            p.pdf_full_url,
            // `${this.cfg.cloudFrontUrl}/pdf/${p.trunk}-${p.configuration_id}-binder.pdf`,
            {
              responseType: "blob" as any,
            }
          )
        ),
        map((blob: Blob) => {
          let newBlob = new Blob([blob], { type: "application/pdf" });
          saveAs(newBlob, fileName);
        })
      );
  }

  requestStatusDownloadPdfImages(
    carModelId: string,
    packageId: string,
    countryCode: string,
    selectedOptions: OptionSelection[] = [],
    isMuseum: boolean = false,
    configurationId: string = ""
  ): Observable<any> {
    let url: string;
    if (!!isMuseum && isMuseum === true) {
      url = `${this.cfg.apiUrl}/vehicle/${carModelId}/${packageId}/pdf?country_code=${countryCode}&museum=true`;
    } else {
      url = `${this.cfg.apiUrl}/vehicle/${carModelId}/${packageId}/pdf?country_code=${countryCode}`;
    }
    if (!!configurationId && configurationId !== "") {
      url += `&pdf_id=${configurationId}`;
    }
    return this.http
    .post<MackevisionServices.VehicleSlugPackageIdPdf_Post200>(url,
      {
        options: selectedOptions.map((m) => {
          return { optionId: m.optionId, variantId: m.variantId || null };
        }),
      } as MackevisionServices.PdfRequestBody_Post
    )
    .pipe(
      catchError((error) => {
        return of({ status: "error" });
      })
    );
  }

  downloadPdfImages(url: string): Observable<any> {
    return this.http.get<Blob>(url, { responseType: 'blob' as any })
    .pipe(
      catchError((error) => {
        console.log("error in downloadPdfImages", error);
        return of(null);
      })
    );
  }

  // get machine serial number
  getMachineSerialNumberMuseum(): Observable<any> {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    });
    return this.http.get<any>(`http://localhost:5555/api/v1/client/serial`, { headers: headers} )
    .pipe(
      catchError((error) => {
        console.log("Error while retrieving Museum machine Serial Number", error);
        return of(null);
      })
    )
  }

  /**
   * Returns a list of suggestions for the current model configuration
   * @returns
   */
  //   getSuggestions(requestData: MackevisionServices.getSuggestionsRequest): Observable<Array<MackevisionServices.getSuggestionsResponse>> {
  //     const options = { headers: new HttpHeaders( {content: 'application/json'} ) }
  //     const endpoint = `${this.cfg.upselling_url}suggest`;
  //     return this.http.post<Array<MackevisionServices.getSuggestionsResponse>>(endpoint, requestData, options);
  //   }
}
