import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  Observable,
  of,
} from "rxjs";
import {
  distinctUntilChanged,
  map,
  share,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from "rxjs/operators";
import { environment } from "../../../environments/environment";

import {
  COMMAND_APPLICATION_CONFIG,
  COMMAND_FERRARI_APPLICATION_CONFIG,
  COMMAND_LISTEN_CONFIGURATION,
} from "./application-commands";

import { ApplicationStateService } from "./application-state.service";
import { cloneDeep, isEqual } from "lodash";
import { VehicleConfig } from "../models/vehicle-config";
import { StateLineClientService } from "./state-line-client.service";
import { ApplicationCommand } from "./application-interfaces";
import { Store } from "@ngrx/store";
import { FerrariState } from "src/app/shared/application-interfaces";

export enum CobaFileType {
  EXCLUDE_VARIANT_OFF = 0,
  INCLUDE_VARIANT_OFF = 1,
}

export interface Exceptions {
  uiLevelConflictBehaviour: string[];
}

export interface VehicleInfoPayload {
  modelId: string;
  des: string;
  vehicleId: string;
  carDetailsName: string;
  commercialModel: string;
  cwsId: string;
  cobaFileType: CobaFileType;
  sortOrder: number;
  additionalData: {
    cobaOptionPrefix: string;
    additionalCobaOptions?: string[];
    drivingExperienceAvailable?: boolean;
    availableDrivingExperiences?: string[];
    pricingEnabled?: boolean;
    preorderEnabled?: boolean;
    notAvailableOptions?: string[];
    exceptions?: Exceptions;
    additionalOptionInformation?: AdditionalOptionInformation[];
    optionSelectionChangeMessages?: OptionWarnMessages[];
  };
}

export interface VehicleInfo extends VehicleInfoPayload {
  marketId: string;
}

interface ModelsPayload {
  models: VehicleInfoPayload[];
}


/*
 * represents the application.json from Unreal
 * @author: ggraf
 * @since: 20.12.2017
 */
export interface ApplicationConfig {
  hideSingleOptionConfigItems: string;
  clientCode: string;
  hmdDevice: string;
  screenshotPath: string;
  httpServerPath: string;
  version: string;

  // if needed, create interfaces for the following objects
  statelineSettings: any;
  defaultCarSettings: any;
  defaultEnvironmentSettings: any;
  cars: any[];
  environments: Environment[];
}

export interface Environment {
  name: string;
  available: boolean;
}

export interface EnviromentResponse {
  environments: Environment[];
}

export interface FerrariApplicationConfig {
  applicationSettings: {
    exportMetaData?: boolean;
    specialDealers?: string[];
  };
  videoSettings: {
    exportEnabled: boolean;
  };
  pdfSettings: any;
  imageStreamSettings: {
    enabled: boolean;
  };
  saveStateSettings: {
    cameras: string[];
  };
  esbSettings: {
    esbEnabled: boolean;
  };
  ssoSettings: {
    enabled: boolean;
  };
}
export interface AdditionalOptionInformation {
  optionId: string;
  variantId?: string;
  icon: string;
  iconColor: string;
  message: string;
}

export interface OptionWarnMessages {
  optionId: string;
  variantId?: string;
  title?: string;
  message: string;
  showWhenSelected: boolean;
}

@Injectable({
  providedIn: "root",
})
export class ApplicationInfoService {
  private activeMarket$: Observable<string | undefined> = of("");
  private availableEnvironments$: Observable<string[]> = of([]);
  private applicationConfig$: Observable<ApplicationConfig | undefined> = of(undefined);
  private ferrariApplicationConfig$: Observable<FerrariApplicationConfig> = this.getFerrariApplicationConfig();

  private  onlineState$: Observable<boolean> = of(false);

  private marketChangedSubject = new BehaviorSubject<boolean>(false);
  private readonly marketChanged$ = this.marketChangedSubject.asObservable();

  private activeConfigurationSubject = new BehaviorSubject<
    VehicleConfig | undefined
  >(undefined);
  private activeCustomer$: Observable<any | undefined> = of(undefined);
  private readonly activeConfiguration$ = this.activeConfigurationSubject
    .asObservable()
    .pipe(
      map((vehicleConfig) => {
        return vehicleConfig ? vehicleConfig.clone() : void 0;
      })
    );

  private _startingConfigurationId: number | undefined;
  set startingConfigurationId(id: number | undefined) {
    this._startingConfigurationId = id;
  }

  get startingConfigurationId() {
    return this._startingConfigurationId || undefined;
  }

  constructor(
    private stateLineClientService: StateLineClientService,
    private applicationStateService: ApplicationStateService,
    private store: Store<FerrariState>
  ) {
    // this.availableMarkets$ = this.applicationStateService.getApplicationAuthenticationState()
    //   .pipe(
    //     filter((available) => available),
    //     switchMap(() => {
    //       return this.stateLineClientService.callJson<MarketsPayload>(ENDPOINT_GET_MARKETS, undefined, true)
    //         .pipe(
    //           map((rawMarkets): MarketInfo[] => rawMarkets.markets)
    //         );
    //     }),
    //     shareReplay(1),
    //     map(markets => cloneDeep(markets))
    //   );
    // // make it hot
    // this.makeObservableHot(this.availableMarkets$);

    // this.initSubscriptionCommands();
  }

  public subscribeToCommandsInit() {
    if (!environment.production) {
      this.applicationStateService.currentStateMachineState$.subscribe(
        (state) => {
          console.log(state);
        },
        (reason) => void 0
      );
    }


    this.makeObservableHot(
      this.applicationStateService
        .subscribeToApplicationCommand(COMMAND_LISTEN_CONFIGURATION)
        .pipe(
          map((payload) => {
            if (payload && payload.stringValue) {
              return payload.stringValue;
            }
            return null;
          }),
          distinctUntilChanged((vehicleConfigA, vehicleConfigB) => {
            return isEqual(vehicleConfigA, vehicleConfigB);
          }),
          tap((vehicleConfig: VehicleConfig | null) => {
            this.activeConfigurationSubject.next(
              vehicleConfig ? cloneDeep(vehicleConfig) : void 0
            );
          }),
          shareReplay(1)
        )
    );

    this.applicationConfig$ = this.applicationStateService
      .subscribeToApplicationCommand(COMMAND_APPLICATION_CONFIG)
      .pipe(
        map((payload) => {
          if (payload) {
            return payload.stringValue;
          }
          return undefined;
        }),
        shareReplay(1)
      );
    // make it hot
    this.makeObservableHot(this.applicationConfig$);

    this.ferrariApplicationConfig$ = this.applicationStateService
      .subscribeToApplicationCommand(COMMAND_FERRARI_APPLICATION_CONFIG)
      .pipe(
        map((payload) => {
          if (payload) {
            return payload.stringValue;
          }
          return undefined;
        }),
        shareReplay(1),
        map((config) => cloneDeep(config))
      );
    // make it hot
    this.makeObservableHot(this.ferrariApplicationConfig$);

  }


  /**
   * Returns all countries that are available to be used for overriding dealer-country info.
   */

  getApplicationConfig() {
    return this.applicationConfig$;
  }

  getFerrariApplicationConfig(): Observable<FerrariApplicationConfig> {
    return this.ferrariApplicationConfig$;
  }

  getAvailableEnvironments(): Observable<string[]> {
    return this.availableEnvironments$;
  }

  getActiveConfiguration() {
    return this.activeConfiguration$;
  }


  getOnlineState(): Observable<boolean> {
    return this.onlineState$;
  }

  getActiveCustomer() {
    return this.activeCustomer$;
  }

  setMarketChaned(changed: boolean) {
    this.marketChangedSubject.next(changed);
  }

  isMarketChanged(): Observable<boolean> {
    return this.marketChanged$;
  }

  resetStartingConfigId() {
    this.startingConfigurationId = undefined;
  }

  // private pipeCarDetailsNameToVehicleInfo (): OperatorFunction<string | undefined, VehicleInfo | undefined> {
  //   return (source: Observable<string>) => {
  //     return source
  //       .pipe(
  //         distinctUntilChanged(),
  //         switchMap((carDetailsName) => {
  //           if (!carDetailsName) {
  //             return of(undefined);
  //           }
  //           return this.availableVehicles$
  //             .pipe(
  //               map((vehicles: VehicleInfo[]) => {
  //                 return vehicles.find(vehicle => vehicle.carDetailsName === carDetailsName);
  //               })
  //             );
  //         }),
  //         shareReplay(1),
  //         map(vehicleInfo => cloneDeep(vehicleInfo))
  //       );
  //   };
  // }

  private makeObservableHot(observable$: Observable<any>) {
    observable$.subscribe(
      () => void 0,
      (error) => console.error("Subscription failed", error)
    );
  }

  private restIt() {
    this.testIt<Map<string, object>>().pipe(
      map((value) => {
        return value.get("test");
      })
    );
  }

  private testIt<T>(): Observable<T> {
    return <Observable<T>>of({});
  }
}
