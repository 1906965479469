<div mat-dialog-title>
  <div class="title-container">
    <div class="rectangle">
      <span>
        {{ "RT.NEWSETTINGS" | translate }}
      </span>
    </div>
    <div class="sub-title-info">
      <span *ngIf="conflictOrigin === 'ForceSelection'">
        {{ "RT.TOKEEPLASTCHOICEAPPLY" | translate }}
      </span>
      <span *ngIf="conflictOrigin === 'AlternativeSelection'">
        {{ "RT.TOKEEPLASTCHOICEAPPROVED" | translate }}
      </span>
      <span *ngIf="conflictOrigin === 'ColorSubstitution'" [innerHtml]="'RT.TOKEEPLASTCHOICECHANGE' | translate : {category:substituteColorcategory | translate }">
        
      </span>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <div class="conflict-container" [ngSwitch]="conflictOrigin">
    <div *ngSwitchCase="'ForceSelection'">
      <ng-container
        *ngIf="
          ruleEngineResponse.trigger && ruleEngineResponse.trigger.length > 0
        "
      >
        <div class="option-title-container">
          <div class="title-area">
            <span>{{ "RT.YOURSELECTION" | translate }}</span>
          </div>
        </div>
        <div class="option-list-container">
          <ng-container *ngFor="let item of ruleEngineResponse.trigger">
            <rt-option-item [item]="item"></rt-option-item>
          </ng-container>
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          ruleEngineResponse.toDeSelect &&
          ruleEngineResponse.toDeSelect.length > 0
        "
      >
        <div class="option-title-container">
          <div class="title-area">
            <span>{{ "RT.ITEMSREMOVED" | translate }}</span>
          </div>
        </div>
        <div class="option-list-container">
          <ng-container *ngFor="let item of ruleEngineResponse.toDeSelect">
            <rt-option-item [item]="item"></rt-option-item>
          </ng-container>
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          ruleEngineResponse.toSelect && ruleEngineResponse.toSelect.length > 0
        "
      >
        <div class="option-title-container">
          <div class="title-area">
            <span>{{ "RT.ITEMSADDED" | translate }}</span>
          </div>
        </div>
        <div class="option-list-container">
          <ng-container *ngFor="let item of ruleEngineResponse.toSelect">
            <rt-option-item [item]="item"></rt-option-item>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <div *ngSwitchCase="'AlternativeSelection'">
      <ng-container
        *ngIf="
          ruleEngineResponse.trigger && ruleEngineResponse.trigger.length > 0
        "
      >
        <div class="option-title-container">
          <div class="title-area">
            <span>{{ "RT.YOURSELECTION" | translate }}</span>
          </div>
        </div>
        <div class="option-list-container">
          <ng-container *ngFor="let item of ruleEngineResponse.trigger">
            <rt-option-item [item]="item"></rt-option-item>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="ruleEngineResponse.alternatives && ruleEngineResponse.alternatives.length > 0">
        <div class="option-title-container">
          <div class="title-area">
            <span>{{ "RT.CHOOSEONE" | translate }}</span>
          </div>
        </div>
        <div class="option-list-container">
          <ng-container *ngFor="let item of ruleEngineResponse.alternatives">
            <rt-option-item [item]="item" [selectable]="true" [selectedSingleItem]="selectedAlternative" (selectedItemEvent)="selectAlternative($event)"></rt-option-item>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="ruleEngineResponse.coupledAlternatives.length > 0">
        <div class="option-title-container">
          <div class="title-area">
            <span>{{ "RT.CHOOSEONE" | translate }}</span>
          </div>
        </div>
        <div class="option-list-container">
          <ng-container *ngFor="let itemList of ruleEngineResponse.coupledAlternatives;let i = index">
            <ng-container *ngFor="let item of itemList; let j = index">
              <rt-option-item [ngClass]="{'hidden' : item.values[0].variantId === '364000000'}" [item]="item" [selectable]="true" [selectedSingleItem]="selectedAlternativeCouple[j]" (selectedItemEvent)="selectCoupledAlternative($event, i)"></rt-option-item>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <div *ngSwitchCase="'ColorSubstitution'">
      <ng-container *ngIf="ruleEngineResponse.dependecyMap">
        <div class="option-title-container">
          <div class="title-area">
            <span>{{ "RT.CHOOSEONE" | translate }}</span>
          </div>
        </div>
        <div class="option-list-container">
          <ng-container *ngFor="let dependencies of ruleEngineResponse.dependecyMap | keyvalue">
            <ng-container *ngFor="let item of dependencies.value;">
              <rt-option-item [item]="item" [selectable]="true" [selectedSingleItem]="selectedAlternative" (selectedItemEvent)="selectAlternative($event)"></rt-option-item>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <div *ngSwitchCase="'Error'"></div>
  </div>
</div>
<div mat-dialog-actions class="footer">
  <span class="action-button-text" (click)="cancel()" [ngClass]="{'disabled' : !(canSkip$ | async)}">{{
    "RT.CANCEL" | translate
  }}</span>
  <span class="divider"></span>
  <span class="action-button-text" (click)="apply()" [ngClass]="{'disabled' : !canApply()}">{{
    "RT.APPLY" | translate
  }}</span>
</div>
