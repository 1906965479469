import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Car } from "src/app/core/models/Car";
import { Store } from "@ngrx/store";
import { FerrariState } from "src/app/shared/application-interfaces";
import { AnimationDialogService } from "src/app/core/services/animation-dialog.service";
import { UtilityService } from "src/app/core/services/utility.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription, catchError, combineLatest, of, switchMap, take } from "rxjs";
import { ConfigurationManagerService } from "src/app/core/services/configuration-manager.service";
import { Api } from "src/app/core/services/_api";
import { OptionSelection, VehicleConfig, VehicleInfo } from "src/app/core/models/vehicle-config";
import { ModelConfigurationService } from "src/app/core/services/model-configuration.service";
import saveAs from "file-saver";
import { HttpClient } from "@angular/common/http";
import { RT_CONFIGURATOR_URL } from "src/app/shared/application-constants";
import { MetaService } from "src/app/core/services/meta.service";
import { AnalyticsService } from "src/app/core/services/analytics.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { isDownloadingPdfImages } from "src/app/state/actions";

@Component({
  selector: "rt-museum-landing-page",
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: "./museum-landing.page.html",
  styleUrls: ["./museum-landing.page.scss"],
})
export class MuseumLandingPage implements OnInit {
  public selectedCar: Car = new Car({});
  public carLogoUrl: Observable<string> = of("");
  public carImageUrl: Observable<string> = of("");
  private carImageUrlString: string = '';
  private carImageCaption: string = '';
  private selectedOptions: OptionSelection[] = [];
  private packageId: string = '';
  public downloadingPDF = false;
  public ferrariCode = "";
  webShareSupported: boolean = false;
  subscription: Subscription = new Subscription();

  constructor(
    private store: Store<FerrariState>,
    public dialogAnimation: AnimationDialogService,
    private util: UtilityService,
    private route: ActivatedRoute,
    private cm: ConfigurationManagerService,
    private api: Api,
    private modelConfigService: ModelConfigurationService,
    private router: Router,
    private http: HttpClient,
    private translate: TranslateService,
    private metaService: MetaService,
    private analytics: AnalyticsService,
    private _snackBar: MatSnackBar
  ) {

    this.removeSuperHeaderFooter();
    this.subscription.add(this.store.select(p => p.ui.isDownloadingPdfImages).subscribe(isDownloading => {
      this.downloadingPDF = isDownloading;
    }));
  }

  ngOnInit(): void {
    this.checkWebShareSupport();
    this.ferrariCode = this.route.snapshot.paramMap.get("ferrariCode") || "";
    if (!!this.ferrariCode){
      this.util.checkIfLoadConfigurationValidCode(this.ferrariCode).subscribe({
        next: result => {
          if (result.isValid){
            console.log("here the ferrariCode object: ", result);
            this.util.getCarModel(result.response.carId).subscribe({
              next: car => {
                if (!!car){
                  this.selectedCar = car;
                  this.packageId = result.response.packageId;
                  this.selectedOptions = this.convertToOptionSelection(result.response.values);
                  this.carLogoUrl = of(`/rt-assets/data/cars/${car.id}/ui/logo.png`);
                  this.carImageUrl = of(this.createImageUrl(result.response.imageUrl));
                  this.carImageUrlString = this.createImageUrl(result.response.imageUrl);
                  this.translate.get("RT.MUSEUM.IMAGE.DESCRIPTION").subscribe(descr => {
                    if (!!descr && descr !== '' && descr !== 'RT.MUSEUM.IMAGE.DESCRIPTION') {
                      this.carImageCaption = descr;
                    } else {
                      this.carImageCaption = 'This is my #FerrariOneOfAKind made at Museo Enzo Ferrari';
                    }
                  });
                  const titlePage = 'Museo Enzo Ferrari Modena';
                  const descrPage = 'This is my #FerrariOneOfAKind made at Museo Enzo Ferrari'
                  this.metaService.refreshMuseum(titlePage, descrPage, this.carImageUrlString);
                }
              }
            });
          }
        }
      })
    }
  }

  createImageUrl(responseUrl: string): string {
    const urlSplitted = responseUrl.split('/');
    urlSplitted.splice(3, 0, 'museum');
    const urlTemp = urlSplitted.join('/');
    return urlTemp;
  }

  convertToOptionSelection(values: any[]): OptionSelection[] {
    let optionSelected: OptionSelection[] = [];
    values.forEach(val => {
      optionSelected.push({ optionId: val.optionId, variantId: val.variantId, selected: true } as OptionSelection);
    })
    return optionSelected;
  }

  checkWebShareSupport() {
    if (!!navigator.share) {
      this.webShareSupported = true;
    }
  }

  // SHARE NATIVO
  share() {
    if (navigator.share) {
      navigator.share({
        title: 'Museo Enzo Ferrari Modena',
        text: this.carImageCaption,
        url: this.carImageUrlString})
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing:', error));
    }
  }

  webshare() {
    let navigator = window.navigator;
    let data: any = { files: [], text: this.carImageCaption, title: this.carImageCaption };
    const options = { type: "image/jpeg" };

    this.http
      .get(this.carImageUrlString, {
        responseType: "arraybuffer"
      })
      .subscribe(response => {
        console.log(response);
        let blob = new File([response], 'FerrariOneOfAKind.jpg', options);
        data.files.push(blob);
        if (navigator.canShare(data)) {
          navigator
            .share(data)
            .then(() => {})
            .catch((err: any) => {
              console.error("Unsuccessful share " + err);
            });
        }
      });
  }

  shareOnFacebook() {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.carImageUrlString)}&quote=${encodeURIComponent(this.carImageCaption)}`;
    window.open(facebookShareUrl, '_blank');
  }

  shareOnTwitter() {
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(this.carImageUrlString)}&text=${encodeURIComponent(this.carImageCaption)}`;
    window.open(twitterShareUrl, '_blank');
  }

  shareOnLinkedIn() {
    const linkedInShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(this.carImageUrlString)}&title=${encodeURIComponent(this.carImageCaption)}`;
    window.open(linkedInShareUrl, '_blank');
  }

  shareOnInstagram() {
    // Instagram doesn't support direct sharing via URLs due to security restrictions
    // Users have to manually upload images through the Instagram app or website
    // You can provide a link to your Instagram profile instead
    window.open('https://www.instagram.com/your_instagram_profile', '_blank');
  }

  saveImage() {
        // Fetch the image data
        this.http.get(this.carImageUrlString, { responseType: 'blob' }).subscribe(blob => {
          // Convert fetched data into Blob object
          // Save the Blob as a file using file-saver
          saveAs(blob, `ferrari-museum.jpg`);
        }, error => {
          console.error('Error fetching image:', error);
        });
    // this.http.get<any>(
    //   this.carImageUrlString,
    //   {
    //     responseType: "blob" as any,
    //   }
    // ).subscribe((blob) => {
    //   const blobObj = new Blob([blob], { type: 'image/jpg' });
    //   saveAs(blobObj, `ferrari-museum.jpg`);
    // });
  }

  downloadPDF() {
    if (this.downloadingPDF) return;

    if (!!this.selectedCar && !!this.packageId) {
      this.store.dispatch(isDownloadingPdfImages({ isDownloadingPdfImages: true }));
      const downloadSubscription = this.util.pollingDownloadPDF(true, this.selectedCar.id, this.packageId, this.util.getCurrentCountry().code, this.selectedOptions, true)
      .subscribe({
        next: (res) => {
          this.store.dispatch(isDownloadingPdfImages({ isDownloadingPdfImages: false }));
          //console.log("response in save and enquire", res);
          if (!!res) {
            console.log("[PDF DOWNLOAD] PDF downloaded successfully");
            this.analytics.download();
          } else {
            // console.log("[PDF DOWNLOAD] Empty response. Something went wrong. PDF not downloaded.");
            // this._snackBar.open("An error occurred. Please try again.", "", {
            //   duration: 3000,
            // });
          }
        },
        error: (err) => {
          this.store.dispatch(isDownloadingPdfImages({ isDownloadingPdfImages: false }));
          console.log(err);
          // console.log("[PDF DOWNLOAD] Something went wrong. PDF not downloaded.");
          //   this._snackBar.open("An error occurred. Please try again.", "", {
          //     duration: 3000,
          //   });
        },
        complete: () => {
          console.log("[PDF DOWNLOAD] Completed");
          downloadSubscription.unsubscribe();
        }
      });
    }
  }

  //old downloadPdf
  OLDdownloadPDF() {
    if (this.downloadingPDF) return;
    this.downloadingPDF = true;
    this.modelConfigService.getVehicleConfigurationMeta(this.selectedCar as VehicleInfo)
    .subscribe((vehicleMeta) => {
      if (!!vehicleMeta) {
        const cameraShots = vehicleMeta.configuration.getAllCameraShots();
        this.api.downloadPdf(
          this.selectedCar.id,
          this.ferrariCode,
          this.packageId,
          this.selectedOptions,
          cameraShots,
          this.util.getCurrentCountry().code
        ).subscribe(() => {
          this.downloadingPDF = false;
        });
      } else {
        this.downloadingPDF = false;
      }
    })
  }

  goToConfigurator() {
    this.api.loadConfiguration(this.ferrariCode).subscribe({
      next: (result: any) => {
        if (!!result && result.configurationId) {
          window.location.href = `${window.location.origin}/${this.util.getCurrentLocale()}/${RT_CONFIGURATOR_URL}/${this.selectedCar.id}/${this.packageId}/exterior?configuration=${result.configurationId}&isStreaming=${this.selectedCar.hasStreaming}`;
        }
        else {
          window.location.href = `${window.location.origin}/${this.util.getCurrentLocale()}`;
        }
      },
      error: (err) => {
        window.location.href = `${window.location.origin}/${this.util.getCurrentLocale()}`;
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private removeSuperHeaderFooter() {
    const params = ["superheader", "superfooter"];
    params.forEach(elementId => {
      const element = document.getElementById(elementId);
      if (!!element) {
        element.remove();
      }
    })
  }

}
