import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRoute,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { cloneDeep } from "lodash";
import { Observable, of, combineLatest, debounceTime, take } from 'rxjs';
import { UtilityService } from "src/app/core/services/utility.service";
import { FerrariState } from "../../shared/application-interfaces";
import { setCarPackage, setSelectedCar, updateConfigLevel } from "../../state/actions";

@Injectable({
  providedIn: "root",
})
export class PackageSelectionResolver implements Resolve<any> {
  constructor(
    private util: UtilityService,
    private route: ActivatedRoute,
    private store: Store<FerrariState>
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    console.log("[RESOLVER] PackageSelectionResolver");
    const carModelId = route.paramMap.get("carModelId") || null;
    const packageId = route.paramMap.get("packageId") || undefined;
    const car$ = this.util.getCarModel(carModelId);

    // if (!!carModelId && !!packageId){
    //   let newMeta = undefined;
    //     this.store.select(p => p.data.vehicleConfigMeta).pipe(debounceTime(2000), take(1))
    //   .subscribe({
    //     next: meta => {
    //       if (!!meta){
    //         const newConfig = cloneDeep(meta.configuration.configHierarchy);
    //         this.util.applyPackage(carModelId, packageId, newConfig);
    //         // meta.configuration = cloneDeep(newConfig);
    //         // this.store.dispatch(updateConfigLevel({configLevel: newConfig}));
    //         newMeta = cloneDeep(meta);
    //         newMeta = Object.assign({}, newMeta, {configuration: {configHierarchy: newConfig}});
    //         console.log('this is new config after package :', newMeta);
    //         this.store.dispatch(updateConfigLevel({configLevel: newMeta}));
    //       }
    //     }
    //   });
    // }

    if (!!car$) {
      car$.subscribe((car) => {
        if (!!car && !!car.id && !!packageId && car.packages.includes(packageId)) {
          this.store.dispatch(setCarPackage({ packageId: packageId }));
        }
      });
    }
    return of("");
  }
}
