// inactivity.service.ts
import { Injectable } from "@angular/core";
import { Subscription, interval, timer } from "rxjs";
import { Store } from "@ngrx/store";
import { FerrariState } from "src/app/shared/application-interfaces";
import { setInstanceStatus } from "src/app/state/actions";
import { MonkeyWayService } from "./monkey-way.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AvailabilityCheckService {
  private pollingInterval  = environment.monkeyWayConfig.checkAvailabilityPollingInterval; // Get the timeout from environment
  private timerSubscription: Subscription;

  constructor(
    private store: Store<FerrariState>,
    private monkeyWayService: MonkeyWayService
  ) {
    this.timerSubscription = new Subscription();
  }

  startStreamingCheckTimer(modelId: string) {
    this.checkInstanceAvailability(modelId);
    this.timerSubscription = interval(this.pollingInterval).subscribe(() => {
      this.checkInstanceAvailability(modelId);
    });
  }

  stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  private checkInstanceAvailability(modelId: string) {
    this.monkeyWayService.checkForAvailableSession(modelId).subscribe(response => {
      if (response === 204) {
        this.stopTimer();
        this.store.dispatch(setInstanceStatus({ hasStreamingInstance: true }));
      } else {
        this.store.dispatch(setInstanceStatus({ hasStreamingInstance: false }));
        console.log("[STREAMING]: no instances are available")
      }
    });
  }
}
