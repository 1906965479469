import { Component, Input, OnInit } from "@angular/core";
import { CommonModule, Location } from "@angular/common";
import { NavigationService } from "../../../core/services/navigation.service";
import { Router, RouterModule, ActivatedRoute } from "@angular/router";
import { FerrariState } from "../../application-interfaces";
import { Store } from "@ngrx/store";
import { setSelectedItem } from "../../../state/actions";
import { ConfigurationProvider } from "src/app/core/services/configuration.provider";

@Component({
  selector: "rt-back-button",
  standalone: true,
  imports: [CommonModule, RouterModule],
  template: `
    <div [ngClass]="{ 'btn-back': arrow === 'background-back' }">
      <div
        [ngClass]="{
          'ferrari-arrow-back': arrow === 'back' || arrow === 'background-back',
          'ferrari-arrow-down': arrow === 'down' && !isMuseum,
          'ferrari-classic-back': arrow === 'down' && isMuseum
        }"
        (click)="goBack()"
      ></div>
    </div>
  `,
  styleUrls: ["../../../../styles/fonts/_ferrari-custom-icon.scss"],
  styles: [
    `
      .btn-back {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background-color: #303030;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .ferrari-arrow-back {
        width: 10px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .ferrari-arrow-down {
        width: 16px;
        height: 16px;
      }
    `,
  ],
})
export class BackButtonComponent implements OnInit {
  @Input() arrow: string = "background-back";
  public isMuseum: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private navService: NavigationService,
    cfg: ConfigurationProvider
  ) {
    this.isMuseum = cfg.isMuseum;
  }

  ngOnInit(): void {}

  goBack() {
    this.navService.back();
  }
}
