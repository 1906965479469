import { Location, isPlatformBrowser, isPlatformServer } from "@angular/common";
import { Inject, Injectable, Optional, PLATFORM_ID } from "@angular/core";
import { ConfigurationProvider } from "./configuration.provider";
import { LocalStorageService } from "./local-storage.service";
import { Observable, catchError, debounceTime, of, switchMap, take, tap } from "rxjs";
import {
  removeUserCredentials,
  setUserCredentials,
} from "src/app/state/actions";
import { FerrariState, User } from "src/app/shared/application-interfaces";
import { Store } from "@ngrx/store";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Api } from "./_api";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    private http: HttpClient,
    private cfg: ConfigurationProvider,
    private ls: LocalStorageService,
    private store: Store<FerrariState>,
    private api: Api
  ) {}

  login(email: string | undefined, password: string | undefined): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!!email && !!password && email !== '' && password !== '') {
        this.api.silentLogin(email, password)
          .pipe(
            debounceTime(0),
            catchError(err => {
              console.log(err);
              reject();
              return of(err);
            })
          ).subscribe(response => {
            if (response && response.access_token) {
              this.ls.access_token = response.access_token;
              this.ls.refresh_token = response.refresh_token;
              this.userInfo().pipe(
                take(1),
                tap(() => this.store.select(p => p.data.user))
                ).subscribe(() => resolve(void 0));
              }
            else {
              console.log('ERROR ' + response);
              reject();
            }
          });
      } else {
        reject();
      }
    })
  }

  userInfo() {
    return this.http
      .get(this.cfg.oAuthUrl + "/userinfo", {
        headers: {
          Authorization: "Bearer " + this.ls.access_token,
        },
      })
      .pipe(
        take(1),
        tap((p) => (this.ls.credentials = p)),
        tap((p: any) => {
          const user: User = { email: p.email, sub: p.sub };
          this.store.dispatch(setUserCredentials({ user: user }));
        }),
        catchError((p) => {
          // if credential expired re new the tokens
          if (this.ls.refresh_token) {
            return this.reNewTokens();
          } else {
            this.store.dispatch(removeUserCredentials());
            this.ls.credentials = undefined;
            this.ls.access_token = undefined;
            this.ls.id_token = undefined;
            return of(p);
          }
        })
      );
  }

  fetchRefreshToken(code: string, redirectUrl: string) {
    const data = "grant_type=authorization_code" +
      "&scope=openid%20offline_access" +
      "&client_id=" + this.cfg.oAuthClientId +
      "&client_secret=" + this.cfg.oAuthClientSecret +
      "&redirect_uri=" + encodeURIComponent(redirectUrl) +
      "&code=" + code;
    return this.http.post(this.cfg.oAuthUrl + '/token',
      data
      ,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
      }).pipe(
        take(1),
        tap((p: any) => {
          if (p) {
            this.ls.id_token = p['id_token'];
            this.ls.access_token = p['access_token'];
            this.ls.refresh_token = p['refresh_token'];
          }
        }),
        catchError(p => {
          this.ls.credentials = undefined;
          this.ls.access_token = undefined;
          this.ls.id_token = undefined;
          this.ls.refresh_token = undefined;
          return of(p);
        }),
      );
  }
  

  logout(redirectUrl: string) {
    const logOutUrl = `${this.cfg.oAuthUrl}/endsession?id_token_hint=${
      this.ls.id_token
    }&post_logout_redirect_uri=${encodeURIComponent(redirectUrl)}`;
    const headers = new HttpHeaders().set(
      "Content-Type",
      "text/plain; charset=utf-8"
    );

    return this.http.get(logOutUrl, { headers, responseType: "text" }).pipe(
      tap((p) => this.store.dispatch(removeUserCredentials())),
      tap((p) => {
        this.ls.credentials = undefined;
      }),
      tap((p) => {
        this.ls.access_token = undefined;
      }),
      tap((p) => {
        this.ls.id_token = undefined;
      }),
      tap((p) => {
        this.ls.refresh_token = undefined;
      })
    );
  }

  private reNewTokens() {
    const data =
      "grant_type=refresh_token" +
      // "&scope=openid%20offline_access" +
      "&client_id=" +
      this.cfg.oAuthClientId +
      "&client_secret=" +
      this.cfg.oAuthClientSecret +
      // "&client_secret=N0JpaldFWnN6Z0xVM0lGbzg2Z2U=" +
      "&refresh_token=" +
      this.ls.refresh_token;
    return this.http
      .post(this.cfg.oAuthUrl + "/token", data, {
        headers: new HttpHeaders().set(
          "Content-Type",
          "application/x-www-form-urlencoded"
        ),
      })
      .pipe(
        tap((p: any) => {
          this.ls.id_token = p["id_token"];
          this.ls.access_token = p["access_token"];
          this.ls.refresh_token = p["refresh_token"];
          this.userInfo();
        }),
        catchError((p) => {
          console.log("renew token error : ", p);
          return of(p);
        })
      );
  }

}
