import { DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { TransferState } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import locales from "../../../rt-assets/i18n/locales.json";
import languages from "../../../rt-assets/i18n/languages.json";
import countries from "../../../rt-assets/i18n/countries.json";
import { Language } from "../models/Language";
import { Locale } from "../models/Locale";
import { Country } from "../models/Country";
import { LocalStorageService } from "./local-storage.service";
import { Store } from "@ngrx/store";
import { FerrariState, MuseumStartingConfig } from "src/app/shared/application-interfaces";
import { CarConfig } from "src/environments/environment-interface";

@Injectable({
  providedIn: "root",
})
export class ConfigurationProvider {
  // private LANGUAGES_FILE = makeStateKey('LANGUAGES_FILE');
  // public languages: any[] = [];
  private isMuseumSelected = false;
  private museumConfig: MuseumStartingConfig[] | undefined;
  private _carConfigList: CarConfig[] = [];

  constructor(
    private ts: TransferState,
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private ls: LocalStorageService,
    private store: Store<FerrariState>
  ) {
    this.setupCarConfigList();
    this.store.select(p => p.ui.isMuseum).subscribe(x => this.isMuseumSelected = x);
    // setTimeout(() => {
    //   if (this.isMuseum) {
        // set default configuration
        http.get<MuseumStartingConfig[]>("/rt-assets/data/museum-default-config.json").subscribe({
          next: (config: MuseumStartingConfig[]) => {
            this.museumConfig = config;
          }
        })
    //   }
    // }, 10);
    // ts.set<any>(this.LANGUAGES_FILE, require('../../../rt-assets/i18n/languages.json'));
    // this.languages = ts.get<any>(this.LANGUAGES_FILE, null as any);
  }

  setupCarConfigList() {
    this.http.get(`./rt-assets/configurations/${environment.carConfigListFileName}`)
    .subscribe({
        next: (data: any) => {
          if (!!data) {
            this._carConfigList = data;
          }
        },
        error: (error: any) => {
          console.error(error);
          console.log("[CONFIG PROVIDER]: Something went wrong while fetching monkeyway car config list data. The monkeyway car config list is empty.")
        }
      });
  }

  public get apiUrl(): string {
    return environment.appConfig.apiUrl;
  }

  public get cloudFrontUrl(): string {
    return environment.appConfig.cloudFrontUrl;
  }

  public get oAuthClientId(): string {
    return environment.appConfig.oAuthClientId;
  }

  public get oAuthUrl(): string {
    return environment.appConfig.oAuthUrl;
  }

  public get oAuthClientSecret(): string {
    return environment.appConfig.oAuthClientSecret;
  }


  public get gtmId(): string {
    return environment.GTM_ID;
  }

  public get carConfigList(): CarConfig[] {
    return this._carConfigList;
  }

  public get isChina(): boolean {
    return environment.isChina;
  }

  public get isUseForceRender(): boolean {
    return environment.appConfig.useForceRender;
  }

  public get isLocal(): boolean {
    return !!environment.environmentName && environment.environmentName === "local";
  }

  public get isMuseum(): boolean {
    return (!!environment.environmentName && environment.environmentName === "museum") || this.isMuseumSelected;
  }

  public get museumStartingConfig(): MuseumStartingConfig[] | undefined{
    return this.museumConfig;
  }

  public get apiKey(): string | undefined {
    return environment.appConfig.apiKey;
  }

  public get locales(): Locale[] {
    const result: Locale[] = [];
    locales.forEach((l) => {
      const locale = new Locale(l);
      result.push(locale);
    });
    return result;
  }

  public get languages(): Language[] {
    const result: Language[] = [];
    languages.forEach((l) => {
      const lang = new Language(l);
      result.push(lang);
    });
    return result;
  }

  public get countries(): Country[] {
    const result: Country[] = [];
    countries.forEach((c) => {
      const country = new Country(c);
      result.push(country);
    });
    return result;
  }
}
