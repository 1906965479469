import { Injectable } from '@angular/core';
import { StateLineConnectionOptions, StateLineCredentials } from "./state-line-client.service";
import { environment } from 'src/environments/environment';


export interface StateLineAuthenticator {
  obtainCredentials: () => Promise<StateLineCredentials>;
}

@Injectable({
  providedIn: 'root'
})
export class StateLineConfigService {
  

  private _url: string | undefined;
  private _websocketProtocol = "wss://";
  private _publicDomainName: string | undefined;
  private _realm: string | undefined;
  private _user: string | undefined;
  private _password: string | undefined;
  private _maxRetries = 3;
  private _initialRetryDelay = 1.5;
  private _maxRetryDelay = 300;
  private _retryDelayGrowth = 1.5;
  private _retryDelayJitter = 0.1;
  private _serializers: any[] = [];

  constructor(){
    this.realm = environment.streamingConfig.realm;
    this.user = environment.streamingConfig.user;
    this.password = environment.streamingConfig.password;
    // this.url = `${this.websocketProtocol}${this.publicDomainName}/ws`;
   }

  public applicationCommandsTopic = "com.mackevision.engine.commands";

  get websocketProtocol(): string | undefined {
    return this._websocketProtocol;
  }

  set websocketProtocol(value: string | undefined) {
    this._websocketProtocol = value || "wss://";
  }

  get publicDomainName(): string | undefined {
    return this._publicDomainName;
  }

  set publicDomainName(value: string | undefined) {
    this._publicDomainName = value;
  }

  get url(): string | undefined {
    return this._url;
  }

  set url(value: string | undefined) {
    this._url = value || `${this.websocketProtocol}${this.publicDomainName}/ws`;
  }

  get realm(): string | undefined {
    return this._realm;
  }

  set realm(value: string | undefined) {
    this._realm = value;
  }

  get user(): string | undefined {
    return this._user;
  }

  set user(value: string | undefined) {
    this._user = value || environment.streamingConfig.user;
  }

  get password(): string | undefined {
    return this._password;
  }

  set password(value: string | undefined) {
    this._password = value || environment.streamingConfig.password;
  }

  get maxRetries(): number | undefined {
    return this._maxRetries;
  }

  set maxRetries(value: number | undefined) {
    this._maxRetries = value || this._maxRetries;
  }

  get initialRetryDelay(): number | undefined {
    return this._initialRetryDelay || this._initialRetryDelay;
  }

  set initialRetryDelay(value: number | undefined) {
    this._initialRetryDelay = value || this._initialRetryDelay;
  }

  get maxRetryDelay(): number | undefined {
    return this._maxRetryDelay;
  }

  set maxRetryDelay(value: number | undefined) {
    this._maxRetryDelay = value || this._maxRetryDelay;
  }

  get retryDelayGrowth(): number | undefined {
    return this._retryDelayGrowth;
  }

  set retryDelayGrowth(value: number | undefined) {
    this._retryDelayGrowth = value || this._retryDelayGrowth;
  }

  get retryDelayJitter(): number | undefined {
    return this._retryDelayJitter;
  }

  set retryDelayJitter(value: number | undefined) {
    this._retryDelayJitter = value || this._retryDelayJitter;
  }

  get serializers(): any[] {
    return this._serializers;
  }

  set serializers(value: any[]) {
    this._serializers = value || this._serializers;
  }

  getStreamingConfigs(): StateLineConnectionOptions{
    const result: StateLineConnectionOptions = {
      url: this.url,
      realm: this.realm,
      maxRetries: this.maxRetries, // unlimited
      initialRetryDelay: this.initialRetryDelay,
      maxRetryDelay: this.maxRetries,
      applicationCommandsTopic: this.applicationCommandsTopic,
      authid: this.user
  }
  return result;
  }
}



// import { StateLineConnectionOptions, StateLineCredentials } from "./state-line-client.service";

// const websocketProtocol = "wss:";
// const streamingValueToChange = "18-195-95-252"; // should read using monkeyway libs
// const baseConnectionUrl = `${websocketProtocol}//ferrari-streaming-${streamingValueToChange}.solution.gorillastreaming.com/ws`;



// export const streamingConfigs: StateLineConnectionOptions = {
//     // url: this.,
//     realm: "realm1",
//     maxRetries: -1, // unlimited
//     initialRetryDelay: 1.5,
//     maxRetryDelay: 300,
//     applicationCommandsTopic: "",
//     authenticator: {
//       obtainCredentials: () => {
//         return new Promise<StateLineCredentials>((resolve, reject) => {
//           resolve({
//             user: "mackevision",
//             password: "m1acke",
//           });
//         });
//       },
//     },
//     authid: "mackevision"
// }