import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import { auditTime, distinctUntilChanged, first, map, skip, switchMap, tap } from 'rxjs/operators';
import { ApplicationStateService } from './application-state.service';
import { COMMAND_UI_STATE } from './application-commands';
import { cloneDeep, get, isEqual, set } from 'lodash';


export interface UiStateCommand<T> {
  path: string[] | string;
  payload: T;
}

@Injectable({
  providedIn: "root",
})
export class UiStateService {

  private uiState$: Observable<any> = of("");

  private readonly updateUiStateSubject = new Subject<UiStateCommand<any>>();

  private readonly uiStateSubject = new BehaviorSubject<any | undefined>({});

  constructor (
    private applicationStateService: ApplicationStateService
  ) {
  }
  init() {
    this.uiState$ = merge(
      this.uiStateSubject
        .pipe(
          skip(1)
        ),
      this.applicationStateService.subscribeToApplicationCommand(COMMAND_UI_STATE)
        .pipe(
          map((uiStateCommand) => {
            if (!uiStateCommand) {
              return {};
            }
            return uiStateCommand.stringValue || {};
          })
        )
    ).pipe(
        distinctUntilChanged((a, b) => isEqual(a, b)),
        // connectedPublishReplay(),
        map((uiState) => cloneDeep(uiState)) // only return deep-clones
      );

    this.updateUiStateSubject
      .pipe(
        switchMap((uiStateCommand) => {
          return this.uiState$
            .pipe(
              first(),
              map((uiState) => {
                let uiStateToBePublished = cloneDeep(uiState);
                if (!uiStateToBePublished) {
                  uiStateToBePublished = {};
                }
                if (!uiStateCommand.path) {
                  // updates whole ui-state
                  uiStateToBePublished = cloneDeep(uiStateCommand.payload);
                } else {
                  // updates only a portion of the state
                  set(uiStateToBePublished, uiStateCommand.path, cloneDeep(uiStateCommand.payload));
                }
                return uiStateToBePublished;
              })
            );
        })
      ).subscribe(this.uiStateSubject);

    this.uiStateSubject
      .pipe(
        skip(1), // skip the first one as it is the empty, initial ui-state that might override an existing ui-state
        auditTime(0),
        tap((uiState) => {
          this.publishUiState(uiState);
        }),
        // connectedPublishReplay()
      );
  }

  /**
   * Returns an Observable stream providing either the complete UI-State or only a portion of it
   * determined by the given path.
   * @param path The path to observe inside the UI-State.
   */
  getUiState<T> (path?: string[] | string): Observable<T | undefined> {
    if (!path) {
      return this.uiState$;
    }
    return this.uiState$
      .pipe(
        map((uiState) => {
          return get(uiState, path);
        }),
        distinctUntilChanged((a, b) => {
          return isEqual(a, b);
        })
      );
  }

  /**
   * Get the current ui state or a portion of it.
   * @param path The path to observe inside the UI-State.
   */
  getUiStateSync<T> (path?: string[] | string): T | undefined {
    if (!path) {
      return cloneDeep(this.uiStateSubject.getValue());
    }
    return cloneDeep(get(this.uiStateSubject.getValue(), path));
  }

  /**
   * Updates the UI-State to the given value. If path is given, only this portion of the UI-State is updated.
   * @param updatedUiState The updated UI-State.
   * @param path The path to update inside the UI-State.
   */
  updateUiState<T> (uiStateCommand: UiStateCommand<T>) {
    this.updateUiStateSubject.next(uiStateCommand);
  }

  private publishUiState (uiState: any) {
    this.applicationStateService.publishCommand({
      type: COMMAND_UI_STATE,
      stringValue: JSON.stringify(uiState)
    });
  }

}