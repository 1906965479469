import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Observable, interval, BehaviorSubject, of } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

const STORE_KEY =  'userLastAction';

@Injectable({
    providedIn: "root",
  })
export class NgIdleService implements OnDestroy {
  public static runTimer: boolean;
  public static runSecondTimer: boolean;
  public USER_IDLE_TIMER_VALUE_IN_MIN: number = environment.streamingConfig.inactivityTimeout;
  public userIdlenessChecker: BehaviorSubject<string>;

  private _idleTimeout = this.USER_IDLE_TIMER_VALUE_IN_MIN;

  private sessionForIdle: Observable<number> | undefined;
  private userActivityChangeCallback: (($event: any) => void) | undefined;

  public clockForIdle: Observable<number> = of(0);

  constructor(private zone: NgZone, private ls: LocalStorageService) {

    this.userIdlenessChecker = new BehaviorSubject<string>('INITIATE_TIMER');
    
  }

  public initilizeSessionTimeout(): void {
    NgIdleService.runTimer = true;
    console.log('Initial ng-idle');

    this.reset();
    this.initListener();
    this.initInterval();
  }

  get idleTimeout(): number {
    return this._idleTimeout;
  }

  set idleTimeout(value: number) {
    this._idleTimeout = value;
  }

  get lastAction(): number {
    return parseInt(this.ls.lastAction, 10);
  }

  set lastAction(value) {
    this.ls.lastAction = value.toString();
  }


  private initListener(): void {
    this.zone.runOutsideAngular(() => {
      console.log('Initial ng-idle listener');
      this.userActivityChangeCallback = ($event) => this.handleUserActiveState($event);
      window.document.addEventListener('click', this.userActivityChangeCallback.bind(this), true);
    });
  }

  handleUserActiveState(event: any): void {
    this.reset();
  }

  public reset(): void {
    this.lastAction = Date.now();
    if (this.userIdlenessChecker) {
      this.userIdlenessChecker.next('RESET_TIMER');
    }
  }

  private initInterval(): void {
    console.log('Initial ng-idle initInterval');
    const intervalDuration = 1000;
    this.sessionForIdle = interval(intervalDuration).pipe(
      map((tick: number) => {
        return tick;
      }),
      takeWhile(() => NgIdleService.runTimer)
    );

    this.check();
  }

  private check(): void {
    if (!!this.sessionForIdle){
        this.sessionForIdle
        .subscribe(() => {
          const now = Date.now();
          const timeleft = this.lastAction + this.idleTimeout * 60 * 1000;
          const diff = timeleft - now;
          const isTimeout = diff < 0;
    
          this.userIdlenessChecker.next(`${diff}`);
    
          if (isTimeout) {
            if (!!this.userActivityChangeCallback){
                window.document.removeEventListener('click', this.userActivityChangeCallback, true);
            }
            this.zone.run(() => {
              if (this.userIdlenessChecker) {
                this.userIdlenessChecker.next('IDLE_TIMEOUT');
              }
              NgIdleService.runTimer = false;
            });
          }
        });
    }
  }

  public removeActionFromStore(): void {
    this.ls.remove(STORE_KEY);
  }

  
  ngOnDestroy(): void {
    if (this.userIdlenessChecker) {
      this.userIdlenessChecker.unsubscribe();
    }
  }

}
