import { Action, createReducer, on } from '@ngrx/store';
import { ErrorState } from '../../shared/application-interfaces';
import * as actions from '../actions';
import { initialState } from '../initialState';

export const errorReducer = createReducer(
  initialState.error,
  on(actions.setError, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: ErrorState | undefined, action: Action) {
  return errorReducer(state, action);
}
