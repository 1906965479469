import { Option, OptionWithVariant } from './configuration-meta'

export class PreconfigurationMuseum {
  id: string = '';
  name: string = '';
  carDetailsName: string = '';
  package: string = '';
  trunk: string = '';
  chosenOptions: any[] = []
  lang: string = '';
  configurationCode: string = '';
  image: string = '';
  thumbnail: string = '';

  constructor(data?: any) {
    if (data) {
      this.id = data.id ? data.id : '';
      this.name = data.name ? data.name : '';
      this.carDetailsName = data.carDetailsName ? data.carDetailsName : '';
      this.package = data.package ? data.package : '';
      this.trunk = data.trunk ? data.trunk : '';
      this.chosenOptions = data.chosen_options ? data.chosen_options : [];
      this.lang = data.lang ? data.lang : '';
      this.configurationCode = data.configurationCode ? data.configurationCode : '';
      this.image = data.image ? data.image : '';
      this.thumbnail = data.thumbnail ? data.thumbnail : '';
    }
  }
}