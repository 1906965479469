import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Option } from "../../../../core/models/configuration-meta";
import { Store } from "@ngrx/store";
import { FerrariState } from "../../../../shared/application-interfaces";
import { Observable } from "rxjs";
import { Car } from "../../../../core/models/Car";
import { OptionSelection } from "../../../../core/models/vehicle-config";
import { ConfigurationManagerService } from 'src/app/core/services/configuration-manager.service';
import { UtilityService } from "src/app/core/services/utility.service";

@Component({
  selector: "rt-option-item",
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: "./option-item.component.html",
  styleUrls: ["./option-item.component.scss"],
})
export class OptionItemComponent implements OnInit {
  @Input() item: Option | undefined;
  @Input() selectable: boolean = false;
  @Input() selectedSingleItem: Option | undefined;
  @Output() selectedItemEvent = new EventEmitter<Option>();
  
  public car$: Observable<Car>;

  public subConfigLabel = "";
  public showStep = false;

  constructor(private store: Store<FerrariState>, private translate: TranslateService, private cm: ConfigurationManagerService, private util: UtilityService) {
    this.car$ = store.select((s) => s.data.car);

  }

  ngOnInit(): void {
    this.isShowCategory();
    const meta = this.cm.getVehicleMetaValue();
    if (!!meta && this.item){
      const configPath = this.item.getPathToRoot();
      configPath.pop();
      const level = meta.configuration.getConfigurationLevel(configPath);
      if (!!level){
        this.subConfigLabel = level.name;
      }
    }
  }

  selectItem() {
    if (!!this.item) {
      this.selectedSingleItem = this.item;
      this.selectedItemEvent.emit(this.selectedSingleItem);
      
    }
  }

  private isShowCategory(){
    if (!!this.item){
      if (this.item.id.includes("_")){
        const language = this.util.getCurrentLanguage();
        if (this.item.category) {
          this.translate.get([this.item.category]).subscribe(translationObj => {
            if (!!translationObj){
              this.showStep = true;
            }
          });
        }
      }
    }
  }

  // private getTranslateArray() {
  //   return new Promise(resolve => {
      
  //     this.store.select(p => p.data.language).subscribe(language => {
  //       // this.translate.get().getTranslation(language.code).subscribe(translationObj => {
  //       const result: any[] = [];
  //       if (!!this.item) {
  //         const translatekeys = [];
  //         if (this.item.category) {
  //           translatekeys.push(this.item.category);
  //         }
  //         if (this.item.parentConfiguration && this.item.parentConfiguration.name){
  //           translatekeys.push(this.item.parentConfiguration.name);
  //         }

  //         if (translatekeys.length > 0) {
  //           this.translate.get(translatekeys)
  //           .subscribe(res => {
  //             result.push({ category: res[categoryKey], label: res[labelKey] });
  //             if ((i + 1) == this.options.length) {
  //               console.log('translates:', result);
  //               resolve(result);
  //             }
  //           });
  //         }
          
  //       }
  //       for (let i = 0; i < this.options.length; i++) {

  //         let categoryKey = `PREMIUM.SUBCONFIG.${this.options[i].optionListId.toUpperCase()}`;
  //         let labelKey = this.namePipe.transform(this.options[i]); //this.options[i].name_label ? this.options[i].name_label : this.options[i].name;
  //         // this.options[i].groupId === "equipment" ? key += ".TITLE" : "";
  //         this.translate.get([categoryKey, labelKey])
  //           .subscribe(res => {
  //             result.push({ category: res[categoryKey], label: res[labelKey] });
  //             if ((i + 1) == this.options.length) {
  //               console.log('translates:', result);
  //               resolve(result);
  //             }
  //           });


  //       }





  //     })
  //     // })
  //   })
  // }
}
