import { Routes } from "@angular/router";
import { NotFoundPage } from "../shared/pages/not-found/not-found.page";
import { InvalidLocaleGuard } from "./_guards/invalid-locale.guard";
import { LocaleResolver } from "./_resolvers/locale.resolver";
import { ModelSelectionResolver } from "./_resolvers/model-selection-.resolver";
import { PackageSelectionResolver } from "./_resolvers/package-selection.resolver";
import { VehicleConfigResolver } from "./_resolvers/vehicle-config.resolver";
import { ListComponent } from "../features/configuration-area/list/list.component";
import { ClearConfigurationStoreGuard } from "./_guards/clear-configuration-store.guard";
import { EmptyComponent } from "../shared/components/empty/empty.component";
import { InvalidFerrariCodeGuard } from "./_guards/invalid-ferrari-code.guard";
import { RetriveCodeResolver } from "./_resolvers/retrive-code.resolver";
import { MuseumLandingPage } from "../features/configuration-area/museum-landing-page/museum-landing.page";
import { ActiveCarGuard } from "./_guards/active-car.guard";
import { MuseumPage } from "../features/model-selection/museum/museum.page";
import { PostLoginLandingPage } from "../features/post-login-landing/post-login-landing.page";
import { MuseumSelectionModel } from "../features/model-selection/museum/museum-selection-model/museum-selection-model.page";
import { CarListResolver } from "./_resolvers/car-list.resolver";

const routes: Routes = [
  { path: "404", component: NotFoundPage, pathMatch: "full" },
  { path: "iam_authorize", component: PostLoginLandingPage , pathMatch: "full"},
  // { path: "museum", pathMatch: "full", resolve: { data: CarListResolver },
  // loadComponent: () =>
  //   import(
  //     "../features/model-selection/museum/museum-selection-model/museum-selection-model.page"
  //   ).then((c) => c.MuseumSelectionModel), },
  // { path: "museum", component: MuseumPage, pathMatch: "full" },
  // {
  //   path: "iam_authorize",
  //   loadComponent: () =>
  //     import("../features/post-login-landing/post-login-landing.page").then(
  //       (c) => c.PostLoginLandingPage
  //     ),
  //   pathMatch: "full",
  // },
  {
    path: ":locale",
    canActivate: [InvalidLocaleGuard],
    resolve: {
      locale: LocaleResolver,
    },
    runGuardsAndResolvers: "paramsChange",
    children: [
      {
        path: "museumlanding/:ferrariCode",
        component: MuseumLandingPage,
        pathMatch: "full",
      },
      {
        path: "code",
        loadComponent: () =>
          import("../features/load-configuration/load-configuration.page").then(
            (c) => c.LoadConfigurationPage
          ),
        pathMatch: "full",
      },
      {
        path: "code/:ferrariCode",
        canActivate: [InvalidFerrariCodeGuard],
        component: EmptyComponent,
        resolve: { redirect: RetriveCodeResolver },
        pathMatch: "full",
      },
      {
        path: "",
        loadComponent: () =>
          import("../features/model-selection/model-selection.page").then(
            (c) => c.ModelSelectionPage
          ),
        children: [
          {
            path: "",
            loadComponent: () =>
              import("../features/model-selection/home/home.component").then(
                (c) => c.HomeComponent
              ),
          },

          // just for cars that have more than one package
          {
            path: "ferrari_car_configurator/:carModelId",
            resolve: { car: ModelSelectionResolver },
            canActivate: [ActiveCarGuard],
            loadComponent: () =>
              import(
                "../features/model-selection/package-selection/package-selection.component"
              ).then((c) => c.PackageSelectionComponent),
          },
          {
            path: "ferrari_car_configurator/:carModelId/:packageId",
            resolve: { data: ModelSelectionResolver, PackageSelectionResolver },
            canActivate: [ClearConfigurationStoreGuard],
            loadComponent: () =>
              import(
                "../features/model-selection/preconfig-selection/preconfig-selection.component"
              ).then((c) => c.PreconfigSelectionComponent),
          },
          {
            path: "ferrari_car_configurator/:carModelId/:packageId/:section",
            loadComponent: () =>
              import(
                "../features/configuration-area/configuration-area.page"
              ).then((c) => c.ConfigurationAreaPage),
            resolve: {
              car: ModelSelectionResolver,
              data: VehicleConfigResolver,
            },
            children: [
              {
                path: "",
                outlet: "streaming",
                loadComponent: () =>
                  import(
                    "../features/configuration-area/car-viewer/car-viewer.component"
                  ).then((c) => c.CarViewerComponent),
              },
              {
                path: "",
                loadComponent: () =>
                  import(
                    "../features/configuration-area/configuration-panel/configuration-panel.component"
                  ).then((c) => c.ConfigurationPanelComponent),
                children: [
                  {
                    path: "",
                    loadComponent: () =>
                      import(
                        "../features/configuration-area/navigation/navigation.component"
                      ).then((c) => c.NavigationComponent),
                  },
                  {
                    path: ":level/list",
                    component: ListComponent,
                  },
                ],
              },
              {
                path: ":level",
                loadComponent: () =>
                  import(
                    "../features/configuration-area/config-level/config-level.component"
                  ).then((c) => c.ConfigLevelComponent),
                children: [],
              },
              {
                path: ":level/:subLevel",
                loadComponent: () =>
                  import(
                    "../features/configuration-area/config-level/config-level.component"
                  ).then((c) => c.ConfigLevelComponent),
              },
            ],
          },
        ],
      },
    ],
  },
  { path: "", redirectTo: "/en", pathMatch: "full" },
  {
    path: "**",
    loadComponent: () =>
      import("../shared/pages/not-found/not-found.page").then(
        (c) => c.NotFoundPage
      ),
  },
];

export default routes;
