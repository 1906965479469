import { Injectable } from "@angular/core";
// import { streamingConfigs } from "./wamp-config";
import { StateLineCredentials } from "./state-line-client.service";
import { StateLineConfigService } from "./state-line-config";
import { Subject, of } from "rxjs";

declare const autobahn: any;

@Injectable({
  providedIn: "root",
})
export class WampClientService {
  get sessionId(): number | undefined {
    if (this._overrideSessionId) {
      return this._overrideSessionId;
    }
    return this.session ? this.session.id : undefined;
  }

  private session: any;
  private connection: any;
  private _wampConfig$: Subject<any> = new Subject();

  get wampConfig$(): Subject<any> {
    return this._wampConfig$;
  }

  set wampConfig$(value: any) {
    this._wampConfig$.next(value);
  }


  private _overrideSessionId: number | undefined = 123456789;

  /**
   * Manually override the session id used to communicate with the WAMP-server.
   * @param sessionId
   */
  private set overrideSessionId(sessionId: number | undefined) {
    console.warn(
      "Manually overriding the session id should only be used for debug purposes."
    );
    this._overrideSessionId = sessionId;
  }

  private isConnected = false;

  constructor(private wampConfigService: StateLineConfigService) {
    // this.connection = new autobahn.Connection(streamingConfigs); // Use the imported config object
    //   this.connection.onopen = (session: any, details: any) => {
    //     console.log("WAMP connection established:", details);
    //     // Perform actions on successful connection
    //     // E.g., register procedures, subscribe to topics, etc.
    //     // session.register('com.myapp.procedure', this.myProcedure);
    //     // session.subscribe('com.myapp.topic', this.mySubscription);
    //   };
    //   this.connection.open();
  }

  openNewConnection(
    onConnectionOpen: (details: any) => void,
    onConnectionClose: (details: any) => boolean
  ): void {
    // if (this.wampConfigService.)
    const wampConfig = {
      url: this.wampConfigService.url,
      realm: this.wampConfigService.realm,
      authmethods: ["wampcra", "cookie"],
      onchallenge: (session: any, method: any, extra: any, password: string) =>
        this.onChallenge(session, method, extra, this.wampConfigService.password),
      authid: this.wampConfigService.user,
      max_retries: this.wampConfigService.maxRetries,
      initial_retry_delay: this.wampConfigService.initialRetryDelay,
      max_retry_delay: this.wampConfigService.maxRetryDelay,
      retry_delay_growth: this.wampConfigService.retryDelayGrowth,
      retry_delay_jitter: this.wampConfigService.retryDelayJitter || 0.1,
      serializers: [
        new autobahn.serializer.MsgpackSerializer(),
        new autobahn.serializer.JSONSerializer(),
      ],
    }
    this.wampConfig$ = wampConfig;
    this.connection = new autobahn.Connection(<any>wampConfig);

    this.connection.onopen = (session: any, details: any) => {
      this.isConnected = true;
      this.session = session;

      if (onConnectionOpen) {
        onConnectionOpen(details);
      }
    };

    this.connection.onclose = (reason: any, details: any) => {
      this.session = undefined;
      this.isConnected = false;

      if (onConnectionClose) {
        return onConnectionClose(details);
      }
      return false;
    };

    this.connection.open();
  }

  closeCurrentConnection() {
    if (this.connection) {
      this.connection.close();
    }
    this.connection = undefined;
  }

  isConnectionOpen() {
    return this.isConnected;
  }

  registerProcedure(procedure: string, endpoint: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.session) {
        return reject("No session open");
      }
      this.session.register(procedure, endpoint).then(resolve, reject);
    });
  }

  unregisterProcedure(registration: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.session) {
        return reject("No session open");
      }
      this.session.unregister(registration).then(resolve, reject);
    });
  }

  callRemoteProcedure<T>(procedure: string, args?: any[]): Promise<T> {
    return new Promise((resolve, reject) => {
      if (!this.session) {
        return reject("No session open");
      }
      (this.session.call(procedure, args) as Promise<T>).then(resolve, reject);
    });
  }

  subscribeToTopic(
    topic: string,
    handler: any,
    match: string = "exact"
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.session) {
        return reject("No session open");
      }
      const matchObj = {
        match,
      };

      this.session.subscribe(topic, handler, matchObj).then(resolve, reject);
    });
  }

  unsubscribeToTopic(subscription: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.session) {
        resolve(void 0);
        return;
      }
      try {
        this.session.unsubscribe(subscription).then(resolve, reject);
      } catch (error) {
        reject(error);
      }
    });
  }

  publishEvent(topic: string, args: any[]) {
    return new Promise((resolve, reject) => {
      if (this.session) {
        this.session.publish(topic, args);
        resolve(void 0);
      } else {
        reject("No session open");
      }
    });
  }

  protected onChallenge(
    session: any,
    method: string,
    extra: any,
    password?: string
  ): string {
    if (!password) {
      throw new Error('Cannot sign without password');
    }
    if (method === "wampcra") {
      return autobahn.auth_cra.sign(password, extra.challenge);
    }
    throw new Error(`Unknown challenge method [${method}].`);
  }
}
