import { Component, Inject, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "../../../shared/_modules/material.module";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RuleEngineResponse } from "../../../core/models/RuleEngineResponse";
import { FerrariState, RuleEngineModalResult } from "src/app/shared/application-interfaces";
import { OptionSelection } from "../../../core/models/vehicle-config";
import { OptionItemComponent } from "./option-item/option-item.component";
import { Option } from "../../../core/models/configuration-meta";
import { Store } from "@ngrx/store";
import { Observable } from 'rxjs';

@Component({
  selector: "app-rule-engine-modal",
  standalone: true,
  imports: [CommonModule, MaterialModule, TranslateModule, OptionItemComponent],
  templateUrl: "./rule-engine-modal.component.html",
  styleUrls: ["./rule-engine-modal.component.scss"],
})
export class RuleEngineModalComponent implements OnInit {
  public ruleEngineResponse: RuleEngineResponse;
  public conflictOrigin: string;
  public triggerCategory?: string;
  public selectedAlternative?: Option;
  public selectedAlternativeCouple: Option[] = [];
  public canSkip$: Observable<boolean>;
  public substituteColorcategory = "";

  private bePayload: any;



  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    public dialogRef: MatDialogRef<RuleEngineModalComponent>,
    store: Store<FerrariState>
  ) {
    this.ruleEngineResponse = data.ruleEngineResponse;
    this.conflictOrigin = data.origin;
    this.bePayload = data.bePayload;
    this.canSkip$ = store.select(p => p.ui.canSkipRulePopup);

    this.canSkip$.subscribe({next: canSkipRulePopup => {
      dialogRef.disableClose = !canSkipRulePopup;
    }});
  }

  ngOnInit(): void {
    console.log(
      "here the conflict modal ruleEngineResponse:",
      this.ruleEngineResponse
    );
    if (
      !!this.ruleEngineResponse.trigger &&
      this.ruleEngineResponse.trigger.length > 0
    ) {
      const trigger: Option = this.ruleEngineResponse.trigger[0];
      this.triggerCategory = trigger.category;
    }

    if (this.conflictOrigin === "ColorSubstitution"){
      // translate category for use in UI
      if (!!this.ruleEngineResponse.dependenciesColors && this.ruleEngineResponse.dependenciesColors.length > 0){
        this.ruleEngineResponse.dependenciesColors = this.ruleEngineResponse.dependenciesColors.filter(f => f.selectable)
        this.substituteColorcategory = this.ruleEngineResponse.dependenciesColors[0].category || "";
      }
    }
    if (this.conflictOrigin === "AlternativeSelection"){
        // when alternatives remove non selectable
        if (!!this.ruleEngineResponse.alternatives && this.ruleEngineResponse.alternatives.length > 0){
          this.ruleEngineResponse.alternatives = this.ruleEngineResponse.alternatives.filter(f => f.selectable)
        }
    }
  }

  selectAlternative(optSelect: Option) {
    this.selectedAlternative = optSelect;
  }

  selectCoupledAlternative(optSelect: Option, listIndex: number){
    this.selectedAlternativeCouple = this.ruleEngineResponse.coupledAlternatives[listIndex];
  }

  canApply(){
    if (this.ruleEngineResponse.alternatives && this.ruleEngineResponse.alternatives.length > 0 && !this.selectedAlternative && this.conflictOrigin !== 'ForceSelection'){
      return false;
    }
    return true;
  }

  apply() {
    if (!this.canApply()){
      return;
    }
    const result: RuleEngineModalResult = { isApproved: true, options: [] };
    // make sure toselect & toDeselect in options
    if (!!this.ruleEngineResponse.toSelect && this.ruleEngineResponse.options) {
      for (let opt of this.ruleEngineResponse.toSelect) {
        if (!this.ruleEngineResponse.options.some((s) => s.id === opt.id)) {
          this.ruleEngineResponse.options.push(opt);
        }
      }
    }

    if (
      !!this.ruleEngineResponse.toDeSelect &&
      this.ruleEngineResponse.options
    ) {
      for (let opt of this.ruleEngineResponse.toDeSelect) {
        const indx = this.ruleEngineResponse.options.findIndex(
          (s) => s.id === opt.id
        );
        if (indx !== -1) {
          this.ruleEngineResponse.options.splice(indx, 1);
        }
      }
    }
    switch (this.conflictOrigin) {
      case "ForceSelection":
        // use options in rulEngineResponse
        if (!!this.ruleEngineResponse.options) {
          this.ruleEngineResponse.options.forEach((opt) => {
            if (opt.values) {
              const optSelect: OptionSelection = {
                optionId: opt.values[0].optionId,
                variantId: opt.values[0].variantId || null,
                selected: true,
              };
              result.options?.push(optSelect);
            }
          });
        }
        break;
      case "AlternativeSelection":
        result.userDecision = [];
        if (!!this.selectedAlternative && !!this.selectedAlternative.values) {
          const optSelect: OptionSelection = {
            optionId: this.selectedAlternative.values[0].optionId,
            variantId: this.selectedAlternative.values[0].variantId || null,
            selected: true,
          };
          result.userDecision = [optSelect];
        }
        if (this.selectedAlternativeCouple.length > 0){
          // featur2
          this.selectedAlternativeCouple.forEach((item: Option) => {
            if (!!item.values){
              const optSelect: OptionSelection = {
                optionId: item.values[0].optionId,
                variantId: item.values[0].variantId || null,
                selected: true,
              };
              result.userDecision?.push(optSelect);
            }
          });
        }
        break;
      case "ColorSubstitution":
        if (!!this.selectedAlternative && !!this.selectedAlternative.values) {
          const optSelect: OptionSelection = {
            optionId: this.selectedAlternative.values[0].optionId,
            variantId: this.selectedAlternative.values[0].variantId || null,
            selected: true,
          };
          result.userDecision = [optSelect];
        }
        break;

      // default:
      //   this.dialogRef.close(result);
      //   break;
    }

    // consider if multiple conflict we need to put to_deselect in userAction for disable
    if (this.ruleEngineResponse.origins.length > 1){
      if (this.ruleEngineResponse.toDeSelect && this.ruleEngineResponse.toDeSelect.length > 0){
        this.ruleEngineResponse.toDeSelect.forEach(opt => {
          if (opt.values){
            const optDeSelect: OptionSelection = {
              optionId: opt.values[0].optionId,
              variantId: opt.values[0].variantId || null,
              selected: false,
            };
            if (!result.userDecision){
              result.userDecision = [];
            }
            result.userDecision.push(optDeSelect);
          }
        })
      }
    }
    this.dialogRef.close(result);
  }

  cancel() {
    const result: RuleEngineModalResult = { isApproved: false };
    this.dialogRef.close(result);
  }
}
