import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { ConfigurationProvider } from "src/app/core/services/configuration.provider";
import { FerrariState } from "src/app/shared/application-interfaces";
import { setCountry } from "src/app/state/actions";
import { Country } from "../../core/models/Country";

@Injectable({
  providedIn: "root",
})
export class InvalidLocaleGuard implements CanActivate {
  constructor(
    private router: Router,
    private cfg: ConfigurationProvider,
    private store: Store<FerrariState>
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    console.log("[GUARD] InvalidLocaleGuard");

    const locale = route.paramMap.get("locale");

    if (!!locale) {
      const languageCode = locale.split("_")[0];
      const countryCode = locale.split("_")[1];

      if (!!languageCode) {
        const validLanguage = this.cfg.languages.find((p) => p.code === languageCode);
        if (!!validLanguage) {
          console.log("language: ", validLanguage);
        } else {
          this.router.navigateByUrl("/404");
        }
      } else {
        this.router.navigateByUrl("/404");
      }

      if (!!countryCode) {
        const validCountry = this.cfg.countries.find((p) => p.code === countryCode);
        if (!!validCountry) {
          console.log("country: ", validCountry);
          this.store.dispatch(setCountry({ country: validCountry }));
        } else {
          this.router.navigateByUrl("/404");
        }
      } else {
        this.router.navigateByUrl("/404");
      }
    }

    return true;
  }
}
