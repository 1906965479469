import { Store } from "@ngrx/store";
import { cloneDeep, isEqual, isNumber, trim, values } from "lodash";
import { FerrariState } from "src/app/shared/application-interfaces";
import { VehicleConfigurationMeta, Option } from './configuration-meta';

export interface OptionSelection {
  optionId: string;
  selected: boolean;
  variantId: string | null;
  isUserDescision?: boolean;
}
export interface VehicleConfigModel {
  [option: string]: OptionSelection;
}
export interface SelectedOptionAware {
  getSelectedOptions(): OptionSelection[];
  isOptionSelected(optionId: string, variantId?: string): boolean;
}

export interface VehicleConfigModelAware {
  getConfig(): VehicleConfigModel;
  applyConfig(vehicleConfigModel: VehicleConfigModel): void;
}

/**
 * Defines the interface for accessing option values as they are currently configured.
 */
export interface PropertyContext {
  /**
   * Get the value configured for a variant of an option.
   * @param {string} option
   * @return {string}
   */
  getVariant(option: string): string | null;

  isSelected(option: string, variant?: string): boolean;
}

/**
 * Function that checks if two arrays containing OptionSelections are equal.
 * @param selectionsA
 * @param selectionsB
 */
export const areSelectedOptionsEqual = (
  selectionsA: OptionSelection[],
  selectionsB: OptionSelection[]
) => {
  const sortByOptionId = (a: any, b: any) =>
    a.optionId.localeCompare(b.optionId);
  const normalizeSelection = (selection: any) => {
    if (!selection.comment) {
      delete selection.comment;
    }
    if (!selection.variant) {
      delete selection.variant;
    }
    return selection;
  };
  let normalizedSelectionsA = cloneDeep(selectionsA).sort(sortByOptionId);
  let normalizedSelectionsB = cloneDeep(selectionsB).sort(sortByOptionId);
  normalizedSelectionsA = normalizedSelectionsA.map(normalizeSelection);
  normalizedSelectionsB = normalizedSelectionsB.map(normalizeSelection);

  return isEqual(normalizedSelectionsA, normalizedSelectionsB);
};

/**
 * Function that checks if two SelectedOptionAwares are equal considering their OptionSelections.
 * @param a
 * @param b
 */
export const areStatesEqual = (
  a: SelectedOptionAware,
  b: SelectedOptionAware
) => {
  return areSelectedOptionsEqual(
    a.getSelectedOptions(),
    b.getSelectedOptions()
  );
};

export class PropertyContextImpl implements PropertyContext {
  constructor(private vehicleConfigModel: VehicleConfig) {}

  getVariant(option: string): string | null {
    const selectedOption = this.vehicleConfigModel.getOption(option);
    if (selectedOption && selectedOption.variantId) {
      return selectedOption.variantId;
    }
    return null;
  }

  isSelected(option: string, variant?: string): boolean {
    return this.vehicleConfigModel.isOptionSelected(option, variant);
  }
}

export enum ConfigType {
  USER_CONFIG = 0,
  PREDEFINED_CONFIG = 1,
}

export interface VehicleInfoPayload {
  modelId?: string;
  des?: string;
  vehicleId: string;
  commercialModel?: string;
  startingConfigurationId?: number;
}

export interface VehicleInfo extends VehicleInfoPayload {
  id: string;
  name: string;
  code?: string;
  marketId?: string;
  order?: number;
  active: boolean;
  packages: string[];
  isEnabledInRealTime: boolean;
  cwsId?: string;
  cobaPrefix?: string;
  carDetailsName?: string;
  nameUpper(): string;
}

export interface VehicleConfigChosenOptionsPayload {
  optionId: string;
  variantId?: string;
}

interface VehicleConfigPayloadBase {
  id?: number;
  name?: string;
  configurationCode?: string;
  model_id: string;
  cardetails_name: string;
  vehicle_id?: string;
  chosen_options: VehicleConfigChosenOptionsPayload[];
  market_id?: string;
  comments?: any[];
  startingConfigurationId?: number;
}

interface LocalVehicleConfigPayloadBase extends VehicleConfigPayloadBase {
  myferrari_id?: number; // id of a myferrari-configuration that this local configuration might have been derived from
  myferrari_code?: string;  // config-code of a myferrari-configuration that this local configuration might have been derived from
}

/**
 * Data structure for a vehicle-configuration as it is used for communication with the backend.
 */
export interface VehicleConfigPayload extends LocalVehicleConfigPayloadBase {
  writable: number; // 0 = false, 1 = true
  customer?: any; // may be null in case of predefined configurations
  created?: string;
  publication?: string;
  number?: string;
}

export class VehicleConfig
  implements SelectedOptionAware, VehicleConfigModelAware
{
  static readonly NO_ID_VALUE = -1;

  // id?: number = VehicleConfig.NO_ID_VALUE;
  // id?: string;
  name?: string;
  startingConfigurationId?: number = VehicleConfig.NO_ID_VALUE;

  private configModel: VehicleConfigModel = {};

  private configMeta: VehicleConfigurationMeta | undefined;

  constructor(
    store?: Store<FerrariState>,
    public id? : string,
    public marketId?: string, // may be undefined in case of predefined-configurations
    public modelId?: string,
    public vehicleId?: string | undefined, // may be undefined in case of predefined-configurations
    public cwsId?: string,
    public cobaPrefix?: string,
    public carDetailsName?: string,
    configModel?: VehicleConfigModel,
  ) {
    if (configModel) {
      this.applyConfig(configModel);
    }
    if (store){
      store
        .select((p) => p.data.vehicleConfigMeta)
        .subscribe((meta) => {
          this.configMeta = meta;
        });
    }
  }

  /**
   * Applies the given VehicleConfigModel to this config.
   * @param configModel
   */
  applyConfig(configModel: VehicleConfigModel): void {
    this.configModel = cloneDeep(configModel);
  }

  getConfig(): VehicleConfigModel {
    return cloneDeep(this.configModel);
  }

  /**
   * Applies the state defined by the given SelectedOptionAware to this VehicleConfig.
   * @param state The state to apply.
   * might needed for streaming 3D
   */
  //   applyState(state: SelectedOptionAware) {
  //     this.configModel = state
  //       .getSelectedOptions()
  //       .reduce((configModel, selectedOption) => {
  //         configModel[selectedOption.optionId] = selectedOption;
  //         return configModel;
  //       }, <VehicleConfigModel>{});
  //   }

  getSelectedOptions() {
    return values(this.configModel)
      .filter((option) => option.selected)
      .map((option) => cloneDeep(option));
  }

  getFullOptionList(): Option[] {
    const result: Option[] = [];
    this.getSelectedOptions().forEach(opt => {
      const convertedopt = this.convertToFulloption(opt);
      if (!!convertedopt){
        result.push(convertedopt);
      }
    })
    return result;
  }


  getOption(optionId: string): OptionSelection | null {
    const optionSelection = this.getOptionInternal(optionId);
    if (!optionSelection) {
      return null;
    }
    return {
      ...optionSelection,
    };
  }

  selectOption(optionId: string, variantId?: string | null) {
    let optionSelection = this.configModel[optionId];
    const adjustedVariantId = trim(variantId || undefined) || null;
    if (!optionSelection) {
      optionSelection = {
        optionId,
        selected: true,
        variantId: adjustedVariantId,
      };
      this.configModel[optionId] = optionSelection;
    } else {
      optionSelection.selected = true;
      optionSelection.variantId = adjustedVariantId;
    }
  }

  unselectOption(optionId: string) {
    delete this.configModel[optionId];
  }

  deSelectAll(){
    Object.keys(this.configModel).forEach(optionId => {
      delete this.configModel[optionId];
    });
  }

  isOptionSelected(optionId: string, variantId?: string | null): boolean {
    if (this.configModel[optionId] && this.configModel[optionId].selected) {
      if (variantId) {
        return this.configModel[optionId].variantId === variantId;
      }
      return true;
    }
    return false;
  }

  getSelectedVariant(optionId: string): string | null {
    return this.configModel[optionId]
      ? this.configModel[optionId].variantId
      : null;
  }

  isPersisted(): boolean {
    return isNumber(this.id) && this.id !== VehicleConfig.NO_ID_VALUE;
  }

  // unsetId() {
  //   this.id = VehicleConfig.NO_ID_VALUE;
  // }

  clone(): VehicleConfig {
    return cloneDeep(this);
  }

  private getOptionInternal(optionId: string): OptionSelection | null {
    const returnVal = this.configModel[optionId];
    return returnVal;
  }

  private convertToFulloption(opt: OptionSelection): Option | undefined{
    if (this.configMeta){
      return this.configMeta.getOption(opt.optionId, opt.variantId || undefined);
    } else{
      return undefined;
    }
  }
}

// might needed for streaming 3D
// export class CapturedState implements SelectedOptionAware {
//   readonly id?: number;
//   readonly name: string;
//   created?: string;

//   constructor(
//     id: number | undefined,
//     name: string,
//     private configModel: VehicleConfigModel
//   ) {
//     this.id = id;
//     this.name = name;
//   }

//   getSelectedOptions(): OptionSelection[] {
//     return values(this.configModel)
//       .filter((option) => option.selected)
//       .map((option) => cloneDeep(option));
//   }

//   isOptionSelected(optionId: string, variantId?: string): boolean {
//     if (this.configModel[optionId] && this.configModel[optionId].selected) {
//       if (variantId) {
//         return this.configModel[optionId].variantId === variantId;
//       }
//       return true;
//     }
//     return false;
//   }

//   /**
//    * Returns a clone of the underlying VehicleConfigModel.
//    */
//   getConfigModel() {
//     return cloneDeep(this.configModel);
//   }

//   /**
//    * Applies the given VehicleConfigModel to this config.
//    * @param configModel
//    */
//   applyConfigModel(configModel: VehicleConfigModel) {
//     this.configModel = cloneDeep(configModel);
//   }
// }
