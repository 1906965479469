import { Injectable } from "@angular/core";
import { OptionSelection, VehicleConfig, VehicleConfigPayload } from "../models/vehicle-config";
import {
  Option,
  VehicleConfigurationMeta,
  OptionWithVariant,
  ConfigurationLevel,
} from "../models/configuration-meta";
import { Api } from "./_api/index";
import {
  FerrariState,
  ImageRender,
  RuleEngineModalResult,
  UserAction,
} from "src/app/shared/application-interfaces";
import { UtilityService } from "./utility.service";
import { RuleEngineResponse } from "../models/RuleEngineResponse";
import { Store, select } from "@ngrx/store";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { RuleEngineModalComponent } from "../../features/configuration-area/rule-engine-modal/rule-engine-modal.component";
import { BrowserDetectorService } from "./browser-detector.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfigurationProvider } from "./configuration.provider";
import { HttpClient } from "@angular/common/http";
import { DialogService } from "./dialog.service";
import {
  catchError,
  combineLatest,
  map,
  Observable,
  of,
  BehaviorSubject,
  filter,
  switchMap,
  take,
  debounceTime,
  last,
  distinctUntilChanged,
  skip,
  timeout,
  TimeoutError,
  shareReplay,
  ConnectableObservable,
} from "rxjs";
import {
  enableRuleSkip,
  setRendersImages,
  setTurnTableLoadImage,
  startRenderTime,
  resetDuration,
  setLoading,
  closeFullscreen,
} from "src/app/state/actions";
import { stopLoadingSpinner } from "../../state/actions";
import { cloneDeep, isEqual, isEqualWith } from "lodash";
import { ModalGenericErrorComponent } from "src/app/shared/components/modal-generic-error/modal-generic-error.component";
import { AnalyticsService } from "./analytics.service";
import { Car } from "../models/Car";
import { StateLineClientService } from "../_stateline-client/state-line-client.service";
import { ENDPOINT_LOAD_CONFIGURATION, ENDPOINT_MUSEUM_CHANGE_MODEL, ENDPOINT_SET_CURRENT_CONFIGURATION } from "../_stateline-client/application-endpoints";
import { ApplicationStateService } from "../_stateline-client/application-state.service";
import { ApplicationInfoService } from "../_stateline-client/application-info.service";
import { publishReplay, takeLast, tap, withLatestFrom } from "rxjs/operators";
import { ConfigurationMeta } from "../models/configuration-meta";
import { ConfigLevelComponent } from '../../features/configuration-area/config-level/config-level.component';
import {
  SceneStateService,
  BeautyShot,
} from "../_stateline-client/scene-state.service";
import {
  COMMAND_LISTEN_CONFIGURATION_APPLIED,
  COMMAND_REQUEST_CAMERA_SHOT,
  COMMAND_RESET_SCENE,
  COMMAND_ZOOM,
} from "../_stateline-client/application-commands";
import { RT_CONFIGURATOR_URL } from "src/app/shared/application-constants";
import { MuseumPopupErrorComponent } from "src/app/features/configuration-area/museum-popup-error/museum-popup-error.component";
import { PreconfigurationMuseum } from "../models/PreconfigurationMuseum";
import { LocalStorageService } from "./local-storage.service";
import { SessionStorageService } from "./session-storage.service";

@Injectable({
  providedIn: "root",
})
export class ConfigurationManagerService {
  vehicleConfig: VehicleConfig | undefined = undefined;
  vehicleMeta?: VehicleConfigurationMeta;

  public beautyShotToApply: string = "";

  private vehicleConfig$ = new BehaviorSubject<VehicleConfig | undefined>(
    this.vehicleConfig
  );

  private vehicleMeta$ = new BehaviorSubject<
    VehicleConfigurationMeta | undefined
  >(this.vehicleMeta);

  private lastRenderjob: ImageRender | undefined;
  private isRendering: boolean = false;
  private lastPayloadEffected: OptionSelection | undefined;
  private currentPackageId: string = "default";
  private carModelId = "";
  private isStreaming = false;
  private lastBeautyShot = "";
  private logOfBeautyShots: string[] = [];
  private lastCameraShots: string[] = [];
  private lastOptVariants: OptionWithVariant[] = [];
  private _carSettings = new BehaviorSubject<any>({});

  setVehicleConfig(config: VehicleConfig) {
    this.vehicleConfig = config;
    this.vehicleConfig$.next(config);
  }

  getVehicleConfig(): Observable<VehicleConfig | undefined> {
    return this.vehicleConfig$.asObservable();
  }

  getVehicleInfoValue(): VehicleConfig | undefined {
    return this.vehicleConfig$.getValue();
  }

  setVehicleMeta(meta: VehicleConfigurationMeta | undefined) {
    this.vehicleMeta$.next(meta);
  }

  getVehicleMeta(): Observable<VehicleConfigurationMeta | undefined> {
    return this.vehicleMeta$.asObservable();
  }

  getVehicleMetaValue(): VehicleConfigurationMeta | undefined {
    return this.vehicleMeta$.getValue();
  }

  getLastCameraShots(): string[] {
    return this.lastCameraShots;
  }

  setLastCameraShots(cameraShots: string[]) {
    this.lastCameraShots = cameraShots;
  }

  getLastOptVariants(): OptionWithVariant[] {
    return this.lastOptVariants;
  }

  setLastOptVariants(options: OptionWithVariant[]) {
    this.lastOptVariants = options;
  }

  constructor(
    private router: Router,
    private api: Api,
    private utili: UtilityService,
    private store: Store<FerrariState>,
    private dialog: MatDialog,
    private deviceInfo: BrowserDetectorService,
    private http: HttpClient,
    private cfg: ConfigurationProvider,
    private dialogService: DialogService,
    private analytics: AnalyticsService,
    private stateLineClientService: StateLineClientService,
    private applicationInfoService: ApplicationInfoService,
    private applicationStateService: ApplicationStateService,
    private sceneStateService: SceneStateService,
    private sessionStorage: SessionStorageService
  ) {
    store
      .select((p) => p.data.car)
      .subscribe({ next: (car) => {
          if (!!car && !!car.id) {
            this.carModelId = car.id;
            this.carModelSettings(car.id);
          }
        }
      });
    store
      .select((p) => p.data.vehicleConfigMeta)
      .subscribe((meta) => (this.vehicleMeta = meta));
    this.store
      .select((p) => p.data.packageId)
      .subscribe(
        (packageId) => (this.currentPackageId = packageId || "default")
      );

    store
      .select((p) => p.ui.isStreaming)
      .subscribe({ next: (istreaming) => (this.isStreaming = istreaming) });
  }

  public get carSettings(): BehaviorSubject<any> {
    return this._carSettings;
  }

  public carModelSettings(carId: string) {
    this.http
      .get<any[]>(
        `/rt-assets/data/cars/${carId}/config/car-settings.json`
      )
      .pipe(
        catchError((error) => {
          return of(undefined);
        })
      )
      .subscribe((settings: any) => {
        if (!!settings) {
          this._carSettings.next(settings);
        }
      });
  }

  public updateStatusConfiguration(
    cameraShots: string[],
    options: OptionWithVariant[]
  ) {
    this.setLastCameraShots(cameraShots);
    this.setLastOptVariants(options);
    // console.log("CURRENT STATUS: ", this.lastCameraShots, this.lastOptVariants);
  }

  public async addOption(
    payloadOpt: OptionSelection,
    currentConfigLevel: ConfigurationLevel | null | undefined,
    cameraShots: string[] = [],
    isSilentRuleCheck = false
  ) {
    if (this.cfg.isMuseum) {
      isSilentRuleCheck = true;
      setTimeout(() => {
        if (!this.isStreaming){
          this.dialog.open(MuseumPopupErrorComponent);
          this.analytics.errorPopUp('open');
          // window.location.reload();
          return;
        }
      }, 1);
    }
    await this.isOptionMandatoryForPackage(payloadOpt).then(
      async (isMandatory) => {
        if (!!this.vehicleConfig && !isMandatory) {
          // make a copy of VehicleConfig
          // const clonedVehicleConfig = this.vehicleConfig.clone();
          // prepare to send to ruleEngine
          const vc = cloneDeep(this.vehicleConfig);
          if (!!currentConfigLevel) {
            const standardOpt = currentConfigLevel.options.find(
              (option: any) => option.id && option.id.startsWith("$")
            );
            if (!!standardOpt && !!payloadOpt) {
              if (payloadOpt.optionId.startsWith("$")) {
                // unselect other opts
                currentConfigLevel.options.forEach((x) =>
                  vc.unselectOption(x.id)
                );
              } else {
                vc.unselectOption(standardOpt.id);
              }
            }
          }

          await this.checkRuleEngine(payloadOpt, vc, isSilentRuleCheck).then(
            (res) => {
              const config = res || this.vehicleConfig;
              // if (!!config) {
              // singletone vehicleConfig should updated with new config after passing to ruleEngine
              this.setVehicleConfig(config);
              const optVariants: OptionWithVariant[] = config
                .getSelectedOptions()
                .map((m) => {
                  return { optionId: m.optionId, variantId: m.variantId };
                });
              const carId = config.id ? config.id : this.carModelId;
              // if (!isSilentRuleCheck) {
              this.api
                .lookup(carId, "default", true, optVariants)
                .pipe(
                  switchMap((p) => {
                    this.updateStatusConfiguration(cameraShots, optVariants);
                    if (this.isStreaming) {
                      // send configuration to stateline
                      this.sendConfigToStateLine(config);
                      if (cameraShots.length > 0) {
                        this.changeBeautyShot(cameraShots[0]);
                      }
                      this.lastRenderjob = undefined;

                      // this.setRenderImage(carId, cameraShots, optVariants);

                      return of({ trunk: p.trunk, isStreaming: true });
                    } else {
                      return this.renderImage(
                        cameraShots,
                        optVariants,
                        p.trunk || ""
                      );
                    }
                  })
                )
                .subscribe({
                  next: (render) => {
                    if (!!render && !!render.trunk) {
                      if (payloadOpt.selected === true) {
                        this.analytics.selectOption(currentConfigLevel, payloadOpt, render.trunk);
                      } else {
                        this.analytics.removeOption(currentConfigLevel, payloadOpt, render.trunk);
                      }
                      this.router.navigate([], {
                        queryParams: {
                          configuration: render.trunk,
                          isStreaming: this.isStreaming,
                        },
                        replaceUrl: false,
                      });
                    }
                    if (!!render && render.isStreaming) {
                      this.applicationStateService
                        .subscribeToApplicationCommand(
                          COMMAND_LISTEN_CONFIGURATION_APPLIED
                        )
                        .subscribe({
                          next: () => {
                            // telorance to effect the video stream
                            setTimeout(() => {
                              this.store.dispatch(stopLoadingSpinner());
                            }, 1000);
                          },
                          error: () => {
                            // TODO: Show some UI/UX message
                            this.store.dispatch(stopLoadingSpinner());
                          },
                        });
                    } else {
                      // this.store.dispatch(stopLoadingSpinner());
                    }

                    if (!isSilentRuleCheck) {
                      this.lastPayloadEffected = cloneDeep(payloadOpt);
                    }
                  },
                  error: (err) => {
                    this.store.dispatch(stopLoadingSpinner());
                  },
                });
              // }
            }
          );
        } else {
          this.store.dispatch(stopLoadingSpinner());
          // TODO show general error popup of ruleEngine
          const dialogConfig: any = {};
          dialogConfig.data = {
            title: "",
            description: "RT.OBLIGATORY_FOR_CURRENT_PACKAGE",
          };
          this.dialogService.openDialog(ModalGenericErrorComponent, [
            dialogConfig,
          ]);
        }
      }
    );
  }

  public setRenderImage(
    carId: string,
    cameraShots: string[],
    optVariants: OptionWithVariant[]
  ) {
    // start time tracker
    this.store.dispatch(startRenderTime());
    this.api
      .render(
        carId,
        this.currentPackageId,
        cameraShots,
        "Day",
        optVariants,
        "Blackroom"
      )
      .pipe(
        map((render: any) => {
          const renderFileUrls = render.filenames.sort((a: any, b: any) => {
            const slashSplit = a.split("/");
            const dashSplit = slashSplit[slashSplit.length - 1].split("-");
            const target = dashSplit[dashSplit.length - 2];
            if (cameraShots[0] === target) {
              return -1;
            } else {
              return 1;
            }
          });
          this.lastRenderjob = {
            cameraShots: cameraShots,
            filenames: renderFileUrls,
            trunk: render.trunk,
            prefix: render.prefix,
          };

          this.store.dispatch(
            setRendersImages({
              images: this.lastRenderjob,
            })
          );
        })
      )
      .subscribe();
  }

  public sendConfigToStateLine(config: any = this.vehicleConfig) {
    const chosenOptions = this.mapToCobaOptions(config.getSelectedOptions());
    this.stateLineClientService
      .publish(`mv.commands.select.${config.cwsId}`, {
        forceUpdate: true,
        productId: config.cwsId,
        chosenOptions,
      })
      .pipe(
        tap(() => {
          this.sceneStateService.notifyConfigurationChangeSent();
        }),
        map(() => void 0)
      );

    // publishSelect$.subscribe();
  }

  // public addOptionToAnalytics(imageName: string, duration: string) {
  //   if (!!this.lastPayloadEffected) {
  //     if (this.lastPayloadEffected.selected) {
  //       const option = this.vehicleMeta?.getOption(
  //         this.lastPayloadEffected.optionId,
  //         this.lastPayloadEffected.variantId
  //       );
  //       this.analytics.selectOption(option, imageName, `${duration}`);
  //     }
  //     this.lastPayloadEffected = undefined;
  //   }
  // }

  public silentRuleCheck(
    options: OptionWithVariant[],
    vehicleInfo: Car
  ): Promise<OptionWithVariant[]> {
    return new Promise(async (resolve, reject) => {
      const vehicleConfig = new VehicleConfig(
        this.store,
        vehicleInfo.id,
        vehicleInfo?.marketId,
        vehicleInfo?.modelId,
        vehicleInfo?.vehicleId,
        vehicleInfo?.cwsId,
        vehicleInfo?.cobaPrefix,
        vehicleInfo?.carDetailsName
      );
      // select last option as payload option for the checkRule
      let lastopt = options[options.length - 1];
      // but if there is an option like CAL or EXTC or INTC in the configuration, use it as payload
      // in order to limit conflicts with the checkRule 
      for (let i = 0; i < options.length; i++) {
        const optId = options[i].optionId;
        if (optId.startsWith("CAL") || optId.startsWith("EXTC") || optId.startsWith("INTC")) {
          lastopt = options[i];
          break;
        }
      }
      options.forEach((opt) => {
        if (opt.optionId !== lastopt.optionId) {
          vehicleConfig.selectOption(opt.optionId, opt.variantId);
        }
      });
      const payloadopt: OptionSelection = {
        optionId: lastopt.optionId,
        variantId: lastopt.variantId || null,
        selected: true,
      };
      this.setVehicleConfig(vehicleConfig);
      this.addOption(payloadopt, null, [], true).then(() => {
        if (!!this.vehicleConfig) {
          const correctOpts: OptionWithVariant[] = this.vehicleConfig
            .getSelectedOptions()
            .map((m) => {
              return { optionId: m.optionId, variantId: m.variantId };
            });
          resolve(correctOpts);
        } else {
          reject();
        }
      });
    });
    // const selectedOptions = vehicleConfig.getSelectedOptions();
    // if (selectedOptions.length > 1){
    //   const payloadopt: OptionSelection = selectedOptions[selectedOptions.length-1];
    //   this.vehicleConfig.unselectOption(payloadopt.optionId);
    //   return this.addOption(payloadopt, null, [], true);
    // }
    // }
  }

  public renderImage(
    cameraShots: string[],
    selectedOptions: OptionWithVariant[],
    trunk: string,
    environment = "Blackroom",
    isTurntable = false,
    frames?: number[]
  ): Observable<any> {
    if (!!this.vehicleConfig && cameraShots.length > 0) {
      const carId = this.vehicleConfig.id || this.carModelId;
      const opts =
        selectedOptions.length === 0
          ? this.vehicleConfig
            ? this.vehicleConfig.getSelectedOptions().map((m) => {
              return { optionId: m.optionId, variantId: m.variantId };
            })
            : []
          : selectedOptions;

      // avoid dupplicate render job
      let isSameRender = false;
      if (!!this.lastRenderjob) {
        isSameRender =
          this.lastRenderjob.cameraShots.length === cameraShots.length &&
          this.lastRenderjob.cameraShots.every((ev) =>
            cameraShots.includes(ev)
          ) &&
          trunk === this.lastRenderjob.trunk;
      }

      if ((!isSameRender && !this.isRendering) || isTurntable) {
        // start time tracker
        this.store.dispatch(startRenderTime());
        this.isRendering = true;
        return this.api
          .render(
            carId,
            this.currentPackageId,
            cameraShots,
            "Day",
            opts,
            environment,
            !!frames && frames.length > 0 ? frames : []
          )
          .pipe(
            map((render) => {
              let renderFileUrls = render.filenames || [];
              renderFileUrls = renderFileUrls.sort((a, b) => {
                const slashSplit = a.split("/");
                const dashSplit = slashSplit[slashSplit.length - 1].split("-");
                const target = dashSplit[dashSplit.length - 2];
                if (cameraShots[0] === target) {
                  return -1;
                } else {
                  return 1;
                }
              });
              this.lastRenderjob = {
                filenames: renderFileUrls,
                cameraShots: cameraShots,
                prefix: render.prefix,
                trunk: render.trunk,
              };
              if (!isTurntable) {
                this.store.dispatch(
                  setRendersImages({
                    images: this.lastRenderjob,
                  })
                );
              }
              this.isRendering = false;
              return this.lastRenderjob;
            }),
            catchError((err) => {
              this.store.dispatch(resetDuration());
              return err;
            })
          );
      } else {
        this.store.dispatch(resetDuration());
        return of(null);
      }
    } else {
      this.store.dispatch(resetDuration());
      return of(null);
    }
  }

  public changeBeautyShot(beutyShot: string, forceChange = false) {
    if (!!beutyShot && this.stateLineClientService.alreadyConnected) {
      if (this.lastBeautyShot !== beutyShot || forceChange) {
        this.logOfBeautyShots.push(beutyShot);
        console.log("beautyShots log: ", this.logOfBeautyShots);
        this.lastBeautyShot = beutyShot;
        this.applicationStateService.handleServerCallInProgress(
          this.applicationStateService.publishCommand({
            type: COMMAND_REQUEST_CAMERA_SHOT,
            identifier: beutyShot,
          })
        );
      }
    }
  }

  public renderTurnTableByFrame(
    frames: number[],
    cameraShots = ["360_exterior"],
    selectedOptions: OptionWithVariant[] = [],
    environment = "Blackroom"
  ): Observable<any> {
    const carId = this.vehicleConfig?.id || this.carModelId;
    const opts =
      selectedOptions.length === 0
        ? this.vehicleConfig
          ? this.vehicleConfig?.getSelectedOptions().map((m) => {
            return { optionId: m.optionId, variantId: m.variantId };
          })
          : []
        : selectedOptions;
    return this.api
      .render(carId, "default", cameraShots, "Day", opts, environment, frames)
      .pipe(
        map((render) => {
          this.store.dispatch(
            setTurnTableLoadImage({
              imgUrl: render.filenames || [],
            })
          );
          // this.isRendering = false;
          // return this.lastRenderjob;
        }),
        catchError((err) => {
          return err;
        })
      );
  }

  public museumChangeModelIntent(selectedPreconfigMuseum: PreconfigurationMuseum) {
    const payloadChangeModel = { cardetails_name: selectedPreconfigMuseum.carDetailsName }
    return this.museumChangeModelInternal(payloadChangeModel).pipe(
      switchMap((responseChangeModel) => {
        if(!!responseChangeModel && responseChangeModel.success) {
          this.sessionStorage.previousSelectedCarMuseum = selectedPreconfigMuseum.carDetailsName;
          return of(responseChangeModel);
        } else {
          throw new Error('Cannot change model');
        }
      }),
      catchError((error) => {
        return of({ success: false, msg: error});
      })
    )
  }

  public museumChangeModelAndLoadDefaultConfigIntent(selectedPreconfigMuseum: PreconfigurationMuseum) {
    const payloadChangeModel = { cardetails_name: selectedPreconfigMuseum.carDetailsName }
    return this.museumChangeModelInternal(payloadChangeModel).pipe(
      switchMap((responseChangeModel) => {
        if(!!responseChangeModel && responseChangeModel.success) {
          const payloadLoadConfig = { cardetails_name: selectedPreconfigMuseum.carDetailsName, chosen_options: selectedPreconfigMuseum.chosenOptions };
          return this.museumLoadDefaultConfigurationInternal(payloadLoadConfig);
        } else {
          throw new Error('Cannot change model');
        }
      }),
      switchMap((responseLoadConfig) => {
        if(!!responseLoadConfig && responseLoadConfig.success) {
          return of(responseLoadConfig);
        } else {
          throw new Error('Cannot load default config');
        }
      }),
      catchError((error) => {
        return of({ success: false, msg: error});
      })
    )
  }

  private museumChangeModelInternal(payload: any) {
    return this.stateLineClientService.callJson<any>(ENDPOINT_MUSEUM_CHANGE_MODEL, payload, true)
    .pipe(
      map((response) => response),
      catchError(err => of(null))
    );
  }

  private museumLoadDefaultConfigurationInternal (payload: any) {
    return this.stateLineClientService.callJson<any>(ENDPOINT_LOAD_CONFIGURATION, payload, true)
    .pipe(
      map((response) => response),
      catchError(err => of(null))
    );
  }

  public resetMuseumConfiguration() {
    this.store.dispatch(setLoading({ isLoading: true }));
    this.store.dispatch(closeFullscreen());
    // get initial config from cfg by url based on
    const carId = this.vehicleConfig?.id || this.carModelId;

    const initialConfig = this.cfg.museumStartingConfig?.find(cardata => cardata.id === carId);
    // get options by read config hash
    if (initialConfig) {
      this.api.read(initialConfig.id, initialConfig.package, initialConfig.trunk).subscribe({
        next: options => {
          // set selected items to vehicle config
          if (!!this.vehicleConfig) {
            if (!!options && options.length > 0) {
              this.vehicleConfig.deSelectAll();
              options.forEach((opt: any) => {
                if (!!this.vehicleConfig) {
                  this.vehicleConfig.selectOption(opt.optionId, opt.variantId);
                }
              });
              this.vehicleConfig$.next(this.vehicleConfig);
              // pass opts to streaming
              this.sendConfigToStateLine();
              // reset streaming scene (animations and beatyshot,...)
              this.applicationStateService.handleServerCallInProgress(
                this.stateLineClientService.publishCommand({
                  type: COMMAND_RESET_SCENE,
                })
              );
              setTimeout(() => {
                // zoom out
                this.applicationStateService.handleServerCallInProgress(
                  this.stateLineClientService.publishCommand({
                    type: COMMAND_ZOOM,
                    floatValue: 3
                  })
                );
              }, 1000);

              // reset local storage scroll position
              localStorage.setItem('scrollPosition', "");

              // navigate to default URL (with new model switch page, this is no longer useful)
              // this.router.navigate([initialConfig.lang, RT_CONFIGURATOR_URL, initialConfig.id, initialConfig.package, "museum"], {
              //   queryParams: {
              //     configuration: initialConfig.trunk,
              //     isStreaming: true,
              //   },
              //   replaceUrl: true,
              // });
              this.store.dispatch(setLoading({ isLoading: false }));
            } else {
              this.store.dispatch(setLoading({ isLoading: false }));
              throw Error("no response from spindox");
            }
          }
        }
      });
    }
  }

  private async isOptionMandatoryForPackage(
    payloadOpt: OptionSelection
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      let result = false;
      if (!payloadOpt.selected) {
        this.utili
          .fetchGlobalRules(this.vehicleConfig?.id)
          .pipe(take(1))
          .subscribe({
            next: (data) => {
              const rules = data;
              // const packageId = data[1];
              // this.currentPackageId = packageId || "default";
              if (!!this.currentPackageId && !!rules["mandatory"]) {
                const mandatoryList = rules["mandatory"][this.currentPackageId];
                const payloadString =
                  payloadOpt.optionId +
                  (!!payloadOpt.variantId ? `_${payloadOpt.variantId}` : "");
                result = mandatoryList.includes(payloadString);
              }
              resolve(result);
            },
          });
      } else {
        resolve(result);
      }
    });
  }

  private async checkRuleEngine(
    payloadOpt: OptionSelection,
    clonedVehicleConfig: VehicleConfig,
    isSilent = false
  ): Promise<VehicleConfig> {
    const userAction: UserAction = {
      action: payloadOpt.selected ? "enable" : "disable",
      optionId: payloadOpt.optionId,
      variantId: payloadOpt.variantId,
    };
    const carId = clonedVehicleConfig.id
      ? clonedVehicleConfig.id
      : this.carModelId;

    const initialPayload = {
      carModelId: carId,
      userActions: [userAction],
      selectedOptions: clonedVehicleConfig.getSelectedOptions(),
      marketId: clonedVehicleConfig.marketId || "00",
      countryCode: this.utili.getCountryCodeForRuleEngine() || "DE",
      dealerId: null,
    };
    const maxDepth = 10;
    return this.recursiveCheckRuleCall(
      payloadOpt,
      initialPayload,
      clonedVehicleConfig,
      this.api.checkRuleEngine.bind(this),
      maxDepth,
      0,
      isSilent
    );
  }

  private async recursiveCheckRuleCall(
    payloadOpt: OptionSelection,
    payload: any,
    clonedVehicleConfig: VehicleConfig,
    callBackend: (payload: any) => Promise<any>,
    maxDepth: number = 10,
    currentDepth: number = 0,
    isSilent = false
  ): Promise<any> {
    let beResult;
    try {
      beResult = await callBackend(payload);
      // consider if multiple conflict we need to consider to deselect conflict
      // if (result && result.conflict.conflict_origin.length > 1) {
      //   if (result.conflict.to_deselect && result.conflict.to_deselect.length > 0){

      //   }
      // }
    } catch (e: any) {
      beResult = e;
    }

    const payloadOption: Option | undefined = this.vehicleMeta?.getOption(
      payloadOpt.optionId,
      payloadOpt.variantId
    );
    const packageExclusionList = await this.utili.getGlobalExclusionsOfPackage(
      this.vehicleConfig?.id,
      this.currentPackageId
    ).toPromise();

    const ruleEngineResponse: RuleEngineResponse = new RuleEngineResponse(
      beResult,
      payloadOption,
      packageExclusionList,
      this.vehicleMeta
    );
    const hasConflict = beResult && beResult.conflict;
    if (hasConflict && currentDepth < maxDepth) {
      // const modals = [];
      let silentResponse;
      const result: RuleEngineModalResult = {
        isApproved: true,
        options: [],
      };
      const modalConfigsFiltered = ruleEngineResponse.origins
        .sort((s) => {
          return s === "ForceSelection" ? -1 : 0;
        })
        .filter((orgn) => this.isConflictPopupNeeded(ruleEngineResponse, orgn));
      let modalConfigs;
      if (isSilent) {
        modalConfigs = modalConfigsFiltered.map(async (orgn, index) => {
          if (isSilent) {
            switch (orgn) {
              case "ForceSelection":
                // as confirm needed always confirm
                // use options in rulEngineResponse
                if (!!ruleEngineResponse.options) {
                  ruleEngineResponse.options.forEach((opt) => {
                    if (opt.values) {
                      const optSelect: OptionSelection = {
                        optionId: opt.values[0].optionId,
                        variantId: opt.values[0].variantId || null,
                        selected: true,
                      };
                      result.options?.push(optSelect);
                    }
                  });
                }
                break;
              case "AlternativeSelection":
                // as alternative select first item
                if (
                  !!ruleEngineResponse.alternatives &&
                  ruleEngineResponse.alternatives.length > 0
                ) {
                  if (
                    !!ruleEngineResponse.alternatives[0] &&
                    !!ruleEngineResponse.alternatives[0].values
                  ) {
                    const optionId =
                      ruleEngineResponse.alternatives[0].values[0].optionId;
                    const variantId =
                      ruleEngineResponse.alternatives[0].values[0].variantId ||
                      null;
                    if (!!optionId) {
                      const optSelect: OptionSelection = {
                        optionId,
                        variantId,
                        selected: true,
                      };
                      result.userDecision = [optSelect];
                    }
                  }
                }

                break;
              case "ColorSubstitution":
                // as alternative select first item in the dependecyMap
                if (!!ruleEngineResponse.dependecyMap) {
                  // Get the first entry in the map
                  const firstEntry = ruleEngineResponse.dependecyMap
                    .entries()
                    .next().value;
                  if (firstEntry) {
                    // destructure map
                    const [key, options] = firstEntry;
                    // Ensure the array has options
                    if (options.length > 0) {
                      const optionId = options[0].values[0].optionId;
                      const variantId = options[0].values[0].variantId || null;
                      if (!!optionId) {
                        const optSelect: OptionSelection = {
                          optionId,
                          variantId,
                          selected: true,
                        };
                        result.userDecision = [optSelect];
                      }
                    }
                  }
                }
                break;
            }
          }

          if (
            index ===
            ruleEngineResponse.origins.filter((org) =>
              this.isConflictPopupNeeded(ruleEngineResponse, org)
            ).length -
            1
          ) {
            silentResponse = new Promise((resolve) => resolve(result)); //Promise.resolve(result);
          }
          return {};
        });
      } else {
        modalConfigs = modalConfigsFiltered.map((orgn) => {
          if (!isSilent) {
            const dialogConfig: any = {};
            if (this.deviceInfo.isMobile()) {
              dialogConfig.width = "100%";
              dialogConfig.height = "100%";
              dialogConfig.panelClass = "full-screen-dialog";
            } else if (this.deviceInfo.isDesktop()) {
              dialogConfig.width = "420px";
              // dialogConfig.minHeight = "500px";
              dialogConfig.maxHeight = "750px";
            }

            dialogConfig.data = {
              ruleEngineResponse: ruleEngineResponse,
              origin: orgn,
              bePayload: payload,
            };
            return dialogConfig;
          } else {
            return orgn;
          }
        });
      }

      if (modalConfigs && modalConfigs.length > 0) {
        let dialogPromise;
        if (isSilent && !!silentResponse) {
          dialogPromise = silentResponse;
        } else {
          dialogPromise = this.dialogService.openDialog(
            RuleEngineModalComponent,
            modalConfigs
          );
        }

        await dialogPromise.then(async (userChoice) => {
          console.log("All dialogs closed, result:", userChoice);
          if (
            userChoice.isApproved &&
            userChoice.userDecision &&
            userChoice.userDecision.length > 0
          ) {
            // change the payload and send again
            let newPayload = undefined;
            const newUserActions: UserAction[] = [];
            for (let decision of userChoice.userDecision) {
              const newUserAction: UserAction = {
                optionId: decision.optionId,
                variantId: decision.variantId || null,
                action: decision.selected ? "enable" : "disable",
              };
              newUserActions.push(newUserAction);
            }
            newPayload = {
              ...payload,
              userActions: [...payload.userActions, ...newUserActions],
            };

            const newCall = this.recursiveCheckRuleCall(
              payloadOpt,
              newPayload,
              clonedVehicleConfig,
              callBackend,
              maxDepth,
              currentDepth + 1,
              isSilent
            );
            return newCall;
          } else if (
            userChoice.isApproved &&
            userChoice.options &&
            userChoice.options.length > 0
          ) {
            let newPayload = undefined;

            // const newUserActions: UserAction[] = [];

            // if (
            //   userChoice.options.some((s: any) =>
            //     payloadOption && payloadOption.values
            //       ? s.optionId === payloadOption.values[0].optionId &&
            //         s.variantId === payloadOption.values[0].variantId
            //       : false
            //   )
            // ) {
            //   // in case payload effected in options we need to remove it and replace it with FAKE opt
            //   const newUserAction: UserAction = {
            //     optionId: "FAKE",
            //     variantId: null,
            //     action: "enable",
            //   };
            //   newUserActions.push(newUserAction);
            // } else {
            //   newUserActions.push(...payload.userActions);
            // }

            newPayload = {
              ...payload,
              selectedOptions: userChoice.options,
            };

            const newCall = this.recursiveCheckRuleCall(
              payloadOpt,
              newPayload,
              clonedVehicleConfig,
              callBackend,
              maxDepth,
              currentDepth + 1,
              isSilent
            );

            return newCall;
          } else if (!userChoice.isApproved) {
            this.store.dispatch(stopLoadingSpinner());
            return Promise.reject(this.vehicleConfig);
          } else {
            // to be investigate it might have infect in colorPalletr
            return Promise.resolve(this.vehicleConfig);
          }
        });
      } else {
        // we can use ruleEngine options as selected options and no user action needed
        clonedVehicleConfig.deSelectAll();
        if (ruleEngineResponse.options) {
          ruleEngineResponse.options
            .filter((f) => !!f)
            .forEach((opt) => {
              if (opt.values && opt.values.length > 0) {
                const val = opt.values[0];
                clonedVehicleConfig.selectOption(val.optionId, val.variantId);
              }
            });
        }
        this.store.dispatch(enableRuleSkip());
        this.setVehicleConfig(clonedVehicleConfig);
        return Promise.resolve(clonedVehicleConfig);
      }
    } else if (beResult && beResult.error) {
      // TODO: Handle error
      const dialogConfig: any = {};
      dialogConfig.data = {
        title: "PREMIUM.RULE_ENGINE.POP_UP.COMPATIBILITY_ISSUE",
        description: "RT.RULEENGINE.COMPATIBILITY.DESCRIPTION",
      };
      this.dialogService.openDialog(ModalGenericErrorComponent, [dialogConfig]);
      return Promise.reject();
      // return ruleEngineResponse;
    } else {
      // when the ruleEngine resolved replace the resolved options in copied VehicleConfig
      clonedVehicleConfig.deSelectAll();
      if (ruleEngineResponse.options) {
        ruleEngineResponse.options
          .filter((f) => !!f)
          .forEach((opt) => {
            if (opt.values && opt.values.length > 0) {
              const val = opt.values[0];
              clonedVehicleConfig.selectOption(val.optionId, val.variantId);
            }
          });
      }
      this.setVehicleConfig(clonedVehicleConfig);
      return Promise.resolve(clonedVehicleConfig);
    }
  }

  isConflictPopupNeeded(
    ruleEngineResponse: RuleEngineResponse,
    origin: string
  ): boolean {
    let result = false;
    switch (origin) {
      case "ForceSelection":
        result = ruleEngineResponse.origins.length === 1;
        // if toselect & deselect are just one item each and in same category no popup needed
        // if both select & deselect lists has just one opt in same category
        if (ruleEngineResponse.toDeSelect && ruleEngineResponse.trigger) {
          if (ruleEngineResponse.toDeSelect.length === 1) {
            const trigger = ruleEngineResponse.trigger[0];
            const toDeSelect = ruleEngineResponse.toDeSelect[0];
            const triggerPath = trigger.getPathToRoot();
            triggerPath.pop();
            const toDeselectPath = toDeSelect.getPathToRoot();
            toDeselectPath.pop();
            result = !isEqualWith(triggerPath, toDeselectPath);
            // result =
            //   ruleEngineResponse.trigger[0].category !==
            //   ruleEngineResponse.toDeSelect[0].category;
          }
        }
        if (ruleEngineResponse.toSelect && ruleEngineResponse.trigger) {
          if (ruleEngineResponse.toSelect.length === 1) {
            const trigger = ruleEngineResponse.trigger[0];
            const toSelect = ruleEngineResponse.toSelect[0];
            const triggerPath = trigger.getPathToRoot();
            triggerPath.pop();
            const toDeselectPath = toSelect.getPathToRoot();
            toDeselectPath.pop();
            result = !isEqualWith(triggerPath, toDeselectPath);
            // result =
            //   ruleEngineResponse.trigger[0].category !==
            //   ruleEngineResponse.toSelect[0].category;
          }
          // result = ruleEngineResponse.trigger.some(s => s.values?.some(v=> v.variantId ? v.variantId !== '364000000' : false));
        }

        if (ruleEngineResponse.toDeSelect && ruleEngineResponse.toSelect) {
          if (
            ruleEngineResponse.toSelect.length === 0 &&
            ruleEngineResponse.toDeSelect.length === 0
          ) {
            result = false;
          }
        }

        break;

      case "AlternativeSelection":
        result = true;
        break;

      case "ColorSubstitution":
        // it should always return true
        result = true;
        break;
    }
    return result;
  }

  /**
   * Maps the selected and non-selected options and variants from
   * the given VehicleConfig to an array of strings that represent
   * the COBA selection.
   *
   * @param selectedOptionAware The source VehicleConfig
   * @param vehicleInfo The corresponding VehicleInfo
   * @param vehicleMeta The corresponding VehicleConfigurationMeta
   */
  public mapToCobaOptions(selectedOptionAware: OptionSelection[]): string[] {
    let chosenOptions = [];
    if (!!this.vehicleConfig && selectedOptionAware.length === 0) {
      // in case of outside use for streaming start
      chosenOptions = this.mapSelectedOptions(
        this.vehicleConfig.getSelectedOptions()
      );
    } else {
      // internal use after select an option
      chosenOptions = this.mapSelectedOptions(selectedOptionAware);
    }
    // .concat(this.mapUnselectedoptions());

    //  // add static options from vehicleInfo
    //  if (vehicleInfo.additionalData && vehicleInfo.additionalData.additionalCobaOptions) {
    //    chosenOptions = [...chosenOptions, ...vehicleInfo.additionalData.additionalCobaOptions];
    //  }
    return chosenOptions.sort();
  }

  private mapSelectedOptions(selectedOptionAware: OptionSelection[]): string[] {
    return selectedOptionAware
      .filter((selectedOption) => {
        const variantId = selectedOption.variantId || undefined;
        return !this.vehicleMeta?.isInvisibleDefaultVariant(variantId);
      })
      .map((selectedOption) => {
        let cwsOptionBase = `${this.vehicleConfig?.cobaPrefix}_${selectedOption.optionId}`;
        if (selectedOption.variantId) {
          cwsOptionBase = `${cwsOptionBase}_${selectedOption.variantId}`;
        }
        return cwsOptionBase;
      })
      .sort();
  }

  private mapUnselectedoptions(): string[] {
    return (
      this.vehicleMeta?.configuration
        .getAllOptions()
        .filter((option) => {
          return !this.vehicleConfig?.isOptionSelected(option.id);
        })
        .map((selectedOption) => {
          let cwsOptionBase = `${this.vehicleConfig?.cobaPrefix}_${selectedOption.id}_off`;
          return cwsOptionBase;
        })
        .sort() || []
    );
  }
}
